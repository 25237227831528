import { apiGetV2, apiPostV2 } from "../../../apiV2";

const URL_BASE = "/cadastro/lead";
const URL_BASE_INTERACAO = `${URL_BASE}/interacao`;
const URL_BASE_VISITA = `${URL_BASE}/visita`;
const URL_BASE_PROPOSTA = `${URL_BASE}/proposta`;

const LeadService = {
  routesBase: URL_BASE,
  listar: (params) => apiGetV2(`${LeadService.routesBase}/listar/`, params),
  buscar: (id) => apiGetV2(`${LeadService.routesBase}/buscar/${id}/`),
  interacao: {
    incluir: (payload) => apiPostV2(`${URL_BASE_INTERACAO}/incluir/`, payload),
    listar: (idLead) => apiGetV2(`${URL_BASE_INTERACAO}/listar/${idLead}/`),
    listarSemana: () => apiGetV2(`${URL_BASE_INTERACAO}/listar_semana/`),
    registrarNaoConquista: (payload) =>
      apiPostV2(`${URL_BASE_INTERACAO}/registrar_nao_conquista/`, payload),
    registrarConquista: (payload) =>
      apiPostV2(`${URL_BASE_INTERACAO}/registrar_conquista/`, payload, {
        successMesage: false,
      }),
    reativarLead: (payload) =>
      apiPostV2(`${URL_BASE_INTERACAO}/reativar_lead/`, payload),
  },
  visita: {
    incluir: (payload) => apiPostV2(`${URL_BASE_VISITA}/incluir/`, payload),
    listar: (idLead) => apiGetV2(`${URL_BASE_VISITA}/listar/${idLead}/`),
  },
  proposta: {
    incluir: (payload) => apiPostV2(`${URL_BASE_PROPOSTA}/incluir/`, payload),
    listar: (idLead) => apiGetV2(`${URL_BASE_PROPOSTA}/listar/${idLead}/`),
  },
};

export default LeadService;
