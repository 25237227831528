import { FC, useEffect, useRef } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import { apiGet } from "../api";
import FormButton from "./FormButton";
import ModalHeaderActionArea from "./ModalHeaderActionArea";
import ModalHeaderActionCheckbox from "./ModalHeaderActionCheckbox";
import NumberPageModal from "./NumberPageModal";

interface ModalHeaderCheck {
  value: boolean;
  title: any;
  toggle: () => void;
}

export type ModalSizeTipe = "sm" | "md" | "lg" | "xl";

export interface ModalProps {
  isOpen: boolean;
  toggle: () => any;
  size?: ModalSizeTipe;
  onOpened?: () => any;
  onClosed?: () => any;
  title?: any;
  number?: any;
  footerActions?: any;
  autoFocus?: boolean;
  onConfirm?: () => void;
  confirmButtonText?: any;
  hideCancelButton?: boolean;
  loading?: boolean;
  loadingConfirm?: boolean;
  parameterized?: boolean;
  headerCheck?: ModalHeaderCheck;
  onKeyDown?: (e: React.KeyboardEvent<HTMLElement>) => void;
  paramsName?: string;
  onBeforeOpen?: (params: any) => any;
  onBeforeClose?: () => any;
  bodyStyle?: React.CSSProperties;
  footerStyle?: React.CSSProperties;
  headerStyle?: React.CSSProperties;
  children?: any;
}

const loadingStyle: React.CSSProperties = {
  filter: "blur(3px)",
  pointerEvents: "none",
  userSelect: "none",
};

export const ModalBase: FC<ModalProps> = ({
  isOpen,
  toggle,
  size = "md",
  onOpened,
  onClosed,
  title,
  number,
  footerActions,
  autoFocus = false,
  onConfirm,
  confirmButtonText = "Confirmar",
  hideCancelButton,
  loading,
  loadingConfirm,
  parameterized,
  headerCheck,
  onKeyDown,
  paramsName,
  onBeforeOpen,
  onBeforeClose,
  bodyStyle,
  footerStyle,
  headerStyle,
  children,
}) => {
  const formRef = useRef<any>();
  const modalRef = useRef<any>();

  const onOpenedInternal = () => {
    if (
      !autoFocus &&
      formRef.current?.elements.length > 0 &&
      formRef.current.elements[0]?.focus
    )
      formRef.current.elements[0].focus();

    if (onOpened) onOpened();
  };

  const onKeyDownInternal = (e: React.KeyboardEvent<HTMLElement>) => {
    if (loading) {
      e.preventDefault();
      return false;
    }

    const modals = Array.from(document.getElementsByClassName("modal"));

    if (modals.indexOf(modalRef.current) !== modals.length - 1) return;

    if (!e.shiftKey && !e.altKey && !e.ctrlKey && !e.metaKey) {
      if (e.key === "F9" && onConfirm) onConfirm();
    }

    if (onKeyDown) onKeyDown(e);
  };

  const onBeforeOpenInternal = async () => {
    if (onBeforeOpen) {
      let params = undefined;
      if (paramsName) {
        params = await apiGet(`/tela/${paramsName}/`);
      }
      onBeforeOpen(params);
    }
  };

  const onBeforeCloseInternal = () => {
    if (onBeforeClose) onBeforeClose();
  };

  useEffect(() => {
    if (isOpen) {
      onBeforeOpenInternal();
    } else {
      onBeforeCloseInternal();
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      autoFocus={autoFocus}
      size={size}
      centered
      toggle={toggle}
      onOpened={onOpenedInternal}
      onClosed={onClosed}
      backdrop="static"
      onKeyDown={onKeyDownInternal}
      innerRef={modalRef}
    >
      <ModalHeader toggle={toggle} style={headerStyle}>
        {title}
        {headerCheck && (
          <ModalHeaderActionArea style={loading ? loadingStyle : undefined}>
            <ModalHeaderActionCheckbox
              isActive={headerCheck.value}
              label={headerCheck.title ?? "Ativo"}
              toggleCheck={() => headerCheck.toggle()}
            />
          </ModalHeaderActionArea>
        )}
      </ModalHeader>
      <ModalBody style={bodyStyle}>
        {loading && (
          <div
            style={{
              zIndex: 100,
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <Spinner size="lg" />
          </div>
        )}
        <form
          onSubmit={(e) => e.preventDefault()}
          ref={formRef}
          style={loading ? loadingStyle : {}}
        >
          {children}
        </form>
      </ModalBody>
      {(number || footerActions || onConfirm) && (
        <ModalFooter style={footerStyle}>
          {number && (
            <NumberPageModal parametrizado={parameterized}>
              {number}
            </NumberPageModal>
          )}
          {footerActions}
          {onConfirm && (
            <FormButton
              padded={false}
              divClassName="pr-0"
              md="auto"
              color="success"
              loading={loadingConfirm}
              onClick={onConfirm}
            >
              F9 - {confirmButtonText}
            </FormButton>
          )}
          {!hideCancelButton && (
            <FormButton
              padded={false}
              divClassName="pr-0"
              md="auto"
              color="danger"
              onClick={toggle}
            >
              Esc - Sair
            </FormButton>
          )}
        </ModalFooter>
      )}
    </Modal>
  );
};
