import React, { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import { RouteType } from "../routes";
import { FaQuestion } from "react-icons/fa";
import { UncontrolledTooltip } from "reactstrap";

interface Props {
  route: RouteType;
  backgroundColor?: React.CSSProperties["backgroundColor"];
  divClassName?: string;
}

export const MenuGroupButton: FC<Props> = ({
  route,
  backgroundColor,
  divClassName,
}) => {
  const [internalId] = useState(
    "mgb-" + Math.floor(Math.random() * Date.now())
  );
  const history = useHistory();
  // const menus = useSelector((state) => state.menus.menus);

  // const menu = menus.find((e) => e.path === pathname);
  // const liberado = menu !== undefined;
  const liberado = true;
  // const excecao = menu?.excecao;

  const onClick = () => liberado && history.push(route.path);

  const Icon = route.icon ?? FaQuestion;

  return (
    <>
      <div
        className={classNames(
          "menu-group-button",
          !liberado && "disabled",
          divClassName
        )}
        id={internalId}
      >
        <div
          className="icon-button"
          style={{ backgroundColor: backgroundColor }}
          onClick={onClick}
        >
          <div style={{ width: 30, height: 30, position: "relative" }}>
            <Icon size={30} color="white" />
          </div>
        </div>
        <label onClick={onClick}>{route.name}</label>
      </div>
      {route.hint && (
        <UncontrolledTooltip target={internalId}>
          {route.hint}
        </UncontrolledTooltip>
      )}
    </>
  );
};
