import { FC, useEffect, useState } from "react";
import { Card, Row } from "reactstrap";
import { authGet } from "../../../../api";
import {
  PageContainer,
  AsyncComboBox,
  BotaoPesquisar,
  FormCheckbox,
  SearchInput,
} from "../../../../components";
import { debounce, defaultDebounceTime } from "../../../../coreUtils";
import { CadastroEmpresaModal } from "./components/CadastroEmpresaModal";
import { EmpresasGrid } from "./components/EmpresasGrid";
import { ModalActions } from "../../../../components/cadastro/ModalCadastroV2";
import { BotaoIncluir } from "../../../../components/cadastro";
import { LogAcessosModal } from "./components/LogAcessosModal";

export const modalTitleEmpresa = "Empresa";
export const routesBaseEmpresa = "/cadastro/empresa";

export const Empresa: FC = () => {
  const [pista, setPista] = useState("");
  const [idPlanoContratado, setIdPlanoContratado] = useState(null);
  const [somenteAtivos, setSomenteAtivos] = useState(true);
  const [selected, setSelected] = useState(null);
  const [dados, setDados] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingPista, setLoadingPista] = useState(false);
  const [action, setAction] = useState(ModalActions.add);
  const [cadastroOpen, setCadastroOpen] = useState(false);
  const [acessosOpen, setAcessosOpen] = useState(false);

  const limparDados = () => setDados([]);

  const buscarDados = async (p: string = pista) => {
    const params: any = {
      pista: p,
      id_plano_contratado: idPlanoContratado,
      somente_ativos: somenteAtivos,
    };

    const ret = await authGet(`${routesBaseEmpresa}/listar/`, params);

    setDados(ret ? ret : []);
  };

  const carregarDados = async () => {
    setLoading(true);
    await buscarDados();
    setLoading(false);
  };

  const handlePista = debounce(async (p: any) => {
    setPista(p);
    setLoadingPista(true);
    await buscarDados(p);
    setLoadingPista(false);
  }, defaultDebounceTime);

  useEffect(() => {
    carregarDados();
  }, []);

  const notifyEvent = (action: any) => {
    carregarDados();
  };

  const toggleCadastro = (action: ModalActions) => {
    setAction(action);
    setCadastroOpen(!cadastroOpen);
  };

  const toggleAcessos = () => setAcessosOpen(!acessosOpen);

  const alterar = (id: any) => {
    setSelected(id);
    setTimeout(() => {
      toggleCadastro(ModalActions.edit);
    }, 1);
  };

  const mostrarAcessos = (id: any) => {
    setSelected(id);
    setTimeout(() => {
      toggleAcessos();
    }, 1);
  };

  return (
    <PageContainer title="Cadastro de Empresas" number="003" canGoBack>
      <Card body>
        <Row>
          <SearchInput
            md={6}
            onChange={handlePista}
            loading={loadingPista}
            placeholder="Nome fantasia, razão social ou CNPJ"
          />
          <AsyncComboBox
            md={3}
            isConcatField
            concatModelName="plano_contratado"
            defaultOptions
            label="Plano Contratado"
            isSearchable
            onChange={setIdPlanoContratado}
            value={idPlanoContratado}
            isClearable
          />
          <FormCheckbox
            label="Somente Ativos"
            name="somente_ativos"
            onChange={() => {
              setSomenteAtivos(!somenteAtivos);
              limparDados();
            }}
            checked={somenteAtivos}
          />
        </Row>
        <Row>
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <BotaoIncluir toggle={() => toggleCadastro(ModalActions.add)} />
          <CadastroEmpresaModal
            isOpen={cadastroOpen}
            toggle={toggleCadastro}
            action={action}
            selected={selected}
            notifyEvent={notifyEvent}
          />
          <LogAcessosModal
            isOpen={acessosOpen}
            toggle={toggleAcessos}
            selected={selected}
          />
        </Row>
      </Card>
      <Card body>
        <EmpresasGrid
          data={dados}
          setSelected={setSelected}
          alterar={alterar}
          mostrarAcessos={mostrarAcessos}
        />
      </Card>
    </PageContainer>
  );
};
