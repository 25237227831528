import { Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import moment from "moment";
import { FC } from "react";
import { rawAPIUrl } from "../../coreUtils";
import logo from "../../assets/img/logo/skillsoft_logo.png";

const _styles = StyleSheet.create({
  wrapper: {
    flexDirection: "row",
    marginBottom: 5,
    borderBottom: "0.5px solid #000",
    paddingBottom: "5px",
  },
  nomeEmpresa: { fontFamily: "Open Sans Bold", fontSize: 9 },
  logo: {
    maxHeight: "100%",
    maxWidth: 75,
    objectFit: "contain",
    alignSelf: "center",
    borderRight: "0.5px solid #000",
    padding: "10 10",
    marginRight: "10",
  },
  infoEmpresa: { fontFamily: "Open Sans", fontSize: 8 },
  row: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
  },
});

interface Props {
  dataIni?: any;
  dataFim?: any;
  title?: any;
}

export const ReportHeader: FC<Props> = ({ dataIni, dataFim, title }) => {
  const dataAgora = moment(new Date());

  const emissao = `Impresso em ${dataAgora.format(
    "DD/MM/YYYY"
  )} às ${dataAgora.format("HH:mm")}`;

  const periodo =
    dataIni && dataFim
      ? `Referente ao período de ${
          dataIni instanceof Date
            ? moment(dataIni).format("DD/MM/YYYY")
            : dataIni
        } até ${
          dataFim instanceof Date
            ? moment(dataFim).format("DD/MM/YYYY")
            : dataFim
        }`
      : false;

  return (
    <View style={_styles.wrapper}>
      <Image style={_styles.logo} src={logo} />
      <View style={{ width: "100%" }}>
        <View style={_styles.row}>
          <Text style={_styles.nomeEmpresa}>SKILLSOFT GESTÃO E SOFTWARE</Text>
          {periodo && (
            <Text
              style={{
                fontFamily: "Open Sans",
                fontSize: 8,
                textAlign: "right",
              }}
            >
              {emissao}
            </Text>
          )}
        </View>
        <Text style={_styles.infoEmpresa}>
          RODOVIA RS-239, 3953, SANTA FÉ - SAPIRANGA/RS
        </Text>
        <Text style={_styles.infoEmpresa}>SEGUNDO PISO</Text>
        <Text style={_styles.infoEmpresa}>CNPJ: 13.858.672/0001-84</Text>
        <View style={_styles.row}>
          <Text style={_styles.infoEmpresa}>
            Telefones: (51) 3039-1003 / 9.9814-6886
          </Text>
          {title && (
            <Text
              style={{
                fontFamily: "Open Sans SemiBold Italic",
                fontSize: 10,
                textTransform: "uppercase",
              }}
            >
              {title}
            </Text>
          )}
          <Text style={_styles.infoEmpresa}>{periodo ? periodo : emissao}</Text>
        </View>
      </View>
    </View>
  );
};
