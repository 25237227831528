import { FC, useRef, useState } from "react";
import { ModalBase } from "../../../../../components/ModalBase";
import { Label, Row } from "reactstrap";
import { NumberInput } from "../../../../../components";
import { AtualizacoesEditor } from "../../../../../components/AtualizacoesEditor";
import { interacaoLeadEditorFormats } from "../interacao/InteracoesLead";
import { extrairIdColabMencionados } from "../../../../../components/AtualizacoesForm";
import LeadService from "../../../../../services/components/comercial/LeadService";
import { formatDateISO, formatValueFromAPI } from "../../../../../coreUtils";
import { FixedField } from "../../../../../components/FixedField";
import { DatePicker } from "../../../../../components/DatePicker";
import { toastr } from "react-redux-toastr";

interface Props {
  isOpen: boolean;
  toggle: () => void;
  selected: any;
  notifyEvent: () => void;
}

export const IncluirPropostaLeadModal: FC<Props> = ({
  isOpen,
  toggle,
  selected,
  notifyEvent,
}) => {
  const [nomeEmpresa, setNomeEmpresa] = useState("");
  const [dataEnvProposta, setDataEnvProposta] = useState<any>(null);
  const [vlrMaiorProposta, setVlrMaiorProposta] = useState(null);
  const [observ, setObserv] = useState("");
  const [loading, setLoading] = useState(false);

  const editorRef = useRef<any>();

  const limparDados = () => {
    setNomeEmpresa("");
    setDataEnvProposta(null);
    setVlrMaiorProposta(null);
    setObserv("");
  };

  const carregarDadosLead = async () => {
    const [ok, ret] = await LeadService.buscar(selected);
    if (ok) {
      setNomeEmpresa(formatValueFromAPI(ret.nome_empresa, selected));
    }
  };

  const handleSubmit = async () => {
    if ([null, undefined].includes(dataEnvProposta)) {
      toastr.warning(
        "Atenção",
        "Por favor, informe a Data de Envio da Proposta"
      );
      return;
    }

    if (!(dataEnvProposta instanceof Date)) {
      toastr.warning(
        "Atenção",
        "A Data de Envio da Proposta é inválida. " +
          "Por favor, Revise-a e tente novamente"
      );
      return;
    }

    if ([0, null, undefined].includes(vlrMaiorProposta)) {
      toastr.warning("Atenção", "Por favor, informe o Valor da Maior Proposta");
      return;
    }

    const payload = {
      id_lead: selected,
      data_env_proposta: formatDateISO(dataEnvProposta),
      vlr_maior_proposta: vlrMaiorProposta,
      observ: observ,
      id_colab_mencionados: extrairIdColabMencionados(editorRef),
    };

    setLoading(true);
    const [ok] = await LeadService.proposta.incluir(payload);
    if (ok) {
      toggle();
      notifyEvent();
    }
    setLoading(false);
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
      title="Incluir Proposta ao Lead"
      number="011_7"
      onClosed={limparDados}
      onConfirm={handleSubmit}
      loadingConfirm={loading}
      onBeforeOpen={carregarDadosLead}
    >
      <Row className="mb-3">
        <FixedField label="Empresa" value={nomeEmpresa} horizontal />
      </Row>
      <Row className="mb-3">
        <DatePicker
          md={3}
          label="Data de Envio da Proposta"
          value={dataEnvProposta}
          onChange={setDataEnvProposta}
        />
        <NumberInput
          md={3}
          label="Valor da Maior Proposta"
          value={vlrMaiorProposta}
          onChange={setVlrMaiorProposta}
        />
      </Row>
      <Label>Observação</Label>
      <AtualizacoesEditor
        value={observ}
        onChange={setObserv}
        formats={interacaoLeadEditorFormats}
        editorRef={editorRef}
      />
    </ModalBase>
  );
};
