const settings = {
  AUTENTICADOR: "https://auth.skillsoft.com.br",
  API_ADDRESS: "https://ci-api.skillsoft.com.br",
  AUTENTICADOR_DEV: "http://localhost:6439",
  API_ADDRESS_DEV: "http://localhost:5003",
  API_SKILL_ADDRESS: "https://financ-api.skillsoft.com.br",
  API_SKILL_ADDRESS_DEV: "http://192.168.0.23:5000",
  WS_NOTIF_ADDRESS: "wss://ws-notificacoes.skillsoft.com.br",
  WS_NOTIF_ADDRESS_DEV: "ws://localhost:4567",
};

export default settings;
