import { FC } from "react";
import { TabBody, TabBodyProps } from "../../../../components/TabController";
import { ItemCheckbox } from "./ItemCheckbox";
import { useDispatch, useSelector } from "react-redux";
import { AtualizacoesSetorForm } from "./AtualizacoesSetorForm";
import ImplantacaoService from "../../../../services/ImplantacaoService";
import { setAtuFinanc, setupFinanc } from "../store/detalhesImplantSlice";

interface Props extends TabBodyProps {}

export const TabFinanceiro: FC<Props> = ({ title, active }) => {
  const store = useSelector((state: any) => state.detalhesImplant);
  const itens = store.financ;
  const dispatch = useDispatch();

  const carregarDados = async () => {
    const [ok, ret] = await ImplantacaoService.buscarItens(
      store.selected,
      "FIN"
    );
    if (ok) {
      dispatch(setupFinanc(ret));
    }
  };

  return (
    <TabBody title={title} active={active} onActivate={() => carregarDados()}>
      <ItemCheckbox
        label="Registrar Venda de Serviço de Implantação e Registrar Possível Saldo Devedor no Crédito Rotativo"
        name="reg_venda_implantacao"
        checked={itens.reg_venda_implantacao}
        notifyEvent={carregarDados}
      />
      <ItemCheckbox
        label="Lançar o contas a receber até o mês 12. (lembrar de deixar as parcelas iguais ao mês competente)"
        name="cta_receber_lancado"
        checked={itens.cta_receber_lancado}
        notifyEvent={carregarDados}
      />
      <ItemCheckbox
        label="Confirmação de dados cadastrais com o cliente (ver endereço de implantação e o tipo de envio da cobrança)"
        name="dados_cadastrais_ok"
        checked={itens.dados_cadastrais_ok}
        notifyEvent={carregarDados}
      />
      <ItemCheckbox
        label="Solicitar certificado"
        name="certificado_ok"
        checked={itens.certificado_ok}
        notifyEvent={carregarDados}
      />
      <ItemCheckbox
        label="Enviar a Formalização de serviços por e-mail ou whats"
        name="formalizou_serv"
        checked={itens.formalizou_serv}
        notifyEvent={carregarDados}
      />
      <ItemCheckbox
        label="Cadastrar Logotipo da Empresa no Sistema Interno"
        name="logo_configurado"
        checked={itens.logo_configurado}
        notifyEvent={carregarDados}
      />
      <ItemCheckbox
        label="Gerar nota do pagamento da implantação (caso seja pix)"
        name="gera_nf_implantacao"
        checked={itens.gera_nf_implantacao}
        notifyEvent={carregarDados}
      />
      <ItemCheckbox
        label="Fazer contrato do cliente."
        name="contrato_gerado"
        checked={itens.contrato_gerado}
        notifyEvent={carregarDados}
      />
      <ItemCheckbox
        label="Ajustar a cobrança com o período proporcional a data de início de uso do sistema"
        name="cobranca_proporcional_ok"
        checked={itens.cobranca_proporcional_ok}
        notifyEvent={carregarDados}
      />
      <ItemCheckbox
        label="Incluir cliente na planilha de Reajuste de clientes"
        name="planilha_reajuste"
        checked={itens.planilha_reajuste}
        notifyEvent={carregarDados}
      />
      <ItemCheckbox
        label="Preencher planilha de Comissionamento do Representante que conquistou o Cliente"
        name="planilha_comissao"
        checked={itens.planilha_comissao}
        notifyEvent={carregarDados}
      />
      <AtualizacoesSetorForm
        setor="FIN"
        abaAtiva={active ?? false}
        dados={itens.atualizacoes}
        setDados={(d) => dispatch(setAtuFinanc(d))}
      />
    </TabBody>
  );
};
