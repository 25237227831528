import { useState, FC, useEffect } from "react";
import { Card, Row } from "reactstrap";
import {
  AsyncComboBox,
  BotaoPesquisar,
  SearchInput,
  TextInput,
} from "../../../../components";
import { PageContainer } from "../../../../components/PageContainer";
import { debounce, defaultDebounceTime } from "../../../../coreUtils";
import { CadastroMenuModal } from "./components/CadastroMenuModal";
import { MenuGrid } from "./components/MenuGrid";
import { APIs, authGetV2 } from "../../../../apiV2";
import { BotaoIncluir, ModalExcluirV2 } from "../../../../components/cadastro";
import { ModalActions } from "../../../../components/cadastro/ModalCadastroV2";
import { LinkButton } from "../../../../components/LinkButton";
import { excecaoMenuRoute } from "../../../../routes/modules/desenvolvimento";

export const modalTitleMenu = "Menu";
export const routesBaseMenu = "/cadastro/menu";

export const Menu: FC = () => {
  const [nroTela, setNroTela] = useState<string | null>(null);
  const [pista, setPista] = useState("");
  const [idPlano, setIdPlano] = useState(null);
  const [selected, setSelected] = useState(null);
  const [dados, setDados] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingPista, setLoadingPista] = useState(false);
  const [action, setAction] = useState(ModalActions.add);
  const [cadastroOpen, setCadastroOpen] = useState(false);
  const [excluirOpen, setExcluirOpen] = useState(false);

  const buscarDados = async (p = pista, nrTela = nroTela) => {
    const params = {
      nro_tela: nrTela,
      descricao: p,
      id_plano: idPlano,
    };

    const [ok, ret] = await authGetV2(`${routesBaseMenu}/listar/`, params);
    setDados(ok ? ret : []);
  };

  const carregarDados = async () => {
    setLoading(true);
    await buscarDados();
    setLoading(false);
  };

  const handlePista = debounce(async (p: any) => {
    setPista(p);
    setLoadingPista(true);
    await buscarDados(p);
    setLoadingPista(false);
  }, defaultDebounceTime);

  const handleSetNroTela = debounce(async (v: any) => {
    setNroTela(v);
    setLoading(true);
    await buscarDados("", v);
    setLoading(false);
  }, defaultDebounceTime);

  const notifyEvent = (action: any) => {
    if (action === ModalActions.delete) setSelected(null);
    carregarDados();
  };

  useEffect(() => {
    carregarDados();
  }, []);

  const toggleCadastro = (action: any) => {
    setAction(action);
    setCadastroOpen(!cadastroOpen);
  };

  const toggleExcluir = () => setExcluirOpen(!excluirOpen);

  const alterar = (id: any) => {
    setSelected(id);
    setTimeout(() => {
      toggleCadastro(ModalActions.edit);
    }, 1);
  };

  const excluir = (id: any) => {
    setSelected(id);
    setTimeout(() => {
      toggleExcluir();
    }, 1);
  };

  return (
    <PageContainer title="Menu" number="012" canGoBack>
      <Card body>
        <Row>
          <TextInput
            md={2}
            label="Nº Tela"
            onChange={handleSetNroTela}
            maxLength={4}
          />
          <SearchInput
            md={4}
            onChange={handlePista}
            loading={loadingPista}
            placeholder="Descrição"
          />
        </Row>
        <Row>
          <AsyncComboBox
            md={3}
            isConcatField
            concatModelName="plano_contratado"
            defaultOptions
            label="Plano"
            isSearchable
            isClearable
            onChange={setIdPlano}
          />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <BotaoIncluir toggle={() => toggleCadastro(ModalActions.add)} />
          <CadastroMenuModal
            isOpen={cadastroOpen}
            toggle={toggleCadastro}
            action={action}
            selected={selected}
            notifyEvent={notifyEvent}
          />
          <ModalExcluirV2
            title={modalTitleMenu}
            isOpen={excluirOpen}
            toggle={toggleExcluir}
            number="012_2"
            selected={selected}
            routeBase={routesBaseMenu}
            notifyEvent={notifyEvent}
            useApi={APIs.auth}
          />
          <LinkButton pathname={excecaoMenuRoute.path} color="primary">
            Exceções
          </LinkButton>
        </Row>
      </Card>
      <Card body>
        <MenuGrid
          data={dados}
          setSelected={setSelected}
          alterar={alterar}
          excluir={excluir}
        />
      </Card>
    </PageContainer>
  );
};
