import { FC, useState } from "react";
import { Col, CustomInput, Label, Row } from "reactstrap";
import { AsyncComboBox, FormButton, TextInput } from "../../../../components";
import { ClassifCli } from "../../../../components/ClassifCli";
import { Divider } from "../../../../components/Divider";
import { ModalBase } from "../../../../components/ModalBase";
import {
  RadioGroup,
  RadioGroupOption,
} from "../../../../components/RadioGroup";
// import ClienteService from "../../../../services/ClienteService";
import PendenciaService from "../../../../services/PendenciaService";

interface Props {
  notifyEvent: any;
}

const origemOptions: RadioGroupOption[] = [
  { label: "WhatsApp", value: "WHATS" },
  { label: "Telefone Fixo", value: "TFIXO" },
  { label: "Celular", value: "TCELU" },
  { label: "E-Mail", value: "EMAIL" },
];

const formaRetornoOptions: RadioGroupOption[] = [
  { label: "WhatsApp", value: "WHATS" },
  { label: "Telefone", value: "TFONE" },
  { label: "E-Mail", value: "EMAIL" },
];

export const IncluirDemandaSuporteModal: FC<Props> = ({ notifyEvent }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [cliente, setCliente] = useState(0);
  const [dadosCliente, setDadosCliente] = useState<any>();
  const [origem, setOrigem] = useState("WHATS");
  const [formaRetorno, setFormaRetorno] = useState("WHATS");
  const [contatoRetorno, setContatoRetorno] = useState("");
  const [foneRetorno, setFoneRetorno] = useState("");
  const [emailRetorno, setEmailRetorno] = useState("");
  const [suporte, setSuporte] = useState(false);
  const [admFinanc, setAdmFinanc] = useState(false);
  const [comercial, setComercial] = useState(false);
  const [desenvolv, setDesenvolv] = useState(false);
  const [loading, setLoading] = useState(false);

  const limparDados = () => {
    setCliente(0);
    setDadosCliente({});
    setOrigem("WHATS");
    setFormaRetorno("WHATS");
    setContatoRetorno("");
    setFoneRetorno("");
    setEmailRetorno("");
    setSuporte(false);
    setAdmFinanc(false);
    setComercial(false);
    setDesenvolv(false);
  };

  const toggle = () => setIsOpen(!isOpen);

  // const buscarDadosCliente = async (idCliente: any) => {
  //   if (![0, null, undefined].includes(idCliente)) {
  //     setDadosCliente(await ClienteService.buscarDadosPendencia(idCliente));
  //   } else {
  //     setDadosCliente({});
  //   }
  // };

  const handleSetCliente = (v?: any) => {
    setCliente(v);
    // buscarDadosCliente(v);
  };

  const handleSetFormaRetorno = (s: any) => {
    setFormaRetorno(s);
    setFoneRetorno("");
    setEmailRetorno("");
    if (s === "WHATS") {
      setFoneRetorno(dadosCliente?.whatsapp?.trim() ?? "");
    } else if (s === "TFONE") {
      setFoneRetorno(dadosCliente?.fone1?.trim() ?? "");
    } else if (s === "EMAIL") {
      setEmailRetorno(dadosCliente?.email?.trim() ?? "");
    }
  };

  const handleSubmit = async () => {
    setLoading(true);

    const payload = {
      id_cliente: cliente ?? 0,
      origem: origem,
      forma_retorno: formaRetorno,
      retorno_nome_contato: contatoRetorno,
      retorno_fone: foneRetorno,
      retorno_email: emailRetorno,
      pend_suporte: suporte,
      pend_adm_financ: admFinanc,
      pend_comercial: comercial,
      pend_desenvolv: desenvolv,
    };

    if (await PendenciaService.incluir(payload)) {
      notifyEvent();
      toggle();
    }
    setLoading(false);
  };

  return (
    <>
      <FormButton md="auto" color="info" onClick={toggle}>
        Incluir Demanda
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        onClosed={limparDados}
        title="Incluir Demanda de Suporte"
        number="0004_1"
        onConfirm={handleSubmit}
        loadingConfirm={loading}
      >
        <Row className="mb-3">
          <AsyncComboBox
            md={8}
            isConcatField
            concatModelName="cliente"
            label="Cliente"
            isSearchable
            onChange={handleSetCliente}
            value={cliente}
          />
          {dadosCliente && (
            <>
              <ClassifCli value={dadosCliente?.classif_cli} label height={30} />
              <TextInput
                label="Plano Contratado"
                value={dadosCliente?.nome_plano_contratado}
                disabled
              />
            </>
          )}
        </Row>
        {dadosCliente && (
          <Row className="mb-3">
            <TextInput
              divClassName="pr-0"
              label="Fone"
              value={dadosCliente?.fone1}
              disabled
            />
            <TextInput
              divClassName="pr-0"
              label="Fone 2"
              value={dadosCliente?.fone2}
              disabled
            />
            <TextInput
              divClassName="pr-0"
              label="Fone 3"
              value={dadosCliente?.fone3}
              disabled
            />
            <TextInput
              divClassName="pr-0"
              label="WhatsApp"
              value={dadosCliente?.whatsapp}
              disabled
            />
            <TextInput
              md={4}
              label="E-Mail"
              value={dadosCliente?.email}
              disabled
            />
          </Row>
        )}
        <Row className="mb-3">
          <RadioGroup
            label="Cliente entrou em Contato Por"
            value={origem}
            onChange={setOrigem}
            options={origemOptions}
          />
        </Row>
        <Divider>Retorno</Divider>
        <Row className="mb-3">
          <RadioGroup
            label="Retornar Por"
            value={formaRetorno}
            onChange={handleSetFormaRetorno}
            options={formaRetornoOptions}
          />
        </Row>
        <Row className="mb-3">
          <TextInput
            md={4}
            divClassName="pr-0"
            label="Contato de Retorno"
            value={contatoRetorno}
            onChange={setContatoRetorno}
            maxLength={80}
          />
          {formaRetorno === "EMAIL" ? (
            <TextInput
              md={5}
              label="E-Mail"
              value={emailRetorno}
              onChange={setEmailRetorno}
              maxLength={80}
            />
          ) : (
            <TextInput
              md={5}
              label="Fone"
              value={foneRetorno}
              onChange={setFoneRetorno}
              maxLength={18}
            />
          )}
        </Row>
        <Divider>Demanda</Divider>
        <Row className="mb-3">
          <Col md="auto">
            <Label inline>Setor(es):</Label>
            <div>
              <CustomInput
                type="checkbox"
                name="pend_suporte"
                id="pend_suporte"
                checked={suporte}
                onChange={() => setSuporte(!suporte)}
                htmlFor="pend_suporte"
                label="Suporte"
                inline
              />
              <CustomInput
                type="checkbox"
                name="pend_adm_financ"
                id="pend_adm_financ"
                checked={admFinanc}
                onChange={() => setAdmFinanc(!admFinanc)}
                htmlFor="pend_adm_financ"
                label="Financeiro | ADM"
                inline
              />
              <CustomInput
                type="checkbox"
                name="pend_comercial"
                id="pend_comercial"
                checked={comercial}
                onChange={() => setComercial(!comercial)}
                htmlFor="pend_comercial"
                label="Comercial"
                inline
              />
              <CustomInput
                type="checkbox"
                name="pend_desenvolv"
                id="pend_desenvolv"
                checked={desenvolv}
                onChange={() => setDesenvolv(!desenvolv)}
                htmlFor="pend_desenvolv"
                label="Desenvolvimento"
                inline
              />
            </div>
          </Col>
        </Row>
      </ModalBase>
    </>
  );
};
