import { apiGetV2, apiPostV2 } from "../apiV2";

const URL_BASE = "/notificacao";
const NotificacoesService = {
  buscarNotifUsuario: async (params) =>
    await apiGetV2(`${URL_BASE}/buscar_notif_usuario/`, params),
  ler: async (payload) =>
    apiPostV2(`${URL_BASE}/ler/`, payload, {
      successMessage: false,
      errorMessage: false,
    }),
};

export default NotificacoesService;
