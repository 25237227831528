import React, { FC, ReactNode } from "react";

interface Props {
  children: ReactNode;
}

const Content: FC<Props> = ({ children }) => (
  <div className="content">{children}</div>
);

export default Content;
