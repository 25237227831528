import Cookies from "js-cookie";
import { useState } from "react";
import { singletonHook } from "react-singleton-hook";
import { revokeToken } from "../api";

const initialState = {
  authToken: Cookies.get("token"),
  dbName: Cookies.get("database-name"),
  apiAddress: Cookies.get("api-address"),
};

let configureSessionGlobal = () => {
  throw new Error("Você deve chamar useSession antes de logar.");
};

const useSessionImpl = () => {
  const [session, setSession] = useState(initialState);
  configureSessionGlobal = setSession;
  return session;
};

export const useSession = singletonHook(initialState, useSessionImpl);

export const login = (authToken, dbName, apiAddress) => {
  Cookies.set("token", authToken);
  Cookies.set("database-name", dbName);
  Cookies.set("api-address", apiAddress);
  configureSessionGlobal({ authToken, dbName, apiAddress });
};

export const logoff = async () => {
  await revokeToken();
  Cookies.remove("access_token");
  Cookies.remove("refresh_token");
};
