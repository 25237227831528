import React, { FC } from "react";
import FormButton from "./FormButton";

interface Props {
  md?: number | string;
  padded?: boolean;
  loading?: boolean;
  onClick?: any;
  className?: string;
  divClassName?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  disabledHint?: any;
  text?: any;
}

export const BotaoImprimir: FC<Props> = ({
  md = "auto",
  padded = true,
  loading,
  onClick,
  className = "",
  divClassName = "",
  style,
  disabled,
  disabledHint,
  text = "Imprimir",
}) => {
  return (
    <FormButton
      color="primary"
      onClick={onClick}
      md={md}
      loading={loading}
      padded={padded}
      className={className}
      divClassName={divClassName}
      style={style}
      disabled={loading || disabled}
      disabledHint={disabledHint}
    >
      {text}
    </FormButton>
  );
};
