import { FC } from "react";
import ReactQuill from "react-quill";
import "quill-mention";
import ComboBoxService from "../services/components/ComboBoxService";
import classNames from "classnames";

export interface AtualizacoesEditorProps {
  value: ReactQuill["props"]["value"];
  onChange: ReactQuill["props"]["onChange"];
  editorRef?: any;
  modules?: any;
  formats?: string[];
  className?: string;
  onKeyDown?: ReactQuill["props"]["onKeyDown"];
}

const defaultModules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image"],
  ],
  clipboard: {
    matchVisual: false,
  },
  mention: {
    allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
    mentionDenotationChars: ["@"],
    source: async function (searchTerm: any, renderList: any) {
      const colabs = await ComboBoxService.fetchOptions("colaborador", {
        nome__istartswith: searchTerm,
      });
      renderList(colabs.map((e: any) => ({ id: e.value, value: e.nome })));
    },
  },
};

const defaultFormats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "align",
  "link",
  "image",
  "code",
  "mention",
];

export const AtualizacoesEditor: FC<AtualizacoesEditorProps> = ({
  value,
  onChange,
  editorRef,
  modules,
  formats,
  className,
  onKeyDown,
}) => {
  return (
    <ReactQuill
      modules={modules ?? defaultModules}
      formats={formats ?? defaultFormats}
      value={value}
      onChange={onChange}
      theme="snow"
      ref={editorRef}
      className={classNames("atu-editor", className)}
      onKeyDown={onKeyDown}
    />
  );
};
