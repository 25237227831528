import { RouteType } from "..";
import { MenuGroup } from "../../components/MenuGroup";
import { Menu } from "../../pages/desenvolvimento/cadastros/menu/Menu";
import { Excecao } from "../../pages/desenvolvimento/cadastros/menu/excecao/Excecao";
import { BsListCheck } from "react-icons/bs";
import { RiFunctionLine } from "react-icons/ri";
import { GoTasklist } from "react-icons/go";
import { Modulo } from "../../pages/desenvolvimento/cadastros/modulo/Modulo";
import { Desenvolvimento } from "../../pages/desenvolvimento/demandas/Desenvolvimento";
import { IncluirAlterarDemandaDev } from "../../pages/desenvolvimento/demandas/incluir_alterar/IncluirAlterarDemandaDev";

export const excecaoMenuRoute: RouteType = {
  id: "AcSk012",
  name: "Exceções do Menu",
  path: "/desenvolvimento/cadastros/menu/excecao/",
  component: Excecao,
};

export const desenvolvimentoCadastrosRoute: RouteType = {
  name: "Cadastros",
  path: "/desenvolvimento/cadastros/",
  children: [
    {
      id: "AcSk012",
      path: "/desenvolvimento/cadastros/menu/",
      name: "Menu",
      component: Menu,
      icon: BsListCheck,
    },
    {
      id: "AcSk006",
      path: "/desenvolvimento/cadastros/modulos/",
      name: "Módulo",
      component: Modulo,
      icon: RiFunctionLine,
    },
  ],
};

export const incluirAlterarDemandaDevRoute: RouteType = {
  id: "AcSk015",
  path: "/processos/desenvolvimento/demanda/",
  name: "Desenvolvimento",
  component: IncluirAlterarDemandaDev,
};

export const desenvolvimentoDemandasRoute: RouteType = {
  id: "AcSk015",
  path: "/desenvolvimento/processos/demandas/",
  name: "Demandas",
  component: Desenvolvimento,
  icon: GoTasklist,
};

export const desenvolvimentoProcessosRoute: RouteType = {
  name: "Processos",
  path: "/desenvolvimento/processos/",
  children: [desenvolvimentoDemandasRoute],
};

export const desenvolvimentoRoutes: RouteType = {
  name: "Desenvolvimento",
  path: "/desenvolvimento/",
  component: () =>
    MenuGroup({
      routes: [desenvolvimentoCadastrosRoute, desenvolvimentoProcessosRoute],
      title: "Desenvolvimento",
    }),
};
