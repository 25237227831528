import * as types from "../constants";

const initialState = {
  title: "",
  number: "",
  modalNumber: "",
  onBeforeGoingBack: () => {},
  topOptions: () => {},
  goBackRoute: null,
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.SET_PAGE_TITLE:
      return { ...state, title: actions.data };
    case types.SET_PAGE_NUMBER:
      return { ...state, number: actions.data };
    case types.SET_PAGE_MODAL_NUMBER:
      return { ...state, modalNumber: actions.data };
    case types.SET_PAGE_ON_BEFORE_GOING_BACK:
      return { ...state, onBeforeGoingBack: actions.data };
    case types.SET_PAGE_TOP_OPTIONS:
      return { ...state, topOptions: actions.data };
    case types.SET_GO_BACK_ROUTE:
      return { ...state, goBackRoute: actions.data };
    default:
      return state;
  }
}
