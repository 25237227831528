import React, { FC, ReactNode } from "react";

interface Props {
  className?: string;
  children: ReactNode;
}

const Main: FC<Props> = ({ className, children }) => (
  <div className={"main " + className}>{children}</div>
);

export default Main;
