import React, {
  FC,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import InputMask from "react-input-mask";
import { Col, FormFeedback, FormGroup, Input, Label } from "reactstrap";
import { InputType } from "reactstrap/lib/Input";
import { handleFocus } from "../coreUtils";

interface Props {
  md?: string | number;
  mdInput?: string | number;
  label?: any;
  type?: InputType;
  name?: string;
  onChange?: (v: any, e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: any;
  className?: string;
  inline?: any;
  check?: any;
  required?: boolean;
  invalid?: boolean;
  disabled?: boolean;
  placeholder?: any;
  mask: string;
  ref?: any;
}
const MaskedInput: FC<Props> = forwardRef(
  (
    {
      md,
      mdInput = 12,
      label,
      type = "text",
      name,
      onChange,
      value,
      className,
      inline = false,
      check = false,
      required = false,
      invalid = false,
      disabled = false,
      placeholder = "",
      mask,
    },
    ref
  ) => {
    const [innerValidation, setInnerValidation] = useState(true);
    const [internalValue, setInternalValue] = useState("");
    const inputRef = useRef<any>();

    const validateInternally = (e: any) => {
      if (required && e.target.value === "") {
        setInnerValidation(false);
      } else {
        setInnerValidation(true);
      }
    };

    useEffect(() => setInternalValue(value?.toUpperCase()), [value]);

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      const inputValue = e.target.value?.toUpperCase() || "";
      setInternalValue(inputValue);
      if (onChange) {
        onChange(inputValue, e);
      }
    };

    useImperativeHandle(ref, () => ({
      isValid: () => innerValidation && !invalid,
      focus: () =>
        setTimeout(() => inputRef.current && inputRef.current.focus(), 35),
      value: internalValue?.toUpperCase()?.trim(),
      setValue: (val: any) => setInternalValue(val),
    }));

    return (
      <Col md={md}>
        <FormGroup check={check} inline={inline}>
          <Label check={check}>{label}</Label>
          <Col md={mdInput} className="no-gutters">
            <InputMask
              mask={mask}
              onChange={handleOnChange}
              value={internalValue}
              onBlur={validateInternally}
              disabled={disabled}
            >
              {() => (
                <Input
                  type={type}
                  name={name}
                  defaultValue={value}
                  innerRef={inputRef}
                  className={className}
                  required={required}
                  invalid={!innerValidation || invalid}
                  placeholder={placeholder}
                  onKeyDown={handleFocus}
                />
              )}
            </InputMask>
          </Col>
          <FormFeedback valid={false}>Este campo é obrigatório</FormFeedback>
        </FormGroup>
      </Col>
    );
  }
);

export default MaskedInput;
