import React, { FC, useState } from "react";
import { Info } from "react-feather";
import { FiZap } from "react-icons/fi";
import { Label, UncontrolledTooltip } from "reactstrap";
import * as Popper from "popper.js";

interface Props {
  label: string;
  hintText: string;
  paramHintText?: string;
  enabled?: boolean;
  tooltipPlacement?: Popper.Placement;
  labelStyle?: React.CSSProperties;
  hidden?: boolean;
  required?: boolean;
  className?: string;
}

export const HintLabel: FC<Props> = ({
  label,
  hintText,
  paramHintText,
  enabled = true,
  tooltipPlacement,
  labelStyle,
  hidden,
  required,
  className,
}) => {
  const [id] = useState(`hint-${Math.floor(Math.random() * Date.now())}`);
  const [idParam] = useState(
    `hint-param-${Math.floor(Math.random() * Date.now())}`
  );
  return (
    <>
      <Label style={labelStyle} className={className} hidden={hidden}>
        {label}{" "}
        {required && (
          <>
            <span id={`${id}-required`} style={{ color: "#002a57" }}>
              *
            </span>
            <UncontrolledTooltip target={`${id}-required`}>
              Campo obrigatório
            </UncontrolledTooltip>
          </>
        )}
      </Label>
      {enabled && hintText && (
        <>
          <Info
            size={12}
            id={id}
            style={{ marginTop: "-2px", marginLeft: "3px" }}
          />
          <UncontrolledTooltip target={id} placement={tooltipPlacement}>
            <div style={{ textAlign: "left" }}>{hintText}</div>
          </UncontrolledTooltip>
        </>
      )}
      {enabled && paramHintText && (
        <>
          <FiZap
            size={12}
            id={idParam}
            style={{ marginTop: "-2px", marginLeft: "3px" }}
          />
          <UncontrolledTooltip target={idParam} placement={tooltipPlacement}>
            <div style={{ textAlign: "left" }}>{paramHintText}</div>
          </UncontrolledTooltip>
        </>
      )}
    </>
  );
};
