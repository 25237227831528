import { LiaStoreAltSolid } from "react-icons/lia";
import { RouteType } from "..";
import { MenuGroup } from "../../components/MenuGroup";
import { CadastralEmpresas } from "../../pages/relatorios/cadastrais/empresas/CadastralEmpresas";

export const relatoriosCadastraisRoute = {
  name: "Cadastrais",
  path: "/relatorios/cadastrais",
  children: [
    {
      id: "AcSk501",
      name: "Empresas",
      path: "/relatorios/cadastrais/empresas",
      component: CadastralEmpresas,
      icon: LiaStoreAltSolid,
    },
  ],
};

export const relatoriosRoutes: RouteType = {
  name: "Relatórios",
  path: "/relatorios",
  component: () =>
    MenuGroup({
      routes: [relatoriosCadastraisRoute],
      title: "Cadastrais",
    }),
};
