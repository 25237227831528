import React, { FC, ReactNode } from "react";

import Wrapper from "../components/Wrapper";
import Sidebar from "../components/Sidebar";
import Main from "../components/Main";
import Navbar from "../components/Navbar";
import Content from "../components/Content";

export interface LayoutProps {
  username?: string;
  children: ReactNode;
}

const Dashboard: FC<LayoutProps> = ({ username, children }) => {
  return (
    <React.Fragment>
      <Wrapper>
        <Sidebar username={username} />
        <Main>
          <Navbar />
          <Content>{children}</Content>
        </Main>
      </Wrapper>
    </React.Fragment>
  );
};

export default Dashboard;
