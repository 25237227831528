import { LuPencilLine } from "react-icons/lu";
import { IconButton } from "../IconButton";
import { DropdownItem } from "reactstrap";
import { MoreDropdown } from "../MoreDropdown";

export const iconeAlterar = (
  alterar = () => {},
  { keyField = "id", disabled, disabledHint, hint = "Alterar" } = {}
) => ({
  dataField: "edt",
  text: "",
  align: "center",
  colWidth: "32px",
  fixedColWidth: true,
  selectOnClick: false,
  cellContentTag: null,
  formatter: (c, row) => (
    <IconButton
      icon={LuPencilLine}
      size={16}
      disabled={
        typeof disabled === "function" ? disabled(row[keyField], row) : disabled
      }
      disabledHint={
        typeof disabledHint === "function"
          ? disabledHint(row[keyField], row)
          : disabledHint
      }
      onClick={() => alterar(row[keyField], row)}
      hint={hint}
      tooltipPlacement="left"
      color="#dba400"
    />
  ),
});

export const dropdownAcoes = ({
  excluir = () => {},
  keyField = "id",
  outrasAcoes,
} = {}) => ({
  dataField: "act",
  text: "",
  align: "center",
  colWidth: "32px",
  fixedColWidth: true,
  selectOnClick: false,
  cellContentTag: null,
  formatter: (c, row) => (
    <MoreDropdown horizontal>
      {outrasAcoes && (
        <>
          {outrasAcoes(row[keyField], row)}
          <hr />
        </>
      )}
      <DropdownItem
        style={{ color: "red" }}
        onClick={() => excluir(row[keyField])}
      >
        Excluir
      </DropdownItem>
    </MoreDropdown>
  ),
});
