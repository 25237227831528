import React, { FC } from "react";
import FormButton from "./FormButton";

interface Props{
  md?: any;
  padded?: any;
  loading?: any;
  onClick?: any;
  className?: any;
  divClassName?: any;
  style?: any;
  disabled?: any;
}

export const BotaoPesquisar: FC<Props> = ({
  md = "auto",
  padded = true,
  loading,
  onClick,
  className = "",
  divClassName = "",
  style = {},
  disabled,
}) => {
  return (
    <FormButton
      color="secondary"
      onClick={onClick}
      md={md}
      loading={loading}
      padded={padded}
      className={className}
      divClassName={divClassName}
      style={style}
      disabled={loading || disabled}
    >
      Pesquisar
    </FormButton>
  );
};
