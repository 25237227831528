import React, { FC } from "react";
import classNames from "classnames";
import TableNumberInput from "./TableNumberInput";
import TableTextInput from "./TableTextInput";
import TableTextarea from "./TableTextarea";
import { handleTableFocus } from "../../coreUtils";
import { EditModeInfo } from "../Table";
import { TableCombobox, TableComboboxProps } from "./TableCombobox";
import { ComboBoxOption } from "../ComboBox";
import { TableAsyncCombobox } from "./TableAsyncComboBox";

interface EditableInputWrapperProps {
  children: HTMLCollection | React.ReactNode;
  endEdit: () => void;
}

const EditableInputWrapper: FC<EditableInputWrapperProps> = ({
  children,
  endEdit,
}) => (
  <div
    onBlur={() => {
      endEdit();
    }}
  >
    {children}
  </div>
);

type CSSOrFunction =
  | ((value: any, row: any, index: number) => React.CSSProperties)
  | React.CSSProperties;

export interface TableColumn {
  text: any;
  dataField: string;
  align: "left" | "right" | "center";
  formatter?: (cell: any, row: any, index: number) => any;
  editable?: boolean | ((value: any, row: any) => boolean);
  editorType?: "number" | "textarea" | "combobox";
  alwaysShowEditor?: boolean;
  onChange?: (dataField: any, newValue: any, row: any) => void;
  onBlur?: (row: any, value: any, index: number) => void;
  decimalPlaces?: number;
  isPercentage?: boolean;
  borderless?: boolean;
  hidden?: boolean;
  colWidth?: string;
  fixedColWidth?: boolean;
  dummy?: boolean;
  style?: CSSOrFunction;
  sortable?: boolean;
  sortFunc?: (a: any, b: any, order: "asc" | "desc") => number;
  colHeaderStyle?: React.CSSProperties;
  selectOnClick?: boolean;
  footer?: any;
  colFooterStyle?: React.CSSProperties;
  hint?: any;
  editorMaxLength?: number;
  editorForceUpperCase?: boolean;
  editorStyle?: CSSOrFunction;
  cellContentTag?: any;
  options?: ComboBoxOption[];
  optionsRenderer?: TableComboboxProps["optionsRenderer"];
  concatModelName?: string;
  concatLabelField?: string;
  hideSortIcon?: boolean;
}

interface Props {
  tableId?: any;
  row: any;
  keyField: string;
  hover?: boolean;
  striped?: boolean;
  rowSelect: boolean;
  handleRowClick: (key: any, row: any) => void;
  selectable: boolean;
  onSelect: (key: any, checked: boolean, row: any) => void;
  multiselect: boolean;
  isSelected: boolean;
  index: number;
  checkOnEdit: (row: any, dataField: any) => boolean;
  columns: Array<TableColumn>;
  endEdit: () => void;
  setEditModeInfo: React.Dispatch<React.SetStateAction<EditModeInfo>>;
  onRowEnterPress?: () => void;
  onRowDoubleClick?: (key: any, row: any, index: number) => void;
  selectColumnPosition: "left" | "right";
  rowStyle?: React.CSSProperties;
  hideSelectColumn?: boolean;
}

const TableRow: FC<Props> = ({
  tableId,
  row,
  keyField,
  hover,
  striped,
  rowSelect,
  handleRowClick,
  selectable,
  onSelect,
  multiselect,
  isSelected,
  index,
  checkOnEdit,
  columns,
  endEdit,
  setEditModeInfo,
  onRowEnterPress,
  onRowDoubleClick,
  selectColumnPosition,
  rowStyle,
  hideSelectColumn = false,
}) => {
  const _startEdit = (rowKey: any, dataField: any) => {
    setEditModeInfo({
      onEdit: true,
      rowKeyField: rowKey,
      dataField: dataField,
    });
  };

  const buildRows = (row: any) => {
    return columns.map(
      ({
        dataField,
        align,
        formatter,
        editable,
        editorType,
        alwaysShowEditor,
        onChange,
        onBlur,
        decimalPlaces,
        isPercentage,
        borderless,
        hidden,
        colWidth,
        fixedColWidth,
        dummy,
        style,
        selectOnClick = true,
        editorMaxLength,
        options,
        optionsRenderer,
        editorForceUpperCase,
        editorStyle,
        cellContentTag: CellContentTag = "span",
        concatModelName,
        concatLabelField,
      }) => {
        if (hidden) {
          return <td style={{ display: "hidden" }}></td>;
        }

        let child;
        let inputSize;
        let value = row[dataField];
        if (dummy) {
          value = formatter && formatter(undefined, row, index);
        }
        if (formatter) {
          value = formatter(row[dataField], row, index);
        }

        if (value?.length > 50) {
          inputSize = 50;
        } else {
          inputSize = value?.length + 5;
        }
        let isEditable;
        if (typeof editable === "function") {
          isEditable = editable(value, row);
        } else {
          isEditable = editable;
        }
        if (isEditable && (checkOnEdit(row, dataField) || alwaysShowEditor)) {
          if (typeof editorStyle === "function") {
            editorStyle = editorStyle(row[dataField], row, index);
          }

          if (editorType === "number") {
            child = (
              <EditableInputWrapper endEdit={endEdit}>
                <TableNumberInput
                  autoFocus={!alwaysShowEditor}
                  name={dataField}
                  style={{ textAlign: align ?? "right", ...editorStyle }}
                  value={parseFloat(row[dataField] ?? "0")}
                  size={value?.length + 1}
                  onChange={(v: any) => {
                    onChange && onChange(dataField, v, { ...row });
                  }}
                  onBlur={(_: any, v: any) => onBlur && onBlur(row, v, index)}
                  decimalPlaces={decimalPlaces}
                  isPercentage={isPercentage}
                />
              </EditableInputWrapper>
            );
          } else if (editorType === "textarea") {
            child = (
              <EditableInputWrapper endEdit={endEdit}>
                <TableTextarea
                  autoFocus={!alwaysShowEditor}
                  className={`table-textarea ${
                    borderless ? "table-input--borderless" : ""
                  }`}
                  name={dataField}
                  value={value}
                  rows={Math.ceil((value?.length ?? 1) / inputSize)}
                  type="textarea"
                  onBlur={(_: any, v: any) => onBlur && onBlur(row, v, index)}
                  onChange={({ target: { value } }) =>
                    onChange && onChange(dataField, value, { ...row })
                  }
                  forceUpperCase={editorForceUpperCase}
                />
              </EditableInputWrapper>
            );
          } else if (editorType === "combobox") {
            child = (
              <EditableInputWrapper endEdit={endEdit}>
                <TableCombobox
                  autoFocus={!alwaysShowEditor}
                  className={`table-input ${
                    borderless ? "table-input--borderless" : ""
                  }`}
                  name={dataField}
                  value={row[dataField]}
                  onChange={(v) => {
                    onChange && onChange(dataField, v, { ...row });
                  }}
                  options={options ?? []}
                  align={align}
                  style={editorStyle}
                  optionsRenderer={optionsRenderer}
                />
              </EditableInputWrapper>
            );
          } else if (editorType === "concat") {
            child = (
              <EditableInputWrapper endEdit={endEdit}>
                <TableAsyncCombobox
                  autoFocus={!alwaysShowEditor}
                  className={`table-input ${
                    borderless ? "table-input--borderless" : ""
                  }`}
                  name={dataField}
                  value={row[dataField]}
                  onChange={(v) => {
                    onChange && onChange(dataField, v, { ...row });
                  }}
                  options={options ?? []}
                  align={align}
                  concatModelName={concatModelName}
                  label={concatLabelField}
                />
              </EditableInputWrapper>
            );
          } else {
            child = (
              <EditableInputWrapper endEdit={endEdit}>
                <TableTextInput
                  autoFocus={!alwaysShowEditor}
                  className={`table-input ${
                    borderless ? "table-input--borderless" : ""
                  }`}
                  name={dataField}
                  value={value}
                  size={inputSize}
                  rows={Math.ceil((value?.length ?? 1) / inputSize)}
                  type="text"
                  onBlur={(e: any, v: any) => onBlur && onBlur(row, v, index)}
                  onChange={({ target: { value } }) => {
                    onChange && onChange(dataField, value, { ...row });
                  }}
                  maxLength={editorMaxLength}
                  forceUpperCase={editorForceUpperCase}
                  inputStyle={{ textAlign: align, ...editorStyle }}
                />
              </EditableInputWrapper>
            );
          }
        } else {
          if (CellContentTag) {
            child = (
              <CellContentTag
                onClick={() => {
                  if (editable) {
                    _startEdit(row[keyField], dataField);
                  }
                }}
                className="table-editable-cell"
              >
                {value}
              </CellContentTag>
            );
          } else {
            child = value;
          }
        }
        const innerStyle = {} as React.CSSProperties;
        if (colWidth) {
          innerStyle["maxWidth"] = colWidth;
          innerStyle["minWidth"] = colWidth;
          if (fixedColWidth) {
            innerStyle["width"] = colWidth;
          }
        } else {
          innerStyle["width"] = "auto";
          innerStyle["whiteSpace"] = "nowrap";
        }
        if (align) {
          innerStyle["textAlign"] = align;
        }

        if (typeof style === "function") {
          style = style(row[dataField], row, index);
        }

        return (
          <td
            style={{ ...innerStyle, ...style }}
            onClick={(e) => !selectOnClick && e.stopPropagation()}
          >
            {child}
          </td>
        );
      }
    );
  };

  const _buildSelectInput = (row: any) => {
    return (
      <td style={{ textAlign: "center", verticalAlign: "middle" }}>
        <input
          type={multiselect === true ? "checkbox" : "radio"}
          name={`table-sel-inpt-${row[keyField]}`}
          id={`table-sel-inpt-${row[keyField]}`}
          checked={isSelected}
          onChange={({ target: { checked } }) =>
            onSelect(row[keyField], checked, row)
          }
          style={{ display: "flex", margin: "auto" }}
        />
      </td>
    );
  };

  return (
    <tr
      key={row.id}
      id={`${tableId}--row-${index}`}
      tabIndex={index}
      onKeyDown={(e) => {
        if (["ArrowUp", "ArrowDown"].includes(e.key)) {
          e.preventDefault();
          handleTableFocus(tableId, index, e.key);
        } else if (e.key === "Enter" && onRowEnterPress) {
          e.preventDefault();
          onRowEnterPress();
        }
      }}
      className={classNames({
        "table-row-hover": hover === true,
        "table-row-striped": striped === true && index % 2 === 1,
        "table-row-clickable": rowSelect === true,
      })}
      onClick={() => handleRowClick(row[keyField], row)}
      onDoubleClick={() =>
        onRowDoubleClick && onRowDoubleClick(row[keyField], row, index)
      }
      style={rowStyle}
    >
      {selectable &&
        !hideSelectColumn &&
        selectColumnPosition !== "right" &&
        _buildSelectInput(row)}
      {buildRows(row)}
      {selectable &&
        !hideSelectColumn &&
        selectColumnPosition === "right" &&
        _buildSelectInput(row)}
    </tr>
  );
};

export default TableRow;
