import { FC } from "react";
import { TabBody, TabBodyProps } from "../../../../components/TabController";
import { ItemCheckbox } from "./ItemCheckbox";
import { useDispatch, useSelector } from "react-redux";
import { AtualizacoesSetorForm } from "./AtualizacoesSetorForm";
import ImplantacaoService from "../../../../services/ImplantacaoService";
import { setAtuSuporte, setupSuporte } from "../store/detalhesImplantSlice";
import { Row } from "reactstrap";
import { TextInput } from "../../../../components";

interface Props extends TabBodyProps {}

export const TabSuporte: FC<Props> = ({ title, active }) => {
  const store = useSelector((state: any) => state.detalhesImplant);
  const itens = store.suporte;
  const dispatch = useDispatch();

  const carregarDados = async () => {
    const [ok, ret] = await ImplantacaoService.buscarItens(
      store.selected,
      "SUP"
    );
    if (ok) {
      dispatch(setupSuporte(ret));
    }
  };

  const onChangeItem = async (item: any, valor: any) => {
    const payload = {
      id_implantacao: store.selected,
      item: item,
      valor: valor,
    };
    const [ok] = await ImplantacaoService.alterarItem(payload);
    if (ok) {
      carregarDados();
    }
  };

  return (
    <TabBody title={title} active={active} onActivate={() => carregarDados()}>
      <ItemCheckbox
        label="Comunicar o cliente sobre o início dos trabalhos"
        name="inicio_trab_comunicado"
        checked={itens.inicio_trab_comunicado}
        notifyEvent={carregarDados}
      />
      <ItemCheckbox
        label="Solicitar CFOP's que o cliente irá utilizar"
        name="cfops_cadastrada"
        checked={itens.cfops_cadastrada}
        notifyEvent={carregarDados}
      />
      <ItemCheckbox
        label="Realizar pré implantação no ambiente do cliente"
        name="pre_implantacao_feita"
        checked={itens.pre_implantacao_feita}
        notifyEvent={carregarDados}
      />
      <ItemCheckbox
        label="Verificar se o cliente utilizará o PDV"
        name="verif_usa_pdv"
        checked={itens.verif_usa_pdv}
        notifyEvent={carregarDados}
      />
      <Row className="mt-2">
        <TextInput
          md={3}
          label="Primeiro Treinamento"
          value={itens.dh_prim_treinamento}
          type="datetime-local"
          onBlur={(v) => onChangeItem("dh_prim_treinamento", v)}
        />
      </Row>
      <AtualizacoesSetorForm
        setor="SUP"
        abaAtiva={active ?? false}
        dados={itens.atualizacoes}
        setDados={(d) => dispatch(setAtuSuporte(d))}
      />
    </TabBody>
  );
};
