import { createBrowserHistory } from "history";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { Col, Row, Spinner } from "reactstrap";
import { defaultRoutes, pageRoutes } from ".";
import ScrollToTop from "../components/ScrollToTop";
import AuthLayout from "../layouts/Auth";
import MainLayout from "../layouts/MainLayout";
import { NotificationManager } from "../components/NotificationManager";

const newHistory = createBrowserHistory();

const renderChildRoutes = (routes) => {
  return routes.map((route, index) => {
    if (route.children) {
      return renderChildRoutes(route.children);
    } else {
      return (
        <Route
          key={index}
          path={route.path}
          exact
          component={route.component}
        />
      );
    }
  });
};

const ChildRoutes = ({ layout: Layout, routes }) => (
  <Layout>
    <Switch>{renderChildRoutes(routes)}</Switch>
  </Layout>
);

const Routes = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    if (Cookies.get("access_token")) {
      setIsLoading(false);
      setIsAuthenticated(true);
    } else {
      setIsLoading(false);
    }
  }, []);

  return (
    <Router history={newHistory}>
      <NotificationManager />
      {isLoading ? (
        <Row className="justify-content-center h-100">
          <Col sm={1} md={1} lg={1} className="align-self-center">
            <Spinner color="secondary" className="mr-2" type="grow" />
          </Col>
        </Row>
      ) : isAuthenticated ? (
        <ScrollToTop>
          <ChildRoutes layout={MainLayout} routes={defaultRoutes} />
        </ScrollToTop>
      ) : (
        <Switch>
          <Route
            path="/acesso/*"
            exact
            component={() => (
              <ChildRoutes layout={AuthLayout} routes={pageRoutes} />
            )}
          />
          <Redirect push to="/acesso/login" />
        </Switch>
      )}
    </Router>
  );
};

export default Routes;
