import "quill-mention";
import { FC } from "react";
import ReactQuill from "react-quill";
import ComboBoxService from "../../../../../services/components/ComboBoxService";
import hljs from "highlight.js";

hljs.configure({
  languages: ["javascript", "python", "delphi", "django", "typescript"],
});

interface Props {
  value?: any;
  onChange?: any;
  editorRef?: any;
  readOnly?: boolean;
}

export const quillDemandaDevModules = {
  syntax: {
    highlight: (text: any) => hljs.highlightAuto(text).value,
  },
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { list: "check" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video", "code-block"],
  ],
  clipboard: {
    matchVisual: false,
  },
  mention: {
    allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
    mentionDenotationChars: ["@"],
    source: async function (searchTerm: any, renderList: any) {
      const colabs = await ComboBoxService.fetchOptions("colaborador", {
        nome__istartswith: searchTerm,
      });
      renderList(colabs.map((e: any) => ({ id: e.value, value: e.nome })));
    },
  },
};

export const quillDemandaDevFormats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "align",
  "link",
  "image",
  "code",
  "mention",
  "code-block",
];

export const ConteudoDemandaEditor: FC<Props> = ({
  value,
  onChange,
  editorRef,
  readOnly,
}) => {
  return (
    <ReactQuill
      modules={readOnly ? { toolbar: false } : { ...quillDemandaDevModules }}
      readOnly={readOnly}
      formats={quillDemandaDevFormats}
      value={value}
      onChange={onChange}
      theme="snow"
      ref={editorRef}
    />
  );
};
