import { Users as UsersIcon } from "react-feather";
import { RouteType } from "..";
import ResetPassword from "../../pages/auth/ResetPassword";
import SignIn from "../../pages/auth/SignIn";

const authRoutes: RouteType = {
  id: "",
  path: "/acesso/",
  name: "Acesso",
  icon: UsersIcon,
  children: [
    {
      id: "",
      path: "/acesso/reset-password",
      name: "Resetar Senha",
      component: ResetPassword,
    },
    {
      id: "",
      path: "/acesso/login",
      name: "Login de Acesso",
      component: SignIn,
    },
  ],
};

export default authRoutes;
