import { FC, useState } from "react";
import { ModalBase } from "../../../../../components/ModalBase";
import { apiGetV2 } from "../../../../../apiV2";
import ReactQuill from "react-quill";
import { routesBasePop } from "../Pop";

interface Props {
  selected: any;
  isOpen: boolean;
  toggle: () => void;
}

export const VisualizarPopModal: FC<Props> = ({ selected, isOpen, toggle }) => {
  const [titulo, setTitulo] = useState("");
  const [markdown, setMarkdown] = useState("");
  const [loading, setLoading] = useState(false);

  const limparDados = () => {
    setTitulo("");
    setMarkdown("");
  };

  const carregarDados = async () => {
    setLoading(true);
    const [ok, ret] = await apiGetV2(`${routesBasePop}/buscar/${selected}/`);

    if (ok) {
      setTitulo(ret.titulo);
      setMarkdown(ret.markdown);
    } else {
      toggle();
    }
    setLoading(false);
  };

  return (
    <ModalBase
      size="xl"
      isOpen={isOpen}
      toggle={toggle}
      title={loading ? "Carregando..." : titulo}
      onBeforeOpen={carregarDados}
      onClosed={limparDados}
      autoFocus
      loading={loading}
    >
      <ReactQuill
        value={markdown}
        readOnly={true}
        modules={{ toolbar: false }}
      />
    </ModalBase>
  );
};
