import { useCallback, useState, FC, useRef } from "react";
import { useDropzone } from "react-dropzone";
import ImageViewer from "react-simple-image-viewer";
import { TableDelete } from "./TableDelete";
import { BsFolder2Open } from "react-icons/bs";

interface PropsDragDropLogo {
  onDrop: any;
  placeholder: string;
  acceptFormats?: string;
}

const DragDropLogo: FC<PropsDragDropLogo> = ({
  onDrop,
  placeholder = "Adicionar Imagem",
  acceptFormats = "*",
}) => {
  const dropzoneOptions = {
    multiple: false,
    maxFiles: 1,
  } as any;

  const _onDrop = useCallback(
    (acceptedFiles) => {
      if (onDrop) {
        acceptedFiles.forEach((file: any) => onDrop(file));
      }
    },
    [onDrop]
  );

  dropzoneOptions["onDrop"] = _onDrop;

  const { getRootProps, getInputProps } = useDropzone(dropzoneOptions);

  return (
    <div
      {...getRootProps({
        className: "dropzone",
        style: {
          cursor: "pointer",
          justifyContent: "center",
          height: "88%",
        },
      })}
    >
      <input
        {...getInputProps({
          multiple: false,
          accept: `image/${acceptFormats}`,
        })}
      />
      <p style={{ marginTop: "revert" }}>{placeholder}</p>
    </div>
  );
};

interface Props {
  value: any;
  width?: any;
  divClassName?: any;
  height?: any;
  placeholder?: any;
  onChange: (res: FileReader["result"], file: File | null) => void;
  noDelete?: boolean;
  acceptFormats?: string;
}

export const DragDropImagem: FC<Props> = ({
  value,
  width,
  divClassName,
  height = "auto",
  placeholder,
  onChange,
  noDelete,
  acceptFormats,
}) => {
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const addInputRef = useRef<any>();

  const onDrop = (file: File) => {
    var reader = new FileReader();

    reader.addEventListener("load", function (e: ProgressEvent<FileReader>) {
      onChange(e.target!.result, file);
    });

    reader.readAsDataURL(file);
  };

  return (
    <div className={divClassName} style={{ width: width, height: height }}>
      {["", null, undefined].includes(value) ? (
        <DragDropLogo
          placeholder={placeholder}
          onDrop={onDrop}
          acceptFormats={acceptFormats}
        />
      ) : (
        <>
          <div
            style={{
              height: "88%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "#eee",
              border: "1px solid #dee2e6",
            }}
            className="mb-2"
          >
            <img
              src={value}
              style={{ maxWidth: "100%", maxHeight: "100%", cursor: "zoom-in" }}
              alt=""
              onClick={() => setIsViewerOpen(true)}
            />
            {isViewerOpen && (
              <ImageViewer
                src={[value]}
                currentIndex={0}
                disableScroll={true}
                closeOnClickOutside={true}
                onClose={() => setIsViewerOpen(false)}
                backgroundStyle={{ backgroundColor: "#00000085" }}
              />
            )}
          </div>
          <div>
            {!noDelete ? (
              <TableDelete onClick={() => onChange(null, null)} />
            ) : (
              <>
                <input
                  type="file"
                  multiple={false}
                  accept="image/png"
                  ref={addInputRef}
                  style={{ display: "none" }}
                  onChange={(e) => {
                    onDrop(e.target.files![0]);
                  }}
                />
                <BsFolder2Open
                  color="blue"
                  size={18}
                  style={{ cursor: "pointer" }}
                  onClick={() => addInputRef.current.click()}
                />
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};
