import { FC, useEffect, useState } from "react";
import {
  AsyncComboBox,
  BotaoPesquisar,
  FormButton,
  PageContainer,
  SearchInput,
} from "../../../components";
import ComboBox, { ComboBoxOption } from "../../../components/ComboBox";
import { Card, Row } from "reactstrap";
import MaskedInput from "../../../components/MaskedInput";
import DesenvolvimentoService from "../../../services/DesenvolvimentoService";
import { DesenvolvimentoGrid } from "./components/DesenvolvimentoGrid";
import { useHistory } from "react-router-dom";
import { incluirAlterarDemandaDevRoute } from "../../../routes/modules/desenvolvimento";
import { debounce, defaultDebounceTime } from "../../../coreUtils";
import { ModalExcluirV2 } from "../../../components/cadastro";
import { Provider, useDispatch, useSelector } from "react-redux";
import store from "./store";
import {
  setIdCliente,
  setIdColabAtrib,
  setNroTela,
  setPista,
  setStatus,
  setTipo,
} from "./store/listagemDemandaDevSlice";

export const tipoDemandaOptions: ComboBoxOption[] = [
  { label: "Bug", value: "BUG" },
  { label: "Implementação", value: "IMP" },
  { label: "Migrar Processo para Web", value: "MPW" },
  { label: "Sugestão de Melhoria", value: "SUG" },
  { label: "Aspecto Visual", value: "ASV" },
  { label: "Desempenho Lento", value: "DES" },
  { label: "Dificuldade de Usabilidade", value: "DIF" },
  { label: "Desenvolvimento Cobrado", value: "DCB" },
  { label: "Não Sei Definir", value: "NDE" },
];

export const statusOptions: ComboBoxOption[] = [
  { label: "Apresentada", value: "APR" },
  { label: "Aberta", value: "ABE" },
  { label: "Em Andamento", value: "AND" },
  { label: "Enviar Versão", value: "ENV" },
  { label: "Suporte", value: "SUP" },
  { label: "Resolvida", value: "RES" },
  { label: "Backlog", value: "BKL" },
];

export const filtroStatusOptions: ComboBoxOption[] = [
  { label: "Em Pauta", value: "EMP" },
  { label: "Todas as Situações", value: "T" },
  ...statusOptions,
];

export const DesenvolvimentoContainer: FC = () => {
  const store = useSelector((store: any) => store.listagemDemandaDev);
  const dispatch = useDispatch();
  const [dados, setDados] = useState<any[]>([]);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingIniciar, setLoadingIniciar] = useState(true);
  const [loadingPista, setLoadingPista] = useState(false);
  const [loadingAlterarItem, setLoadingAlterarItem] = useState(false);
  const [excluirOpen, setExcluirOpen] = useState(false);

  const history = useHistory();

  const buscarDados = async (pista: any) => {
    const params = {
      pista: pista,
      id_cliente: store.idCliente,
      tipo: store.tipo,
      nro_tela: store.nroTela,
      id_colab_atrib: store.idColabAtrib,
      status: store.status !== "T" ? store.status : null,
    };
    const [ok, ret] = await DesenvolvimentoService.listar(params);
    setDados(ok ? ret : []);
  };

  const carregarDados = async () => {
    setLoading(true);
    await buscarDados(store.pista);
    setLoading(false);
  };

  const handlePista = debounce(async (p: any) => {
    dispatch(setPista(p));
    setLoadingPista(true);
    await buscarDados(p);
    setLoadingPista(false);
  }, defaultDebounceTime);

  const alterarDemandaGrade = (coluna: string, novoValor: any, row: any) => {
    if (loadingAlterarItem) return;
    if (novoValor === row[coluna]) return false;

    if (["titulo"].includes(coluna)) {
      row[coluna] = novoValor ?? "";
    } else if (["status", "tipo"].includes(coluna)) {
      row[coluna] = novoValor;
      atualizarDemandaGrade(row, coluna);
      return;
    }
    setDados(dados.map((e) => (e.id === row.id ? row : e)));
  };

  const atualizarDemandaGrade = async (row: any, coluna: string) => {
    const payload = {
      id_demanda: row.id,
      item: coluna,
      valor: row[coluna],
    };

    setLoadingAlterarItem(true);
    await DesenvolvimentoService.alterarItem(payload);
    await carregarDados();
    setLoadingAlterarItem(false);
  };

  const incluirDemanda = () => {
    history.push(incluirAlterarDemandaDevRoute.path);
  };

  const alterar = (id: any) => {
    history.push(incluirAlterarDemandaDevRoute.path + `?id=${id}`);
  };

  const toggleExcluir = () => setExcluirOpen(!excluirOpen);

  const excluir = (id: any) => {
    setSelected(id);
    setTimeout(() => {
      toggleExcluir();
    }, 1);
  };

  useEffect(() => {
    if (!loadingIniciar) {
      carregarDados();
    }
  }, [store.idCliente]);

  const inicarTela = async () => {
    await carregarDados();
    setLoadingIniciar(false);
  };

  useEffect(() => {
    inicarTela();
  }, []);

  return (
    <>
      <Card body>
        <Row>
          <AsyncComboBox
            md={3}
            label="Cliente"
            concatModelName="empresa"
            value={store.idCliente}
            onChange={(v) => dispatch(setIdCliente(v))}
            isClearable
          />
          <SearchInput
            md={4}
            value={store.pista}
            onChange={handlePista}
            loading={loadingPista}
            placeholder="Título"
          />
          <ComboBox
            md={2}
            label="Tipo"
            options={tipoDemandaOptions}
            value={store.tipo}
            onChange={(v: any) => dispatch(setTipo(v))}
            isClearable
          />
        </Row>
        <Row>
          <MaskedInput
            mask="9999"
            label="Tela"
            md={1}
            onChange={(v) => dispatch(setNroTela(v))}
            value={store.nroTela}
          />
          <AsyncComboBox
            md={2}
            label="Atribuído A"
            concatModelName="colaborador"
            defaultOptions
            value={store.idColabAtrib}
            onChange={(v) => dispatch(setIdColabAtrib(v))}
            isClearable
          />
          <ComboBox
            md={2}
            label="Status"
            options={filtroStatusOptions}
            value={store.status}
            onChange={(v: any) => dispatch(setStatus(v))}
            isClearable={false}
          />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <FormButton color="info" onClick={incluirDemanda}>
            Nova Demanda
          </FormButton>
          <ModalExcluirV2
            title="Demanda de Desenvolvimento"
            isOpen={excluirOpen}
            toggle={toggleExcluir}
            number="015_2"
            selected={selected}
            routeBase={DesenvolvimentoService.routesBase}
            notifyEvent={() => {
              carregarDados();
              setSelected(null);
            }}
          />
        </Row>
      </Card>
      <Card body>
        <DesenvolvimentoGrid
          dados={dados}
          alterarDemandaGrade={alterarDemandaGrade}
          atualizarDemandaGrade={atualizarDemandaGrade}
          loading={loadingAlterarItem || loading}
          alterar={alterar}
          excluir={excluir}
        />
      </Card>
    </>
  );
};

export const Desenvolvimento: FC = () => {
  return (
    <PageContainer title="Desenvolvimento" number="015">
      <Provider store={store}>
        <DesenvolvimentoContainer />
      </Provider>
    </PageContainer>
  );
};
