import { FC } from "react";
import { FormCheckbox } from "../../../../components";
import ImplantacaoService from "../../../../services/ImplantacaoService";
import { useSelector } from "react-redux";

interface Props {
  label: string;
  name: string;
  checked: boolean;
  disabled?: boolean;
  notifyEvent: () => void;
}

export const ItemCheckbox: FC<Props> = ({
  label = "",
  name = "",
  checked,
  disabled = false,
  notifyEvent,
}) => {
  const store = useSelector((state: any) => state.detalhesImplant);

  const onChangeItem = async (valor: any) => {
    const payload = {
      id_implantacao: store.selected,
      item: name,
      valor: valor,
    };
    const [ok] = await ImplantacaoService.alterarItem(payload);
    if (ok) {
      notifyEvent();
    }
  };

  return (
    <FormCheckbox
      divClassName="px-0 mt-2"
      padded={false}
      label={label}
      checked={checked}
      onChange={(v: any) => onChangeItem(v)}
      className="fb-2"
      disabled={disabled}
    />
  );
};
