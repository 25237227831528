import axios from "axios";
import Cookies from "js-cookie";
import { toastr } from "react-redux-toastr";
import settings from "./settings";
import { formatDateISO, isDevEnv } from "./coreUtils";
import qs from "qs";

export enum APIs {
  interno,
  auth,
  skill,
}

export interface APIOptions {
  successMessage?: boolean;
  successMessageType?: "toastr" | "modal";
  errorMessage?: boolean;
}

const kClientId = "xc3BYHQIbFFQL00nhwuSBL10ULggkYOK6vfivM1X";
const kClientSecret =
  "MUi14fA8i8pYiDhk3oymVgzL4MRGkWBCT5c0TFCzqaLy0xnsb5MvY5K8KlGCdfmIfC" +
  "EEmPjJc3Gmz8mqXAKF71v1AtzyeSCGD2R9Jco2JVMFe4hP1IrndHTuzrz5ZPBG";

let api = axios.create({
  baseURL: isDevEnv() ? settings.API_ADDRESS_DEV : settings.API_ADDRESS,
  headers: {
    Authorization: `Bearer ${Cookies.get("access_token")}`,
  },
});

const auth = axios.create({
  baseURL: isDevEnv() ? settings.AUTENTICADOR_DEV : settings.AUTENTICADOR,
  headers: {
    Authorization: `Bearer ${Cookies.get("access_token")}`,
  },
});

const apiSkill = axios.create({
  baseURL: isDevEnv()
    ? settings.API_SKILL_ADDRESS_DEV
    : settings.API_SKILL_ADDRESS,
  headers: {
    Authorization: `Bearer ${Cookies.get("access_token")}`,
    "database-name": "default",
  },
});

export const updateAPIV2 = (
  apiAddress: string,
  dbName: string,
  accessToken: string
) => {
  api = axios.create({
    baseURL: apiAddress,
    headers: {
      "database-name": dbName,
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

// Atualiza token caso necessário
const refreshTokenV2 = async (error: any) => {
  try {
    const data = new URLSearchParams({
      grant_type: "refresh_token",
      refresh_token: Cookies.get("refresh-token"),
    } as Record<string, string>);
    await axios
      .post(`${settings.AUTENTICADOR}/o/token/`, data, {
        timeout: 5000,
        auth: { username: kClientId, password: kClientSecret },
      })
      .then(async (res) => {
        Cookies.set("access-token", res.data.access_token);
        Cookies.set("refresh-token", res.data.refresh_token);
        return res;
      })
      .catch((_) => {
        return error;
      });
  } catch (err) {
    return err;
  }
};

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const access_token = Cookies.get("access-token");
    if ([403, 401].includes(error.response?.status) && access_token) {
      const response = await refreshTokenV2(error);
      return response;
    }
    return Promise.reject(error);
  }
);

export { api as apiV2, kClientId, kClientSecret };

const tratarParams = (params: object) =>
  Object.entries(params)
    .filter(([key, value]) => !["", null, undefined].includes(value))
    .reduce((acc, [key, value]) => {
      if (value instanceof Date) {
        value = formatDateISO(value);
      }
      return { ...acc, [key]: value };
    }, {});

export const revokeTokenV2 = async () => {
  try {
    const data = new URLSearchParams({
      token: Cookies.get("access-token"),
      client_id: kClientId,
      client_secret: kClientSecret,
    } as Record<string, string>);
    await axios
      .post(`${settings.AUTENTICADOR}/o/revoke-token/`, data, {
        timeout: 5000,
      })
      .then(async (res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  } catch (err) {
    return err;
  }
};

const processaRetorno = (
  { data }: any,
  {
    successMessage = true,
    successMessageType = "toastr",
    errorMessage = true,
  }: APIOptions = {
    successMessage: true,
    successMessageType: "toastr",
    errorMessage: true,
  }
) => {
  const success = data.success || (data.hasOwnProperty("err") && !data.err);

  if (success) {
    if (successMessage) {
      toastr.success("Sucesso", data.msg);
    }
  } else {
    if (errorMessage) {
      toastr.error("Erro", data.msg);
    }
  }
  return [success, success ? data.res : data];
};

const processaErro = (err: any) => {
  toastr.error("Erro", err.message);
  return [
    false,
    { msg: err.message, id_err: `SERVER_ERROR_${err.response?.status}` },
  ];
};

const apiGetV2 = async (url: string, params?: object, options?: APIOptions) =>
  await api
    .get(url, {
      params: params ? tratarParams(params) : undefined,
      paramsSerializer: (par) => qs.stringify(par, { arrayFormat: "repeat" }),
    })
    .then((ret) => processaRetorno(ret, { successMessage: false, ...options }))
    .catch((err) => processaErro(err));

const apiPostV2 = async (url: string, payload?: any, options?: APIOptions) =>
  await api
    .post(url, payload)
    .then((ret) => processaRetorno(ret, options))
    .catch((err) => processaErro(err));

const apiPutV2 = async (url: string, payload?: any, options?: APIOptions) =>
  await api
    .put(url, payload)
    .then((ret) => processaRetorno(ret, options))
    .catch((err) => processaErro(err));

const apiDeleteV2 = async (url: string) =>
  await api
    .delete(url)
    .then((ret) => processaRetorno(ret))
    .catch((err) => processaErro(err));

export { apiGetV2, apiPostV2, apiPutV2, apiDeleteV2 };

export const authGetV2 = async (
  url: string,
  params?: object,
  options?: APIOptions
) =>
  await auth
    .get(url, {
      params: params ? tratarParams(params) : undefined,
      paramsSerializer: (par) => qs.stringify(par, { arrayFormat: "repeat" }),
    })
    .then((ret) => processaRetorno(ret, { successMessage: false, ...options }))
    .catch((err) => processaErro(err));

export const authPostV2 = async (
  url: string,
  payload?: any,
  options?: APIOptions
) =>
  await auth
    .post(url, payload)
    .then((ret) => processaRetorno(ret, options))
    .catch((err) => processaErro(err));

export const authPutV2 = async (
  url: string,
  payload?: any,
  options?: APIOptions
) =>
  await auth
    .put(url, payload)
    .then((ret) => processaRetorno(ret, options))
    .catch((err) => processaErro(err));

export const authDeleteV2 = async (url: string) =>
  await auth
    .delete(url)
    .then((ret) => processaRetorno(ret))
    .catch((err) => processaErro(err));

export const apiSkillGetV2 = async (
  url: string,
  params?: object,
  options?: APIOptions
) =>
  await apiSkill
    .get(url, {
      params: params ? tratarParams(params) : undefined,
      paramsSerializer: (par) => qs.stringify(par, { arrayFormat: "repeat" }),
    })
    .then((ret) => processaRetorno(ret, options))
    .catch((err) => processaErro(err));
