import { FC } from "react";
import { TableCheck } from "../../../../../components/TableCheck";
import { Table } from "../../../../../components/Table";
import { TableColumn } from "../../../../../components/table/TableRow";
import { naturalSort } from "../../../../../coreUtils";
import { IconButton } from "../../../../../components/IconButton";
import { BsFileText } from "react-icons/bs";
import {
  dropdownAcoes,
  iconeAlterar,
} from "../../../../../components/cadastro";

interface Props {
  data: any;
  setSelected: any;
  visualizar: (id: any) => void;
  excluir: (id: any) => void;
  alterar: (id: any) => void;
}
export const PopGrid: FC<Props> = ({
  data,
  setSelected,
  visualizar,
  excluir,
  alterar,
}) => {
  const columns: TableColumn[] = [
    {
      dataField: "id",
      text: "#",
      align: "center",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "titulo",
      text: "Título",
      align: "left",
      sortable: true,
    },
    {
      dataField: "ativo",
      text: "Ativo",
      align: "center",
      formatter: (c: any) => <TableCheck value={c} />,
      sortable: true,
    },
    {
      dataField: "det",
      text: "",
      align: "center",
      colWidth: "8px",
      formatter: (c: any, row: any) => (
        <>
          <IconButton
            icon={BsFileText}
            onClick={() => visualizar(row.id)}
            hint="Visualizar"
            tooltipPlacement="left"
          />
        </>
      ),
    },
    iconeAlterar(alterar),
    dropdownAcoes({ excluir: excluir }),
  ];
  return (
    <Table
      data={data}
      columns={columns}
      paginated={false}
      onSelect={setSelected}
      onRowDoubleClick={alterar}
    />
  );
};
