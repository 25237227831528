import { FC, useState } from "react";
import { ModalBase } from "../../../../components/ModalBase";
import ImplantacaoService from "../../../../services/ImplantacaoService";
import { IconButton } from "../../../../components/IconButton";
import { Edit } from "react-feather";
import ComboBox from "../../../../components/ComboBox";
import { Row } from "reactstrap";
import { AsyncComboBox } from "../../../../components";
import { toastr } from "react-redux-toastr";
import { modoImplantaOptions } from "../../components/IniciarImplantacaoModal";

interface Props {
  selected: any;
  notifyEvent: () => void;
}

export const AlterarDadosGeraisModal: FC<Props> = ({
  selected,
  notifyEvent,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modoImplanta, setModoImplanta] = useState("");
  const [idSistemaAtual, setIdSistemaAtual] = useState(null);
  const [loading, setLoading] = useState(false);

  const limparDados = () => {
    setModoImplanta("");
    setIdSistemaAtual(null);
  };

  const toggle = () => setIsOpen(!isOpen);

  const carregarDados = async () => {
    const [ok, ret] = await ImplantacaoService.buscarDadosGerais(selected);
    if (ok) {
      setModoImplanta(ret.modo_implanta);
      setIdSistemaAtual(ret.id_sistema_atual);
    }
  };

  const handleSubmit = async () => {
    if (["", null, undefined].includes(modoImplanta)) {
      toastr.warning("Atenção", "Por favor, informe o Modo da Implantação");
      return false;
    }

    if ([0, null, undefined].includes(idSistemaAtual)) {
      toastr.warning(
        "Atenção",
        "Por favor, informe o Sistema Atual da Empresa"
      );
      return false;
    }

    const payload = {
      id_implantacao: selected,
      modo_implanta: modoImplanta,
      id_sistema_atual: idSistemaAtual,
    };
    setLoading(true);
    const [ok] = await ImplantacaoService.alterarDadosGerais(payload);
    if (ok) {
      toggle();
    }
    setLoading(false);
  };

  return (
    <>
      <IconButton icon={Edit} onClick={toggle} className="mt-auto mb-1" />
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="md"
        title="Alterar Implantação"
        number="014_5"
        onBeforeClose={notifyEvent}
        onClosed={limparDados}
        onBeforeOpen={carregarDados}
        onConfirm={handleSubmit}
        loadingConfirm={loading}
      >
        <Row>
          <ComboBox
            md={4}
            label="Modo"
            value={modoImplanta}
            options={modoImplantaOptions}
            onChange={setModoImplanta}
          />
          <AsyncComboBox
            md={6}
            label="Sistema Atual"
            concatModelName="sistema_terceiro"
            value={idSistemaAtual}
            onChange={setIdSistemaAtual}
          />
        </Row>
      </ModalBase>
    </>
  );
};
