import { FC, useState } from "react";
import { ModalBase } from "../../../../../components/ModalBase";
import { Row } from "reactstrap";
import { TextInput } from "../../../../../components";
import LeadService from "../../../../../services/components/comercial/LeadService";
import lottieSucesso from "../../../../../assets/lottie/success.json";
import Lottie from "lottie-react";

interface Props {
  isOpen: boolean;
  toggle: () => void;
  selected: any;
  notifyEvent: () => void;
}

export const ConquistaLeadModal: FC<Props> = ({
  isOpen,
  toggle,
  selected,
  notifyEvent,
}) => {
  const [observ, setObserv] = useState("");
  const [loading, setLoading] = useState(false);
  const [sucessoOpen, setSucessoOpen] = useState(false);

  const limparDados = () => {
    setObserv("");
  };

  const toggleSucesso = () => setSucessoOpen(!sucessoOpen);

  const handleSubmit = async () => {
    const payload = {
      id: selected,
      observ: observ,
    };

    setLoading(true);
    const [ok] = await LeadService.interacao.registrarConquista(payload);
    if (ok) {
      toggle();
      toggleSucesso();
      notifyEvent();
    }
    setLoading(false);
  };

  return (
    <>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        title="Conquista de Lead"
        onConfirm={handleSubmit}
        loadingConfirm={loading}
        onClosed={limparDados}
        number="011_4"
      >
        <Row>
          <TextInput
            md={12}
            type="textarea"
            label="Observação"
            value={observ}
            onChange={setObserv}
            rows={3}
            upperCaseValue={false}
          />
        </Row>
      </ModalBase>
      <SucessoConquistaModal isOpen={sucessoOpen} toggle={toggleSucesso} />
    </>
  );
};

interface SucessoConquistaModalProps {
  isOpen: boolean;
  toggle: () => void;
}

const SucessoConquistaModal: FC<SucessoConquistaModalProps> = ({
  isOpen,
  toggle,
}) => {
  return (
    <ModalBase
      title="Conquista de Lead"
      isOpen={isOpen}
      toggle={toggle}
      footerActions
      number="011_41"
    >
      <Lottie
        animationData={lottieSucesso}
        loop={true}
        style={{ marginTop: "-3rem" }}
      />
      <h3 style={{ textAlign: "center" }}>
        Parabéns pela Conquista do Cliente!
      </h3>
    </ModalBase>
  );
};
