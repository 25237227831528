import React, {
  FC,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { handleFocus } from "../../coreUtils";
import { ComboBoxOption } from "../ComboBox";
import { PiCaretDownBold } from "react-icons/pi";
import classNames from "classnames";

export interface TableComboboxProps {
  id?: any;
  name?: React.SelectHTMLAttributes<HTMLSelectElement>["name"];
  autoFocus?: React.SelectHTMLAttributes<HTMLSelectElement>["autoFocus"];
  tabOrder?: React.SelectHTMLAttributes<HTMLSelectElement>["tabIndex"];
  className?: React.SelectHTMLAttributes<HTMLSelectElement>["className"];
  options?: ComboBoxOption[];
  onBlur?: (v: any, e: any) => void;
  value?: any;
  onChange?: (v: ComboBoxOption["value"], s: ComboBoxOption, e: any) => void;
  ref?: any;
  align?: React.CSSProperties["textAlign"];
  style?: React.CSSProperties;
  optionsRenderer?: (option: ComboBoxOption) => any;
}

export const TableCombobox: FC<TableComboboxProps> = forwardRef(
  (
    {
      id,
      name,
      autoFocus,
      tabOrder,
      className,
      options = [],
      value,
      onChange,
      align,
      style,
      optionsRenderer,
    },
    ref
  ) => {
    const [mouseOver, setMouseOver] = useState(false);
    const [internalId] = useState(
      id ?? "table-combobox-" + Math.floor(Math.random() * Date.now())
    );
    const [listOpen, setListOpen] = useState(false);
    const inputRef = useRef<any>();

    useImperativeHandle(ref, () => ({
      focus: () =>
        setTimeout(() => inputRef.current && inputRef.current.focus(), 35),
    }));

    const optionsRenderers = options.map((opt) => {
      let renderer = <span className="value-span">{opt.label}</span>;

      if (optionsRenderer) {
        renderer = optionsRenderer(opt);
      }
      return { ...opt, renderer: renderer };
    });

    return (
      <div
        id={internalId}
        className={classNames("table-combobox", className)}
        style={{ textAlign: align, ...style }}
        onClick={() => {
          inputRef.current.focus();
        }}
        onMouseEnter={() => setMouseOver(true)}
        onMouseLeave={() => setMouseOver(false)}
      >
        <div className="value-container">
          <input
            name={name}
            autoFocus={autoFocus}
            tabIndex={tabOrder}
            className="ghost-input"
            readOnly={true}
            ref={inputRef}
            onFocus={() => setListOpen(true)}
            onBlur={() => {
              if (!mouseOver) {
                setListOpen(false);
              }
            }}
            onKeyDown={(e) => {
              if (["Enter", "Tab", "ArrowUp"].includes(e.key)) {
                setListOpen(false);
              }
              handleFocus(e);
            }}
          />
          <span className="value-span">
            {optionsRenderers.find((e) => e.value === value)?.renderer}
          </span>
          <PiCaretDownBold className="caret" />
        </div>
        {listOpen && (
          <div className="options-list">
            {optionsRenderers
              .filter((opt) => opt.value !== value)
              .map((opt) => (
                <div
                  className="option"
                  onClick={(e) => {
                    setListOpen(false);
                    if (onChange) {
                      onChange(opt.value, opt, e);
                    }
                  }}
                >
                  {opt.renderer}
                </div>
              ))}
          </div>
        )}
      </div>
    );
  }
);
