import { FC, useEffect, useRef, useState } from "react";
import { Card, Label, Row } from "reactstrap";
import {
  FormButton,
  PageContainer,
  TextInput,
} from "../../../../../components";
import LeadService from "../../../../../services/components/comercial/LeadService";
import { toastr } from "react-redux-toastr";
import { DatePicker } from "../../../../../components/DatePicker";
import {
  formatDateISO,
  formatValueFromAPI,
  limparNumero,
} from "../../../../../coreUtils";
import { FixedField } from "../../../../../components/FixedField";
import { AtualizacoesEditor } from "../../../../../components/AtualizacoesEditor";
import { extrairIdColabMencionados } from "../../../../../components/AtualizacoesForm";
import { Divider } from "../../../../../components/Divider";
import { InteracoesLeadGrid } from "../components/InteracoesLeadGrid";
import { useQueryParams } from "../../../../../utils/hooks";
import { CadastroLeadModal } from "../components/CadastroLeadModal";
import { ModalActions } from "../../../../../components/cadastro/ModalCadastroV2";

interface Props {
  location: any;
}

export const interacaoLeadEditorFormats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "align",
  "link",
  "mention",
];

export const InteracoesLead: FC<Props> = ({ location }) => {
  const query = useQueryParams();
  const queryIdLead = query.get("id_lead")
    ? parseInt(query.get("id_lead")!)
    : null;
  const queryIdInteracao = query.get("id_interacao")
    ? parseInt(query.get("id_interacao")!)
    : null;
  const selected = location?.state?.selected ?? queryIdLead;
  const [nomeEmpresa, setNomeEmpresa] = useState("");
  const [observ, setObserv] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingStart, setLoadingStart] = useState(true);
  const [dataProxContato, setDataProxContato] = useState<any>(null);
  const [horaProxContato, setHoraProxContato] = useState("");
  const [grauConversao, setGrauConversao] = useState(null);
  const [telefone, setTelefone] = useState("");
  const [nomeContato, setNomeContato] = useState("");
  const [interacoes, setInteracoes] = useState([]);
  const [cadastroOpen, setCadastroOpen] = useState(false);

  const editorRef = useRef<any>();

  const handleSubmit = async () => {
    if (["", null, undefined].includes(grauConversao)) {
      toastr.warning("Atenção", "Por favor, informe o Grau de Conversão.");
      return false;
    }

    const payload = {
      id_lead: selected,
      observ: observ,
      data_prox_contato:
        dataProxContato instanceof Date ? formatDateISO(dataProxContato) : null,
      hora_prox_contato:
        limparNumero(horaProxContato).length > 0 ? horaProxContato : null,
      id_colab_mencionados: extrairIdColabMencionados(editorRef),
    };
    setLoading(true);
    const [ok] = await LeadService.interacao.incluir(payload);
    if (ok) {
      carregarDadosLead();
      carregarInteracoes();
      setObserv("");
    }
    setLoading(false);
  };

  const carregarInteracoes = async () => {
    const [ok, ret] = await LeadService.interacao.listar(selected);
    setInteracoes(ok ? ret : []);
  };

  const carregarDadosLead = async () => {
    const [ok, ret] = await LeadService.buscar(selected);
    if (ok) {
      setNomeEmpresa(formatValueFromAPI(ret.nome_empresa, selected));
      setNomeContato(ret.nome_contato);
      setTelefone(ret.telefone);
      setGrauConversao(ret.desc_grau_conversao);
    }
  };

  const toggleCadastro = () => setCadastroOpen(!cadastroOpen);

  const onKeyDown = (e: any) => {
    if (e.key === "F9") {
      handleSubmit();
    }
  };

  const iniciarTela = async () => {
    await carregarDadosLead();
    await carregarInteracoes();
    setLoadingStart(false);

    setTimeout(() => {
      if (queryIdInteracao) {
        const elementoInteracao = document.getElementById(
          `id-inter-lead-${queryIdInteracao}`
        );
        if (elementoInteracao) {
          elementoInteracao.scrollIntoView({ block: "center" });
          elementoInteracao.click();
        }
      }
    }, 10);
  };

  useEffect(() => {
    iniciarTela();
  }, [selected]);

  return (
    <PageContainer
      title="Incluir Interação de Lead"
      number="011_3"
      onKeyDown={onKeyDown}
      canGoBack
      loading={loadingStart}
    >
      <Card body>
        <Row className="mb-2">
          <FixedField
            label="Empresa"
            value={nomeEmpresa}
            horizontal
            divClassName="pt-2"
          />
          <FixedField
            label="Contato"
            value={nomeContato}
            horizontal
            divClassName="pt-2"
          />
          <FixedField
            label="Telefone"
            value={telefone}
            horizontal
            divClassName="pt-2"
          />
          <FixedField
            label="Grau de Conversão"
            value={grauConversao}
            horizontal
            divClassName="pt-2"
          />
          <CadastroLeadModal
            isOpen={cadastroOpen}
            toggle={toggleCadastro}
            action={ModalActions.edit}
            selected={selected}
            notifyEvent={carregarDadosLead}
          />
          <FormButton padded={false} color="warning" onClick={toggleCadastro}>
            Alterar
          </FormButton>
        </Row>
        <InteracoesLeadGrid data={interacoes} pageSize={12} />
        <Divider>Interação</Divider>
        <Row>
          <DatePicker
            md={2}
            label="Próxima Interação"
            value={dataProxContato}
            onChange={setDataProxContato}
          />
          <TextInput
            md={1}
            label="Hora"
            value={horaProxContato}
            onChange={setHoraProxContato}
            type="time"
          />
        </Row>
        <Label>Observação</Label>
        <AtualizacoesEditor
          value={observ}
          onChange={setObserv}
          formats={interacaoLeadEditorFormats}
          editorRef={editorRef}
        />
        <Row>
          <FormButton
            divClassName="ml-auto"
            color="success"
            onClick={handleSubmit}
            loading={loading}
          >
            F9 - Confirmar
          </FormButton>
        </Row>
      </Card>
    </PageContainer>
  );
};
