import { FC, useState } from "react";
import {
  Button,
  ButtonProps,
  Col,
  ColProps,
  FormGroup,
  Label,
  Spinner,
  UncontrolledTooltip,
} from "reactstrap";
import { handleFocus } from "../coreUtils";

export interface FormButtonProps {
  md?: ColProps["md"];
  onClick?: ButtonProps["onClick"];
  color?: ButtonProps["color"];
  disabled?: ButtonProps["disabled"];
  padded?: boolean;
  className?: string;
  divClassName?: string;
  divStyle?: ColProps["style"];
  style?: ButtonProps["style"];
  loading?: boolean;
  disabledHint?: any;
  id?: string;
  tabIndex?: ButtonProps["tabIndex"];
  children?: ButtonProps["children"];
}

const FormButton: FC<FormButtonProps> = ({
  md = "auto",
  onClick,
  color = "info",
  disabled = false,
  padded = true,
  className = "",
  divClassName = "",
  divStyle,
  style = {},
  loading = false,
  disabledHint = "Selecione um item da lista",
  id,
  tabIndex,
  children,
}) => {
  const [internalId] = useState(
    id ?? "bt-" + Math.floor(Math.random() * Date.now())
  );

  return (
    <Col md={md} className={divClassName} style={divStyle}>
      <FormGroup>
        {padded && <Label>&#8205;</Label>}
        <Button
          className={`align-self-center ${className}`}
          block
          color={color}
          onClick={!loading ? onClick : () => {}}
          disabled={disabled}
          style={style}
          id={internalId}
          onKeyDown={(e) => {
            if (e.key !== "Enter") {
              handleFocus(e);
            }
          }}
          tabIndex={tabIndex}
        >
          {loading ? <Spinner size="sm" color="light" /> : children}
        </Button>
        {disabled && !loading && (
          <UncontrolledTooltip target={internalId}>
            {disabledHint}
          </UncontrolledTooltip>
        )}
      </FormGroup>
    </Col>
  );
};

export default FormButton;
