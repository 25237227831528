import { FC, KeyboardEvent, useEffect } from "react";
import { Container } from "reactstrap";
import NumberPage from "./NumberPage";
import { useHistory } from "react-router-dom";
import {
  SET_GO_BACK_ROUTE,
  SET_PAGE_TITLE,
  SET_PAGE_TOP_OPTIONS,
} from "../redux/constants";
import { useDispatch } from "react-redux";
import Loader from "./Loader";
import { RouteType } from "../routes";

interface Props {
  title?: any;
  number?: any;
  canGoBack?: boolean;
  goBackRoute?: RouteType;
  children?: any;
  topOptions?: any;
  onKeyDown?: (e: KeyboardEvent) => void;
  loading?: boolean;
  className?: string;
}

export const PageContainer: FC<Props> = ({
  title = "Sem Título",
  number = "Sem Número",
  canGoBack = false,
  goBackRoute,
  children,
  topOptions,
  onKeyDown,
  loading,
  className = "p-0 pb-5",
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (onKeyDown) {
      const func = (e: any) =>
        !loading &&
        !document.body.className.includes("modal-open") &&
        onKeyDown(e);

      window.addEventListener("keydown", func);

      return () => {
        window.removeEventListener("keydown", func);
      };
    }
  }, [onKeyDown, loading]);

  useEffect(() => {
    if (canGoBack) {
      const exitOnEsc = (e: any) => {
        if (
          !document.body.className.includes("modal-open") &&
          e.key === "Escape"
        ) {
          if (goBackRoute) {
            history.replace(goBackRoute.path);
          } else {
            history.goBack();
          }
        }
      };

      window.addEventListener("keydown", exitOnEsc);

      return () => {
        window.removeEventListener("keydown", exitOnEsc);
      };
    }
  }, [canGoBack, history]);

  useEffect(() => {
    dispatch({ type: SET_PAGE_TITLE, data: title });
  }, [title]);

  useEffect(() => {
    dispatch({ type: SET_PAGE_TOP_OPTIONS, data: topOptions });
  }, [topOptions]);

  useEffect(() => {
    dispatch({ type: SET_GO_BACK_ROUTE, data: goBackRoute });
  }, [goBackRoute]);

  return loading ? (
    <Loader />
  ) : (
    <Container fluid className={className} style={{ paddingRight: "2px" }}>
      <form className="pb-2" onSubmit={(e) => e.preventDefault()}>
        {children}
      </form>
      <NumberPage>{number}</NumberPage>
    </Container>
  );
};
