import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  selected: null,
  id_empresa: null,
  nome_empresa: "",
  desc_modo_implanta: "",
  nome_sistema_atual: "",
  id_atualiza_mostrar: null,
  financ: {
    reg_venda_implantacao: false,
    cta_receber_lancado: false,
    dados_cadastrais_ok: false,
    certificado_ok: false,
    formalizou_serv: false,
    logo_configurado: false,
    gera_nf_implantacao: false,
    contrato_gerado: false,
    cobranca_proporcional_ok: false,
    planilha_reajuste: false,
    planilha_comissao: false,
    atualizacoes: [],
  },
  suporte: {
    inicio_trab_comunicado: false,
    cfops_cadastrada: false,
    pre_implantacao_feita: false,
    verif_usa_pdv: false,
    dh_prim_treinamento: null,
    atualizacoes: [],
  },
  dev: {
    tem_mig_cad_produto: false,
    mig_cad_produto: false,
    tem_mig_cad_cliente: false,
    mig_cad_cliente: false,
    tem_mig_contas_pagar: false,
    mig_contas_pagar: false,
    tem_mig_contas_receber: false,
    mig_contas_receber: false,
    tem_mig_os: false,
    mig_os: false,
    tem_mig_vendas: false,
    mig_vendas: false,
    atualizacoes: [],
  },
};

export const detalhesImplantSlice = createSlice({
  name: "detalhes_implant",
  initialState: initialState,
  reducers: {
    init: () => initialState,
    setup: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        state[key] = value;
      });
      return state;
    },
    setSelected: (state, action) => {
      state.selected = action.payload;
    },
    setupFinanc: (state, action) => {
      state.financ = { ...state.financ, ...action.payload };
    },
    setupSuporte: (state, action) => {
      state.suporte = { ...state.suporte, ...action.payload };
    },
    setupDev: (state, action) => {
      state.dev = { ...state.dev, ...action.payload };
    },
    setAtuFinanc: (state, action) => {
      state.financ.atualizacoes = action.payload;
    },
    setAtuSuporte: (state, action) => {
      state.suporte.atualizacoes = action.payload;
    },
    setAtuDev: (state, action) => {
      state.dev.atualizacoes = action.payload;
    },
    setIdAtualizaMostrar: (state, action) => {
      state.id_atualiza_mostrar = action.payload;
    },
    setIdEmpresa: (state, action) => {
      state.id_empresa = action.payload;
    },
    setNomeEmpresa: (state, action) => {
      state.nome_empresa = action.payload;
    },
    setDescModoImplanta: (state, action) => {
      state.desc_modo_implanta = action.payload;
    },
    setNomeSistemaAtual: (state, action) => {
      state.nome_sistema_atual = action.payload;
    },
  },
});

export const {
  init,
  setup,
  setSelected,
  setupFinanc,
  setupSuporte,
  setupDev,
  setAtuFinanc,
  setAtuSuporte,
  setAtuDev,
  setIdAtualizaMostrar,
  setIdEmpresa,
  setNomeEmpresa,
  setDescModoImplanta,
  setNomeSistemaAtual,
} = detalhesImplantSlice.actions;

export default detalhesImplantSlice.reducer;
