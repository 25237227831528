import { FC, useState } from "react";
import { ModalBase, ModalProps } from "../../../components/ModalBase";
import { DatePicker } from "../../../components/DatePicker";
import ImplantacaoService from "../../../services/ImplantacaoService";
import { formatDateISO } from "../../../coreUtils";

interface Props {
  isOpen: ModalProps["isOpen"];
  toggle: ModalProps["toggle"];
  selected: any;
  notifyEvent: () => void;
}

export const OkUsoModal: FC<Props> = ({
  isOpen,
  toggle,
  selected,
  notifyEvent,
}) => {
  const [dtOkUso, setDtOkUso] = useState<Date | string>(new Date());
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    const payload = {
      id_implantacao: selected,
      item: "dt_ok_uso",
      valor: formatDateISO(dtOkUso),
    };
    setLoading(true);
    const [ok] = await ImplantacaoService.alterarItem(payload);
    if (ok) {
      notifyEvent();
      toggle!();
    }
    setLoading(false);
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      title="Dar OK de Uso"
      number="014_3"
      size="sm"
      onConfirm={handleSubmit}
      loadingConfirm={loading}
      onClosed={() => setDtOkUso(new Date())}
    >
      <DatePicker
        md={6}
        label="Data de OK de Uso"
        value={dtOkUso}
        onChange={setDtOkUso}
        divClassName="mx-auto"
      />
    </ModalBase>
  );
};
