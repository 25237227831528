import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  FC,
  useState,
} from "react";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import Select from "react-select";
import { Col, FormGroup, Label, UncontrolledTooltip } from "reactstrap";

export interface ComboBoxOption {
  label: string;
  value: any;
}

interface Props {
  md?: number;
  label?: string;
  name?: string;
  onChange?: any;
  options?: ComboBoxOption[];
  isSearchable?: boolean;
  isClearable?: boolean;
  isMulti?: boolean;
  value?: any;
  isDisabled?: any;
  hint?: any;
  divClassName?: string;
}

const ComboBox: FC<Props> = forwardRef(
  (
    {
      md = 4,
      label,
      name,
      onChange,
      options = [],
      isSearchable,
      isClearable,
      isMulti,
      value,
      isDisabled,
      hint,
      divClassName,
    },
    ref
  ) => {
    const [selectedValue, setSelectedValue] = useState(null);
    const selectRef = useRef<any>();
    const clearValue = () => {
      selectRef.current.select.clearValue();
    };

    const setFocus = () => {
      selectRef.current.select.focus();
    };

    const setValue = (value: any) => {
      selectRef.current.select.setValue(value);
    };

    useImperativeHandle(ref, () => ({
      clearValue: () => clearValue(),
      setFocus: () => setFocus(),
      setValue: (v: any) => setValue(v),
    }));

    useEffect(() => {
      if (options.length > 0) {
        if (value) {
          const opt = options.find((v) => v.value === value);
          if (opt) {
            setValue(opt);
          } else {
            clearValue();
          }
        } else {
          clearValue();
        }
      }
    }, [value, options]);

    const handleChange = (v: any, e: any) => {
      if ((v?.value ?? null) !== selectedValue) {
        setSelectedValue(v?.value ?? null);
        if (onChange) onChange(v ? v.value : null, e);
      }
    };

    return (
      <Col md={md} className={divClassName}>
        <FormGroup>
          <Label>
            {label}
            {hint && (
              <>
                <BsFillQuestionCircleFill
                  size={12}
                  className="ml-1 mb-1"
                  id="infoTooltip"
                  color="#47bac1"
                />
                <UncontrolledTooltip placement="right" target="infoTooltip">
                  {hint}
                </UncontrolledTooltip>
              </>
            )}
          </Label>
          <Select
            className="react-select-container"
            classNamePrefix="react-select"
            name={name}
            onChange={handleChange}
            options={options}
            isSearchable={isSearchable}
            isClearable={isClearable}
            isMulti={isMulti}
            isDisabled={isDisabled}
            ref={selectRef}
            placeholder="Selecione..."
          />
        </FormGroup>
      </Col>
    );
  }
);

export default ComboBox;
