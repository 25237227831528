import { RouteType } from "..";

import { Suporte } from "../../pages/processos/suporte/Suporte";

export const suporteRoute: RouteType = {
  id: "AcSk004",
  name: "Suporte",
  path: "/processos/suporte",
  component: Suporte,
};

export const processosRoutes: RouteType = {
  id: "",
  name: "Processos",
  path: "/processos/",
  children: [suporteRoute],
};
