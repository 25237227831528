import { View } from "@react-pdf/renderer";
import { FC } from "react";
import { TextRow } from "./TextRow";
import { Style } from "@react-pdf/types";

export interface ReportColumnType {
  col: number;
  dataField: string;
  text?: any;
  align?: "left" | "right" | "center" | "justify";
  headerStyle?: Style;
  cellStyle?: Style;
  formatter?: (cell?: any, item?: any, index?: number) => any;
}

interface Props {
  columns: ReportColumnType[];
  data?: any[];
  stripped?: boolean;
  fixedHeader?: boolean;
  headerStyle?: Style;
  rowStyle?: Style;
}

export const ReportDataList: FC<Props> = ({
  columns,
  data = [],
  stripped,
  fixedHeader,
  headerStyle,
  rowStyle,
}) => {
  return (
    <View>
      <TextRow
        fixed={fixedHeader}
        style={headerStyle}
        columns={columns.map((item) => {
          return {
            col: item.col,
            text: item.text,
            style: {
              textAlign: item.align,
              fontFamily: "Open Sans Bold",
              marginTop: "auto",
              ...item.headerStyle,
            },
          };
        })}
      />
      {data.map((item, index) => (
        <TextRow
          wrap={false}
          style={{
            backgroundColor: stripped
              ? index % 2 === 0
                ? "#DDD"
                : "white"
              : "",
            ...rowStyle,
          }}
          columns={columns.map((col) => {
            return {
              col: col.col,
              text: col.formatter
                ? col.formatter(item[col.dataField], item, index)
                : item[col.dataField],
              style: { textAlign: col.align, ...col.cellStyle },
            };
          })}
        />
      ))}
    </View>
  );
};
