import { FC } from "react";
import { Table } from "../../../../../components/Table";
import { TableColumn } from "../../../../../components/table/TableRow";
import { extractMarkdownText } from "../../../../../coreUtils";

interface Props {
  data: any;
  pageSize?: any;
}

const columns: TableColumn[] = [
  {
    dataField: "dh_interacao",
    text: "Data/Hora",
    align: "center",
    cellContentTag: null,
    formatter: (c, row) => <span id={`id-inter-lead-${row.id}`}>{c}</span>,
  },
  {
    dataField: "nome_colaborador",
    text: "Colaborador",
    align: "left",
  },
  {
    dataField: "receptivo",
    text: "Interação",
    align: "center",
    formatter: (c: any) => (c ? "Receptiva" : "Ativa"),
  },
  {
    dataField: "observ",
    text: "Detalhes da Interação",
    align: "left",
    formatter: (c) => extractMarkdownText(c),
    fixedColWidth: true,
    colWidth: "70%",
  },
];

export const InteracoesLeadGrid: FC<Props> = ({ data, pageSize }) => {
  return (
    <Table
      data={data}
      columns={columns}
      fixedSize={pageSize ?? false}
      pageSize={pageSize}
      growIntoPageSize
      showRegisterCount={false}
      paginated={false}
      bgStyle={{ marginBottom: "0.5rem" }}
    />
  );
};
