import { FC, useState } from "react";
import { ModalBase, ModalProps } from "../../../components/ModalBase";
import ImplantacaoService from "../../../services/ImplantacaoService";
import ComboBox, { ComboBoxOption } from "../../../components/ComboBox";

interface Props {
  isOpen: ModalProps["isOpen"];
  toggle: ModalProps["toggle"];
  selected: any;
  notifyEvent: () => void;
}

const modoConclusaoOptions: ComboBoxOption[] = [
  { label: "Concluída com Sucesso", value: "FIN" },
  { label: "Desistência", value: "DES" },
];

export const ConcluirImplantacaoModal: FC<Props> = ({
  isOpen,
  toggle,
  selected,
  notifyEvent,
}) => {
  const [modoConclusao, setModoConclusao] = useState(
    modoConclusaoOptions[0].value
  );
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    const payload = {
      id_implantacao: selected,
      item: "status",
      valor: modoConclusao,
    };
    setLoading(true);
    const [ok] = await ImplantacaoService.alterarItem(payload);
    if (ok) {
      notifyEvent();
      toggle!();
    }
    setLoading(false);
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      title="Concluir Implantação"
      number="014_4"
      size="sm"
      onConfirm={handleSubmit}
      loadingConfirm={loading}
      onClosed={() => setModoConclusao(modoConclusaoOptions[0].value)}
    >
      <ComboBox
        md={8}
        label="Finalizar como"
        options={modoConclusaoOptions}
        value={modoConclusao}
        onChange={setModoConclusao}
        divClassName="mx-auto"
      />
    </ModalBase>
  );
};
