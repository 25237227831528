import React, { useEffect, useState } from "react";
import { handleFocus, limparNumero } from "../coreUtils";
import TelefoneBrasileiroInput from "react-telefone-brasileiro";
import { Col, FormGroup, Label } from "reactstrap";
import classNames from "classnames";

export const PhoneInput = ({
  md,
  label,
  value,
  onChange,
  divClassName,
  className,
  formGroupClassName,
  hiddenLabel,
  mdInput = 12,
  maxLength = 18,
  id,
}) => {
  const [internalValue, setInternalValue] = useState("");
  const [internalId] = useState(
    id ?? "pi-" + Math.floor(Math.random() * Date.now())
  );
  const [temDDD, setTemDDD] = useState(true);

  const handleOnChange = (e) => {
    e.preventDefault();
    const inputValue = e.target.value?.toUpperCase() || "";
    setInternalValue(inputValue);
    if (inputValue.length === 0) {
      setTemDDD(true);
    }
    if (onChange) {
      onChange(inputValue, e);
    }
  };

  const verificaTemDDD = (val) => {
    const v = limparNumero(val);
    const temNonoDigito = v.length > 9 && v[0] === "9";
    const limitador = temNonoDigito ? 9 : 8;
    return v.length > limitador;
  };

  const onKeyPress = (e) => {
    if (["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"].includes(e.key)) {
      if ((e.target.value ?? "").length === 0) {
        setTemDDD(true);
      }
    }
  };

  useEffect(() => {
    const numeros = limparNumero(value);
    if (numeros !== limparNumero(internalValue)) {
      setTemDDD(verificaTemDDD(numeros));
      setInternalValue(value.replaceAll(/(\d)\./g, / $1 /));
    }
  }, [value]);

  const onKeyDownInternal = (e) => {
    handleFocus(e);
  };

  return (
    <Col md={md} className={divClassName}>
      <FormGroup className={formGroupClassName}>
        <Label for={internalId} hidden={hiddenLabel}>
          {label}
        </Label>
        <Col md={mdInput} className="no-gutters">
          <TelefoneBrasileiroInput
            value={internalValue}
            onChange={handleOnChange}
            onKeyPress={onKeyPress}
            maxLength={maxLength}
            onKeyDown={onKeyDownInternal}
            temDDD={temDDD}
            separaDDD
            className={classNames("form-control", className)}
          />
        </Col>
      </FormGroup>
    </Col>
  );
};
