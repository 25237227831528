import { FC } from "react";
import { Bell } from "react-feather";
import { useDispatch, useSelector } from "react-redux";

import {
  DropdownMenu,
  DropdownToggle,
  ListGroup,
  ListGroupItem,
  UncontrolledDropdown,
} from "reactstrap";
import {
  formatarResumoNotif,
  onClickNotification,
} from "./NotificationManager";
import moment from "moment";
import classNames from "classnames";
import { FormCheckbox } from "./FormCheckbox";
import { notifMostrarNaoLidas } from "../redux/reducers/notificacoesReducer";
import { useHistory, useLocation } from "react-router-dom";

interface NotificationItemProps {
  notif: any;
}

const NotificationItem: FC<NotificationItemProps> = ({ notif }) => {
  const dispatch = useDispatch();
  const dataHora = moment(notif.data_hora_emi);
  const history = useHistory();
  const location = useLocation();

  return (
    <ListGroupItem
      className={classNames(notif.lida && "readen")}
      onClick={() => onClickNotification(dispatch, history, location, notif)}
    >
      <div className="text-dark">{notif.titulo}</div>
      <div className="text-muted mt-1">{formatarResumoNotif(notif)}</div>
      <div className="text-muted small mt-1">
        {dataHora.format(
          dataHora.toDate().toDateString() === new Date().toDateString()
            ? "HH:mm"
            : "DD/MM/YYYY hh:mm"
        )}
      </div>
    </ListGroupItem>
  );
};

export const NotificationDropdown: FC = () => {
  const store = useSelector((state: any) => state.notificacoes);

  const notificacoes = store.notificacoes;
  const notificacoesNaoLidas = notificacoes.filter(
    (e: any) => e.lida === false
  ).length;

  const dispatch = useDispatch();

  return (
    <UncontrolledDropdown nav inNavbar className="mr-2">
      <DropdownToggle nav className="nav-icon dropdown-toggle">
        <div className="position-relative">
          <Bell className="align-middle" size={18} />
          {notificacoesNaoLidas > 0 ? (
            <span className="notif-indicator">{notificacoesNaoLidas}</span>
          ) : null}
        </div>
      </DropdownToggle>
      <DropdownMenu right className="dropdown-menu-lg py-0">
        <div className="notify-list-header position-relative">
          <FormCheckbox
            padded={false}
            label="Mostrar Lidas"
            checked={store.mostrarNaoLidas}
            onChange={(v: any) => dispatch(notifMostrarNaoLidas(v))}
          />
        </div>
        <ListGroup className="notify-list">
          {notificacoes.length > 0 ? (
            notificacoes.map((notif: any) => <NotificationItem notif={notif} />)
          ) : (
            <div className="dropdown-menu-header position-relative">
              <div className="text-muted">Sem novas notificações</div>
            </div>
          )}
        </ListGroup>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};
