import { FC } from "react";
import { Table } from "../../../components/Table";
import { TableColumn } from "../../../components/table/TableRow";
import { formatValueFromAPI, naturalSortDate } from "../../../coreUtils";
import { IconButton } from "../../../components/IconButton";
import { BsFileText } from "react-icons/bs";
import { MoreDropdown } from "../../../components/MoreDropdown";
import { DropdownItem } from "reactstrap";

interface Props {
  dados: Array<any>;
  setSelected: (id: any) => void;
  mostrarDetalhes: (id: any) => void;
  darOkUso: (id: any) => void;
  concluir: (id: any) => void;
}

const statusSetorFormatter = (v: any) => (
  <>
    <div
      style={{
        width: "10px",
        height: "10px",
        borderRadius: "50%",
        display: "inline-block",
        marginRight: "0.5rem",
        backgroundColor:
          v === "Pendente"
            ? "#fdc675"
            : v === "Em Andamento"
            ? "#3C9AE0"
            : v === "Finalizado"
            ? "#96e3a5"
            : undefined,
      }}
    />
    {v}
  </>
);

export const ImplantacaoGrid: FC<Props> = ({
  dados,
  mostrarDetalhes,
  darOkUso,
  concluir,
  setSelected,
}) => {
  const columns: TableColumn[] = [
    {
      dataField: "nome_empresa",
      text: "Empresa",
      align: "left",
      formatter: (c: any, row: any) => formatValueFromAPI(c, row.id_empresa),
      sortable: true,
    },
    {
      dataField: "nome_plano",
      text: "Plano",
      align: "left",
      formatter: (c, row) => (
        <>
          {c}
          <img className="ml-1" src={row.icone_plano} />
        </>
      ),
      sortable: true,
    },
    { dataField: "desc_modo_implanta", text: "Modo", align: "center" },
    {
      dataField: "data_inclusao",
      text: "Inclusão",
      align: "center",
      sortable: true,
      sortFunc: naturalSortDate,
    },
    {
      dataField: "desc_status",
      text: "Situação",
      align: "center",
      sortable: true,
    },
    {
      dataField: "status_fin",
      text: "Financeiro",
      align: "left",
      formatter: statusSetorFormatter,
      sortable: true,
    },
    {
      dataField: "status_sup",
      text: "Suporte",
      align: "left",
      formatter: statusSetorFormatter,
      sortable: true,
    },
    {
      dataField: "status_dev",
      text: "Migra Dados",
      align: "left",
      formatter: statusSetorFormatter,
      sortable: true,
    },
    {
      dataField: "apps_contratados",
      text: "Apps Contratados",
      align: "left",
      formatter: (c) =>
        c.map((e: any) => (
          <span
            style={{
              backgroundColor: "#dbdbdb",
              paddingInline: "0.3rem",
              marginRight: "0.3rem",
              borderRadius: "0.2rem",
            }}
          >
            {e}
          </span>
        )),
    },
    {
      dataField: "ok_uso",
      text: "OK de Uso",
      align: "center",
      sortable: true,
      sortFunc: naturalSortDate,
    },
    {
      dataField: "act",
      text: "",
      align: "center",
      dummy: true,
      formatter: (c, row) => (
        <IconButton icon={BsFileText} onClick={() => mostrarDetalhes(row.id)} />
      ),
    },
    {
      dataField: "act",
      text: "",
      align: "center",
      formatter: (c, row) => (
        <MoreDropdown>
          <DropdownItem onClick={() => darOkUso(row.id)}>
            Dar OK de Uso
          </DropdownItem>
          {!["FIN", "DES"].includes(row.status) && (
            <DropdownItem onClick={() => concluir(row.id)}>
              Concluir Implantação
            </DropdownItem>
          )}
        </MoreDropdown>
      ),
    },
  ];

  return (
    <Table
      data={dados}
      columns={columns}
      onRowDoubleClick={mostrarDetalhes}
      onSelect={setSelected}
    />
  );
};
