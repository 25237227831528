import { FC, useEffect, useState } from "react";
import { BsDownload, BsX } from "react-icons/bs";
import { Col, Label, Row, Spinner } from "reactstrap";
import {
  AsyncComboBox,
  FormCheckbox,
  IntegerInput,
  TextInput,
  ComboBox,
} from "../../../../../components";
import { ComboBoxOption } from "../../../../../components/ComboBox";
import { DatePicker } from "../../../../../components/DatePicker";
import DragAndDropArquivos from "../../../../../components/DragAndDropArquivos";
import MaskedInput from "../../../../../components/MaskedInput";
import { downloadFileFromBlob, formatDate } from "../../../../../coreUtils";
import { DragDropImagem } from "../../../../../components/DragDropImagem";
import { Divider } from "../../../../../components/Divider";
import { toastr } from "react-redux-toastr";
import { auth } from "../../../../../api";
import { PopModal } from "../../../../../components/PopModal";
import {
  RadioGroup,
  RadioGroupOption,
} from "../../../../../components/RadioGroup";
import {
  ModalActions,
  ModalCadastroProps,
  ModalCadastroV2,
} from "../../../../../components/cadastro/ModalCadastroV2";
import { modalTitleEmpresa, routesBaseEmpresa } from "../Empresa";
import { APIs } from "../../../../../apiV2";
import { IconButton } from "../../../../../components/IconButton";

const regimesTribut: ComboBoxOption[] = [
  { label: "Simples Nacional", value: "SIM" },
  { label: "Lucro Presumido", value: "PRE" },
  { label: "Lucro Real", value: "REA" },
];

interface PropsDragDropCert {
  certificado: any;
  handleSetCertificado: any;
  certificadoConfigurado: boolean;
}

const tipoSistemaOptions: RadioGroupOption[] = [
  { label: "Padrão", value: "PADRAO" },
  { label: "Mecânica", value: "MECANICA" },
];

const DragDropCert: FC<PropsDragDropCert> = ({
  certificado,
  handleSetCertificado,
  certificadoConfigurado,
}) => (
  <Col md="auto">
    {certificado ? (
      <div>
        <h5 className="mb-1">Certificado:</h5>
        <span>
          <strong>{certificado?.file.name}</strong>
          <BsX
            size={20}
            color="red"
            style={{ cursor: "pointer" }}
            onClick={() => handleSetCertificado(null)}
          />
        </span>
      </div>
    ) : (
      <DragAndDropArquivos
        md="auto"
        divClassName="no-gutters"
        text={
          !certificadoConfigurado
            ? "Selecionar Certificado"
            : "Atualizar Certificado"
        }
        onDrop={handleSetCertificado}
        accept="application/x-pkcs12"
        style={{
          padding: "0.5rem 2rem",
          marginTop: "0",
          color: "black",
          cursor: "pointer",
          borderColor: "#ddd",
          marginLeft: "2px",
        }}
      />
    )}
  </Col>
);

interface Props {
  isOpen: ModalCadastroProps["isOpen"];
  toggle: ModalCadastroProps["toggle"];
  action: ModalCadastroProps["action"];
  selected: any;
  notifyEvent: ModalCadastroProps["notifyEvent"];
}

export const CadastroEmpresaModal: FC<Props> = ({
  isOpen,
  toggle,
  action,
  selected,
  notifyEvent,
}) => {
  const [ativo, setAtivo] = useState(true);
  const [nomeFant, setNomeFant] = useState("");
  const [razaoSocial, setRazaoSocial] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [regimeTribut, setRegimeTribut] = useState(regimesTribut[0].value);
  const [siglaUser, setSiglaUser] = useState("");
  const [dataLim, setDataLim] = useState<any>(new Date());
  const [licenca, setLicenca] = useState(0);
  const [certificado, setCertificado] = useState<{
    file: File;
    base64: string;
  } | null>();
  const [mudouCertificado, setMudouCertificado] = useState(false);
  const [certificadoConfigurado, setCertificadoConfigurado] = useState(false);
  const [logo, setLogo] = useState<{
    file: File | null;
    base64: string | null;
  } | null>();
  const [mudouLogo, setMudouLogo] = useState(false);
  const [idPlanoContratado, setIdPlanoContratado] = useState(0);
  const [tipoCertificado, setTipoCertificado] = useState("FILE");
  const [senhaCertificado, setSenhaCertificado] = useState("");
  const [validadeCertificado, setValidadeCertificado] = useState<
    string | Date | null
  >("");
  const [nroSerieCertificado, setNroSerieCertificado] = useState<string | null>(
    null
  );
  const [possuiServidorLocal, setPossuiServidorLocal] = useState(false);
  const [ipBancoDados, setIpBancoDados] = useState("");
  const [appVendaDirContratado, setAppVendaDirContratado] = useState(false);
  const [qtdUserAppVendaDir, setQtdUserAppVendaDir] = useState(0);
  const [appPedVdaContratado, setAppPedVdaContratado] = useState(false);
  const [qtdUserAppPedVda, setQtdUserAppPedVda] = useState(0);
  const [appGerencialContratado, setAppGerencialContratado] = useState(false);
  const [qtdUserAppGerencial, setQtdUserAppGerencial] = useState(0);
  const [qtdUserLimitWeb, setQtdUserLimitWeb] = useState(0);
  const [qtdUserAdmin, setQtdUserAdmin] = useState(0);
  const [sistemaOpenContratado, setSistemaOpenContratado] = useState(false);
  const [tipoSistema, setTipoSistema] = useState("PADRAO");
  const [emailProprietario, setEmailProprietario] = useState("");
  const [idContabilidade, setIdContabilidade] = useState(null);
  const [loadingLogo, setLoadingLogo] = useState(false);

  const limparDados = () => {
    setAtivo(true);
    setNomeFant("");
    setRazaoSocial("");
    setCnpj("");
    setRegimeTribut(regimesTribut[0].value);
    setSiglaUser("");
    setTipoCertificado("FILE");
    setSenhaCertificado("");
    setNroSerieCertificado(null);
    setPossuiServidorLocal(false);
    setIpBancoDados("");
    setDataLim(new Date());
    setLicenca(0);
    setCertificado(null);
    setMudouCertificado(false);
    setCertificadoConfigurado(false);
    setLogo(null);
    setMudouLogo(false);
    setValidadeCertificado("");
    setIdPlanoContratado(0);
    setAppVendaDirContratado(false);
    setQtdUserAppVendaDir(0);
    setAppPedVdaContratado(false);
    setQtdUserAppPedVda(0);
    setAppGerencialContratado(false);
    setQtdUserAppGerencial(0);
    setQtdUserLimitWeb(0);
    setQtdUserAdmin(0);
    setSistemaOpenContratado(false);
    setTipoSistema("PADRAO");
    setEmailProprietario("");
    setIdContabilidade(null);
  };

  const buscarLogo = async () =>
    auth
      .get(`/cadastro/empresa/buscar_logo/${selected}/`, {
        responseType: "blob",
      })
      .then(
        (res: any) =>
          new Promise((resolve) => {
            if (res.status !== 200) {
              resolve(null);
            }
            const reader = new FileReader();
            reader.onloadend = () =>
              resolve({ file: null, base64: reader.result });
            reader.readAsDataURL(res.data);
          })
      )
      .catch((err: any) => {
        toastr.error("Erro", err.message);
        return null;
      });

  const carregarLogo = async () => {
    setLoadingLogo(true);
    const resLogo = await buscarLogo();
    setLogo(resLogo as any);
    setLoadingLogo(false);
  };

  const fetchData = async (data: any) => {
    setLicenca(selected);
    setAtivo(data.ativo);
    setNomeFant(data.nome_fant);
    setRazaoSocial(data.razao_social);
    setRegimeTribut(data.regime_tribut);
    setCnpj(data.cnpj);
    setSiglaUser(data.sigla_user ?? "");
    setTipoCertificado(data.tipo_certificado);
    setSenhaCertificado(data.senha_certificado ?? "");
    setNroSerieCertificado(data.nro_serie_certificado);
    setValidadeCertificado(data.validade_certif ?? "");
    setPossuiServidorLocal(data.possui_servidor_local ?? false);
    setIpBancoDados(data.ip_banco_dados ?? "");
    setDataLim(data.data_lim);
    setIdPlanoContratado(data.id_plano_contratado);
    setAppVendaDirContratado(data.app_venda_dir_contratado);
    setQtdUserAppVendaDir(data.qtd_user_app_venda_dir);
    setAppPedVdaContratado(data.app_ped_vda_contratado);
    setQtdUserAppPedVda(data.qtd_user_app_ped_vda);
    setAppGerencialContratado(data.app_gerencial_contratado);
    setQtdUserAppGerencial(data.qtd_user_app_gerencial);
    setQtdUserLimitWeb(data.qtd_user_limit_web);
    setQtdUserAdmin(data.qtd_user_admin);
    setSistemaOpenContratado(data.sistema_open_contratado);
    setCertificadoConfigurado(data.certificado_configurado);
    setTipoSistema(data.tipo_sist);
    setEmailProprietario(data.email_proprietario);
    setIdContabilidade(data.ci_id_contabilidade);
    await carregarLogo();
  };

  const incluirLogo = async (
    base64: FileReader["result"],
    file: File | null
  ) => {
    if (!base64) {
      setLogo(null);
      return;
    }
    const res = {
      file: file,
      base64: base64 as string,
    };

    setLogo(res);
    setMudouLogo(true);
  };

  const handleSetCertificado = async (file?: File) => {
    if (!file) {
      setCertificado(null);
      return;
    }
    var reader = new FileReader();

    reader.addEventListener("load", async function (e) {
      const res = {
        file: file!,
        base64: e.target?.result as string,
      };

      setCertificado(res);
      setMudouCertificado(true);
    });

    reader.readAsDataURL(file!);
  };

  const submitPayload = (action: any) => {
    if (qtdUserAdmin === 0) {
      toastr.warning(
        "Atenção",
        "Por favor, informe a quantidade de usuários " +
          "com acesso ao sistema de Retaguarda/Administrativo"
      );
      return false;
    }

    if (appVendaDirContratado && qtdUserAppVendaDir === 0) {
      toastr.warning(
        "Atenção",
        "Por favor, informe a quantidade de APPs " +
          "do sistema de Pronta Entrega ativos."
      );
      return false;
    }

    if (appPedVdaContratado && qtdUserAppPedVda === 0) {
      toastr.warning(
        "Atenção",
        "Por favor, informe a quantidade de APPs " +
          "do sistema de Pedido Externo ativos."
      );
      return false;
    }

    if (appGerencialContratado && qtdUserAppGerencial === 0) {
      toastr.warning(
        "Atenção",
        "Por favor, informe a quantidade de APPs " +
          "do sistema Gerencial ativos."
      );
      return false;
    }

    const payload: any = {
      licenca: action === ModalActions.add ? licenca : selected,
      nome_fant: nomeFant,
      razao_social: razaoSocial,
      cnpj: cnpj,
      regime_tribut: regimeTribut,
      data_limite: formatDate(dataLim),
      tipo_certificado: tipoCertificado,
      senha_certificado: senhaCertificado,
      possui_servidor_local: possuiServidorLocal,
      ip_banco_dados: ipBancoDados,
      id_plano_contratado: idPlanoContratado ?? 0,
      app_venda_dir_contratado: appVendaDirContratado,
      qtd_user_app_venda_dir: qtdUserAppVendaDir,
      app_ped_vda_contratado: appPedVdaContratado,
      qtd_user_app_ped_vda: qtdUserAppPedVda,
      app_gerencial_contratado: appGerencialContratado,
      qtd_user_app_gerencial: qtdUserAppGerencial,
      qtd_user_limit_web: qtdUserLimitWeb,
      qtd_user_admin: qtdUserAdmin,
      sistema_open_contratado: sistemaOpenContratado,
      tipo_sist: tipoSistema,
      ativo: ativo,
      email_proprietario: emailProprietario ?? "",
      id_contabilidade: idContabilidade ?? 0,
      data_validade_certificado:
        validadeCertificado != null ? formatDate(validadeCertificado) : null,
      nro_serie_certificado: nroSerieCertificado,
    };

    if (action === ModalActions.add) {
      payload["sigla_user"] = siglaUser ?? "";
    }

    let formData = new FormData();
    formData.append("json", JSON.stringify(payload));
    if (certificado && mudouCertificado) {
      formData.append("certificado", certificado.file, certificado.file.name);
    }

    if (logo && mudouLogo && logo.file) {
      formData.append("logo", logo.file, logo.file.name);
    }

    return [formData, { editMethod: "POST" }];
  };

  const handleSetPossuiServidorLocal = (v: boolean) => {
    setPossuiServidorLocal(v);
    if (!v) {
      setIpBancoDados("");
    }
  };

  const downloadCertificado = async () => {
    const [data, filename] = await auth
      .get(`/cadastro/empresa/download_certificado/${selected}/`, {
        responseType: "blob",
      })
      .then(async (ret) => {
        if (ret.data.type === "application/x-pkcs12") {
          const filename =
            ret.headers["content-disposition"] ||
            `certificado_l${selected}.pfx`;
          return [ret.data, filename];
        } else if (ret.data.type === "application/json") {
          const json_ret = JSON.parse(await ret.data.text());
          toastr.error("Erro", json_ret.msg);
        }

        return [false, null];
      })
      .catch((err) => {
        toastr.error("Erro", err.message);
        return [false, null];
      });

    if (data) {
      downloadFileFromBlob(data, filename);
    }
  };

  return (
    <ModalCadastroV2
      isOpen={isOpen}
      toggle={toggle}
      action={action}
      title={modalTitleEmpresa}
      size="lg"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      headerCheck={{
        value: ativo,
        toggle: () => setAtivo(!ativo),
      }}
      routesBase={routesBaseEmpresa}
      number="003_1"
      selected={selected}
      notifyEvent={notifyEvent}
      useApi={APIs.auth}
    >
      <Row className="no-gutters">
        <Col className="no-gutters" md={8}>
          <Row>
            <IntegerInput
              md={2}
              label="Licença"
              value={licenca}
              onChange={setLicenca}
              autoFocus={action === ModalActions.add}
              disabled={action !== ModalActions.add}
            />
          </Row>
          <Row>
            <TextInput
              label="Nome Fantasia"
              md={12}
              onChange={setNomeFant}
              value={nomeFant}
              autoFocus={action === ModalActions.edit}
            />
          </Row>
          <Row>
            <TextInput
              label="Razão Social"
              md={12}
              onChange={setRazaoSocial}
              value={razaoSocial}
            />
          </Row>
          <Row>
            <MaskedInput
              mask="99.999.999/9999-99"
              name="cnpj"
              label={"CNPJ"}
              md={4}
              value={cnpj}
              onChange={setCnpj}
            />
            <DatePicker
              label="Data Limite da Licença"
              value={dataLim}
              onChange={setDataLim}
            />
          </Row>
        </Col>
        {loadingLogo ? (
          <div
            className="ml-auto"
            style={{
              display: "flex",
              width: "30%",
              height: "225px",
              background: "#eee",
              border: "1px solid #dee2e6",
            }}
          >
            <Spinner className="m-auto" size="md" color="silver" />
          </div>
        ) : (
          <DragDropImagem
            placeholder="Adicionar Logo"
            divClassName="ml-auto"
            width="30%"
            height="225px"
            value={logo?.base64}
            onChange={incluirLogo}
            noDelete
            acceptFormats="png"
          />
        )}
      </Row>
      <Row>
        <ComboBox
          md={4}
          label="Regime Tributário"
          options={regimesTribut}
          isSearchable={false}
          onChange={setRegimeTribut}
          value={regimeTribut}
        />
        <AsyncComboBox
          md={4}
          isConcatField
          concatModelName="contabilidade"
          defaultOptions
          label="Contabilidade"
          isSearchable
          onChange={setIdContabilidade}
          value={idContabilidade}
        />
      </Row>
      <Row>
        <AsyncComboBox
          md={4}
          isConcatField
          concatModelName="plano_contratado"
          defaultOptions
          label="Plano Contratado"
          isSearchable
          onChange={setIdPlanoContratado}
          value={idPlanoContratado}
        />
        <RadioGroup
          label="Tipo de Sistema"
          value={tipoSistema}
          onChange={setTipoSistema}
          options={tipoSistemaOptions}
        />
        <TextInput
          upperCaseValue={false}
          type="email"
          label="E-mail Proprietário"
          value={emailProprietario}
          onChange={setEmailProprietario}
        />
      </Row>
      <Divider className="mb-0">Certificado Digital</Divider>
      <Row>
        <RadioGroup
          options={[
            { label: "Arquivo", value: "FILE" },
            { label: "Cartão/Token", value: "CART" },
          ]}
          onChange={(v) => {
            if (v !== tipoCertificado) {
              setNroSerieCertificado(null);
              setCertificado(null);
              setCertificadoConfigurado(false);
              setValidadeCertificado(null);
            }
            setTipoCertificado(v);
          }}
          label="Tipo de Certificado"
          value={tipoCertificado}
        />
      </Row>
      {tipoCertificado === "FILE" ? (
        <Row className="mb-2">
          {certificadoConfigurado && (
            <Col md="3" style={{ display: "flex" }}>
              <Row className="no-gutters">
                <Label
                  className="mb-0 no-gutters"
                  style={{
                    fontWeight: "bold",
                    color: "blue",
                    alignSelf: "center",
                    margin: "0 !important",
                    padding: "0px",
                    paddingRight: "15px",
                  }}
                >
                  Certificado Configurado
                </Label>
                <IconButton
                  icon={BsDownload}
                  onClick={(e) => {
                    e.preventDefault();

                    downloadCertificado();
                  }}
                  style={{ alignSelf: "center" }}
                  hint="Fazer download do certificado configurado"
                />
              </Row>
            </Col>
          )}
          <DragDropCert
            certificado={certificado}
            handleSetCertificado={handleSetCertificado}
            certificadoConfigurado={certificadoConfigurado}
          />
          <PopModal
            idPop={11}
            label="Converter certificado para .pfx"
            buttonType="label"
          />
        </Row>
      ) : (
        <Row>
          <TextInput
            md={6}
            type="text"
            label="Número de Série do Certificado"
            value={nroSerieCertificado}
            onChange={setNroSerieCertificado}
          />
        </Row>
      )}
      <Row>
        <TextInput
          md={3}
          label="Senha do Certificado"
          value={senhaCertificado}
          onChange={setSenhaCertificado}
          upperCaseValue={false}
        />
        <DatePicker
          md={3}
          label="Validade do Certificado"
          value={!mudouCertificado ? validadeCertificado : ""}
          onChange={setValidadeCertificado}
          disabled={tipoCertificado === "FILE"}
        />
      </Row>
      <Divider className="mb-0">Dados Técnicos</Divider>
      <Row>
        <TextInput
          label="Sigla"
          md={3}
          onChange={(v: any) =>
            setSiglaUser(v?.replace(/[^a-zA-Z0-9_@]/gi, "")?.toLowerCase())
          }
          value={siglaUser}
          upperCaseValue={false}
          updateEveryRender
          disabled={action === ModalActions.edit}
        />
        <FormCheckbox
          divClassName="p-0"
          md={2}
          label="Possui Servidor Local"
          checked={possuiServidorLocal}
          onChange={handleSetPossuiServidorLocal}
        />
        {possuiServidorLocal && (
          <TextInput
            label="IP do Banco de Dados"
            md={4}
            upperCaseValue={false}
            value={ipBancoDados}
            onChange={setIpBancoDados}
            unlockToEdit={action === ModalActions.edit}
            maxLength={50}
          />
        )}
      </Row>
      <Divider className="mb-0">Usuários Contratados</Divider>
      <Row>
        <Label style={{ textAlign: "right" }} className="mt-auto mb-2" md={8}>
          Informe ao lado a quantidade de usuários com acesso ao sistema de
          Retaguarda/Administrativo
        </Label>
        <IntegerInput md={2} value={qtdUserAdmin} onChange={setQtdUserAdmin} />
      </Row>
      <Row>
        <Label style={{ textAlign: "right" }} className="mt-auto mb-2" md={6}>
          Marque a opção ao lado para liberar acesso ao sistema de Pronta
          Entrega
        </Label>
        <FormCheckbox
          md={2}
          label="Liberado"
          checked={appVendaDirContratado}
          onChange={setAppVendaDirContratado}
        />
        <IntegerInput
          md={2}
          label="Qtd. APPs Ativos"
          value={qtdUserAppVendaDir}
          onChange={setQtdUserAppVendaDir}
          clearOnDisable
          disabled={!appVendaDirContratado}
        />
      </Row>
      <Row>
        <Label style={{ textAlign: "right" }} className="mt-auto mb-2" md={6}>
          Marque a opção ao lado para liberar acesso ao sistema de Pedido
          Externo
        </Label>
        <FormCheckbox
          md={2}
          label="Liberado"
          checked={appPedVdaContratado}
          onChange={setAppPedVdaContratado}
        />
        <IntegerInput
          md={2}
          label="Qtd. APPs Ativos"
          value={qtdUserAppPedVda}
          onChange={setQtdUserAppPedVda}
          clearOnDisable
          disabled={!appPedVdaContratado}
        />
      </Row>
      <Row>
        <Label style={{ textAlign: "right" }} className="mt-auto mb-2" md={6}>
          Marque a opção ao lado para liberar acesso ao sistema Gerencial
        </Label>
        <FormCheckbox
          md={2}
          label="Liberado"
          checked={appGerencialContratado}
          onChange={setAppGerencialContratado}
        />
        <IntegerInput
          md={2}
          label="Qtd. APPs Ativos"
          value={qtdUserAppGerencial}
          onChange={setQtdUserAppGerencial}
          clearOnDisable
          disabled={!appGerencialContratado}
        />
      </Row>
    </ModalCadastroV2>
  );
};
