import { Icon } from "react-feather";
import authRoutes from "./modules/auth";
import {
  relatoriosCadastraisRoute,
  relatoriosRoutes,
} from "./modules/relatorios";
import { processosRoutes } from "./modules/processos";
import { RouteProps } from "react-router-dom";
import {
  administrativoRoutes,
  administrativoProcessosRoute,
  administrativoCadastrosRoute,
} from "./modules/administrativo";
import {
  comercialCadastrosRoute,
  comercialRoutes,
  interacoesLeadRoute,
} from "./modules/comercial";
import {
  desenvolvimentoCadastrosRoute,
  desenvolvimentoProcessosRoute,
  desenvolvimentoRoutes,
  excecaoMenuRoute,
  incluirAlterarDemandaDevRoute,
} from "./modules/desenvolvimento";
import { implantacaoRoute } from "./modules/implantacao";
// import { controleRoutes } from "./modules/controle";

export interface RoutePermission {
  id: string;
  liberado: boolean;
}

export interface RouteType {
  id?: string;
  path: string;
  children?: RouteType[];
  name?: string;
  badgeColor?: string;
  badgeText?: string;
  icon?: Icon;
  component?: RouteProps["component"];
  canGoBack?: boolean;
  hint?: string;
}

export const checkRoutes = (
  routes: RouteType[],
  permissions: RoutePermission[]
) => {
  const allowedRoutes: RouteType[] = [];

  routes.forEach((route) => {
    if (route!.children) {
      // Verifica se a rota possui filhos com permissão
      route.children = checkRoutes(route!.children, permissions);
      if (route.children.length > 0) allowedRoutes.push(route);
    } else {
      allowedRoutes.push(route);
    }
  });

  return allowedRoutes;
};

export const defaultRoutes: RouteType[] = [
  excecaoMenuRoute,
  relatoriosRoutes,
  processosRoutes,
  administrativoRoutes,
  comercialRoutes,
  desenvolvimentoRoutes,
  incluirAlterarDemandaDevRoute,
  implantacaoRoute,
  administrativoCadastrosRoute,
  administrativoProcessosRoute,
  comercialCadastrosRoute,
  desenvolvimentoCadastrosRoute,
  desenvolvimentoProcessosRoute,
  relatoriosCadastraisRoute,
  interacoesLeadRoute,
];

export const pageRoutes: RouteType[] = [authRoutes];

const routes: RouteType[] = [
  administrativoRoutes,
  comercialRoutes,
  desenvolvimentoRoutes,
  implantacaoRoute,
  relatoriosRoutes,
];

// All routes
export default routes;
