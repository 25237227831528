import { FC } from "react";
import { RouteType } from "../routes";
import { MenuGroupButton } from "./MenuGroupButton";
import { naturalSort } from "../coreUtils";
import { PageContainer } from "./PageContainer";
import { Divider } from "./Divider";
import classNames from "classnames";

interface Props {
  routes: RouteType[];
  title: string;
  canGoBack?: boolean;
}

export const MenuGroup: FC<Props> = ({ routes, title, canGoBack = false }) => {
  return (
    <PageContainer title={title} canGoBack={canGoBack}>
      <div className="menu-group">
        {routes
          .sort((a, b) => naturalSort(a.name ?? "", b.name ?? ""))
          .filter((e) => !e.children)
          .map((e) => (
            <MenuGroupButton route={e} />
          ))}
      </div>
      {routes
        .sort((a, b) => naturalSort(a.name ?? "", b.name ?? ""))
        .filter((e) => e.children)
        .map((e, index) => (
          <>
            <Divider className={classNames("ml-3", index === 0 && "mt-1")}>
              {e.name}
            </Divider>
            <div className="menu-group">
              {e
                .children!.sort((a, b) =>
                  naturalSort(a.name ?? "", b.name ?? "")
                )
                .map((c) => (
                  <MenuGroupButton route={c} />
                ))}
            </div>
          </>
        ))}
    </PageContainer>
  );
};
