import { FC } from "react";
import { useState } from "react";
import {
  modalTitleOrigemCliente,
  routesBaseOrigemCliente,
} from "../OrigemCliente";
import {
  ModalActions,
  ModalCadastroProps,
  ModalCadastroV2,
} from "../../../../../components/cadastro/ModalCadastroV2";
import { TextInput } from "../../../../../components";
import { Row } from "reactstrap";

interface Props {
  isOpen: ModalCadastroProps["isOpen"];
  toggle: ModalCadastroProps["toggle"];
  action: ModalCadastroProps["action"];
  selected: any;
  notifyEvent: ModalCadastroProps["notifyEvent"];
}

export const CadastroOrigemClienteModal: FC<Props> = ({
  isOpen,
  toggle,
  action,
  selected,
  notifyEvent,
}) => {
  const [ativo, setAtivo] = useState(true);
  const [nome, setNome] = useState("");

  const limparDados = () => {
    setNome("");
    setAtivo(true);
  };

  const fetchData = (data: any) => {
    setNome(data.nome);
    setAtivo(data.ativo);
  };

  const submitPayload = (action: ModalActions) => {
    const payload = {
      nome: nome,
      ativo: ativo,
    };

    return action === ModalActions.add ? payload : { id: selected, ...payload };
  };

  return (
    <ModalCadastroV2
      isOpen={isOpen}
      toggle={toggle}
      action={action}
      title={modalTitleOrigemCliente}
      size="sm"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      headerCheck={{
        value: ativo,
        toggle: () => setAtivo(!ativo),
      }}
      routesBase={routesBaseOrigemCliente}
      number="016_1"
      selected={selected}
      notifyEvent={notifyEvent}
    >
      <Row>
        <TextInput
          md={12}
          label="Nome"
          value={nome}
          onChange={setNome}
          maxLength={60}
        />
      </Row>
    </ModalCadastroV2>
  );
};
