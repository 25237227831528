import { Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import { FC } from "react";
import { PDFReport } from "../../../../components/pdf/PDFReport";

const styles = StyleSheet.create({
  fixedFieldLabel: {
    fontFamily: "Open Sans Bold",
    marginRight: "2px",
  },
  fixedFieldValue: { fontFamily: "Open Sans", fontSize: 9 },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

interface FixedFieldProps {
  label: any;
  value: any;
  labelStyle?: any;
  valueStyle?: any;
  wrapperStyle?: any;
  fontSize?: any;
}

const FixedField: FC<FixedFieldProps> = ({
  label,
  value,
  labelStyle,
  valueStyle,
  wrapperStyle,
  fontSize = 9,
}) => {
  return (
    <View style={{ flexDirection: "row", fontSize: fontSize, ...wrapperStyle }}>
      <Text
        style={{ ...styles.fixedFieldLabel, fontSize: fontSize, ...labelStyle }}
      >
        {label}:{" "}
      </Text>
      <Text
        style={{ ...styles.fixedFieldValue, fontSize: fontSize, ...valueStyle }}
      >
        {value}
      </Text>
    </View>
  );
};

interface Props {
  dados: any;
  imagem: any;
}

export const DemandaDevPDF: FC<Props> = ({ dados, imagem }) => {
  return (
    <PDFReport number="015_1">
      <View style={styles.row}>
        <FixedField label="Titulo" value={dados.titulo} />
      </View>
      <View style={styles.row}>
        <FixedField label="Cliente" value={dados.nome_cliente} />
        <FixedField
          label="Plano"
          value={
            <>
              <Image
                src={dados.icone_plano_contratado}
                style={{ marginBottom: "-2px" }}
              />
              {dados.nome_plano_contratado}
            </>
          }
        />
      </View>
      <View style={styles.row}>
        <FixedField label="Módulo" value={dados.nome_modulo} />
        <FixedField label="Tela" value={dados.nome_tela} />
        <FixedField label="Tipo" value={dados.desc_tipo} />
      </View>
      <Text style={{ fontFamily: "Open Sans Italic", fontSize: 9 }}>
        Detalhamento da Atividade:
      </Text>
      <Image style={{ maxWidth: "100%" }} src={imagem} />
    </PDFReport>
  );
};
