import { FC } from "react";
import { TableCheck } from "../../../../../components/TableCheck";
import { TableColumn } from "../../../../../components/table/TableRow";
import { Table } from "../../../../../components/Table";
import { iconeAlterar } from "../../../../../components/cadastro";
import { naturalSort, naturalSortDate } from "../../../../../coreUtils";

interface Props {
  data: any;
  setSelected: any;
  alterar: (id: any) => void;
}
export const UsuariosGrid: FC<Props> = ({ data, setSelected, alterar }) => {
  const columns: TableColumn[] = [
    {
      dataField: "id",
      text: "#",
      align: "center",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "colaborador",
      text: "Colaborador",
      align: "center",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "nickname",
      text: "nickname",
      align: "left",
      sortable: true,
    },
    {
      dataField: "ativo",
      text: "Ativo",
      align: "center",
      formatter: (c: any) => <TableCheck value={c} />,
      sortable: true,
    },
    {
      dataField: "empresa__nome_fant",
      text: "Empresa",
      align: "left",
      sortable: true,
    },
    {
      dataField: "empresa__cnpj",
      text: "CNPJ",
      align: "center",
      sortable: true,
    },
    {
      dataField: "empresa__ativo",
      text: "Ativa",
      align: "center",
      formatter: (c: any) => <TableCheck value={c} />,
      sortable: true,
    },
    {
      dataField: "licenca",
      text: "Licença",
      align: "center",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "data_limite",
      text: "Data Limite",
      align: "center",
      sortable: true,
      sortFunc: naturalSortDate,
    },
    iconeAlterar(alterar) as TableColumn,
  ];
  return (
    <Table
      data={data}
      columns={columns}
      onSelect={setSelected}
      onRowDoubleClick={alterar}
    />
  );
};
