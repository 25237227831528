import { FC } from "react";
import { BsCheck, BsX } from "react-icons/bs";

interface Props {
  value: any;
}

export const TableCheck: FC<Props> = ({ value }) =>
  value ? (
    <BsCheck size={16} color="#5fc27e" />
  ) : (
    <BsX size={16} color="#f44455" />
  );
