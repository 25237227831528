import { FC, useState } from "react";
import {
  AsyncComboBox,
  ComboBox,
  FormButton,
  FormCheckbox,
} from "../../../components";
import { ModalBase } from "../../../components/ModalBase";
import { toastr } from "react-redux-toastr";
import ImplantacaoService from "../../../services/ImplantacaoService";
import { Row } from "reactstrap";
import { Divider } from "../../../components/Divider";
import { ComboBoxOption } from "../../../components/ComboBox";

interface Props {
  notifyEvent: () => void;
}

export const modoImplantaOptions: ComboBoxOption[] = [
  { label: "Remoto", value: "REM" },
  { label: "Híbrido", value: "HIB" },
  { label: "Presencial", value: "PRE" },
];

export const IniciarImplantacaoModal: FC<Props> = ({ notifyEvent }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [idEmpresa, setIdEmpresa] = useState(null);
  const [modoImplanta, setModoImplanta] = useState("");
  const [idSistemaAtual, setIdSistemaAtual] = useState(null);
  const [temMigCadProduto, setTemMigCadProduto] = useState(false);
  const [temMigCadCliente, setTemMigCadCliente] = useState(false);
  const [temMigContasPagar, setTemMigContasPagar] = useState(false);
  const [temMigContasReceber, setTemMigContasReceber] = useState(false);
  const [temMigOs, setTemMigOs] = useState(false);
  const [temMigVendas, setTemMigVendas] = useState(false);
  const [loading, setLoading] = useState(false);

  const limparDados = () => {
    setIdEmpresa(null);
    setModoImplanta("");
    setIdSistemaAtual(null);
    setTemMigCadProduto(false);
    setTemMigCadCliente(false);
    setTemMigContasPagar(false);
    setTemMigContasReceber(false);
    setTemMigOs(false);
    setTemMigVendas(false);
  };

  const toggle = () => setIsOpen(!isOpen);

  const handleSubmit = async () => {
    if ([0, null, undefined].includes(idEmpresa)) {
      toastr.warning("Atenção", "Por favor, informe a Empresa");
      return;
    }

    if (["", null, undefined].includes(modoImplanta)) {
      toastr.warning("Atenção", "Por favor, informe o Modo da Implantação");
      return false;
    }

    if ([0, null, undefined].includes(idSistemaAtual)) {
      toastr.warning(
        "Atenção",
        "Por favor, informe o Sistema Atual da Empresa"
      );
      return false;
    }

    const payload = {
      id_empresa: idEmpresa,
      modo_implanta: modoImplanta,
      id_sistema_atual: idSistemaAtual,
      tem_mig_cad_produto: temMigCadProduto,
      tem_mig_cad_cliente: temMigCadCliente,
      tem_mig_contas_pagar: temMigContasPagar,
      tem_mig_contas_receber: temMigContasReceber,
      tem_mig_os: temMigOs,
      tem_mig_vendas: temMigVendas,
    };

    setLoading(true);
    const [ok] = await ImplantacaoService.iniciar(payload);
    if (ok) {
      notifyEvent();
      toggle();
    }
    setLoading(false);
  };

  return (
    <>
      <FormButton color="info" onClick={toggle}>
        Iniciar Implantação
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="md"
        title="Iniciar Implantação"
        number="014_1"
        onClosed={limparDados}
        onConfirm={handleSubmit}
        loadingConfirm={loading}
      >
        <Row>
          <AsyncComboBox
            md={12}
            isConcatField
            concatModelName="empresa"
            label="Empresa"
            isSearchable
            isClearable
            value={idEmpresa}
            onChange={setIdEmpresa}
          />
        </Row>
        <Row>
          <ComboBox
            md={4}
            label="Modo"
            value={modoImplanta}
            options={modoImplantaOptions}
            onChange={setModoImplanta}
          />
          <AsyncComboBox
            md={6}
            label="Sistema Atual"
            concatModelName="sistema_terceiro"
            value={idSistemaAtual}
            onChange={setIdSistemaAtual}
          />
        </Row>
        <Divider>Migrações</Divider>
        <Row>
          <FormCheckbox
            padded={false}
            label="Cadastro de Produto"
            checked={temMigCadProduto}
            onChange={setTemMigCadProduto}
            className="fb-2"
          />
        </Row>
        <Row>
          <FormCheckbox
            padded={false}
            label="Cadastro de Cliente"
            checked={temMigCadCliente}
            onChange={setTemMigCadCliente}
            className="fb-2"
          />
        </Row>
        <Row>
          <FormCheckbox
            padded={false}
            label="Contas a Pagar"
            checked={temMigContasPagar}
            onChange={setTemMigContasPagar}
            className="fb-2"
          />
        </Row>
        <Row>
          <FormCheckbox
            padded={false}
            label="Contas a Receber"
            checked={temMigContasReceber}
            onChange={setTemMigContasReceber}
            className="fb-2"
          />
        </Row>
        <Row>
          <FormCheckbox
            padded={false}
            label="Ordens de Serviço"
            checked={temMigOs}
            onChange={setTemMigOs}
            className="fb-2"
          />
        </Row>
        <Row>
          <FormCheckbox
            padded={false}
            label="Vendas"
            checked={temMigVendas}
            onChange={setTemMigVendas}
            className="fb-2"
          />
        </Row>
      </ModalBase>
    </>
  );
};
