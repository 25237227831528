import { FC, useEffect, useState } from "react";
import { Table } from "../../../../components/Table";
import { TableColumn } from "../../../../components/table/TableRow";
import {
  formatValueFromAPI,
  naturalSort,
  naturalSortDate,
} from "../../../../coreUtils";
import { IconButton } from "../../../../components/IconButton";
import { BsFileText } from "react-icons/bs";
import { statusOptions, tipoDemandaOptions } from "../Desenvolvimento";
import { MoreDropdown } from "../../../../components/MoreDropdown";
import { DropdownItem } from "reactstrap";

interface Props {
  dados: any;
  alterarDemandaGrade: (coluna: string, novoValor: any, row: any) => void;
  atualizarDemandaGrade: (row: any, coluna: string) => void;
  alterar: (id: any) => void;
  excluir: (id: any) => void;
  loading: boolean;
}

const coresStatus: any = {
  APR: "#ad44fa",
  ABE: "#96e3a5",
  AND: "#41bec2",
  RES: "#008040",
  SUP: "#e52b50",
  BKL: "orange",
  ENV: "fuchsia",
};

const cellStyle = { fontSize: "0.84rem" };

export const DesenvolvimentoGrid: FC<Props> = ({
  dados,
  alterarDemandaGrade,
  atualizarDemandaGrade,
  alterar,
  excluir,
  loading,
}) => {
  const [lastEditedIds, setLastEditedIds] = useState<any>([]);

  const onChangeInternal = (coluna: any, novoValor: any, row: any) => {
    setLastEditedIds([...lastEditedIds, row["id"]]);
    alterarDemandaGrade(coluna, novoValor, row);
  };

  useEffect(() => {
    if (!loading) {
      setLastEditedIds([]);
    }
  }, [loading]);

  const columns: TableColumn[] = [
    {
      dataField: "id",
      text: "#",
      align: "center",
      style: cellStyle,
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "tipo",
      text: "Tipo",
      align: "center",
      formatter: (c, row) => row.desc_tipo,
      editable: true,
      alwaysShowEditor: true,
      editorType: "combobox",
      options: tipoDemandaOptions,
      onChange: onChangeInternal,
      fixedColWidth: true,
      colWidth: "220px",
      sortable: true,
      style: cellStyle,
    },
    {
      dataField: "titulo",
      text: "Título",
      align: "left",
      editable: true,
      alwaysShowEditor: true,
      onChange: onChangeInternal,
      onBlur: (row: any) => atualizarDemandaGrade(row, "titulo"),
      sortable: true,
      style: cellStyle,
    },
    {
      dataField: "data_inclusao",
      text: "Inclusão",
      align: "center",
      sortable: true,
      sortFunc: naturalSortDate,
      style: cellStyle,
    },
    {
      dataField: "status",
      text: "Status",
      align: "left",
      formatter: (c, row) => row.desc_status,
      editable: true,
      alwaysShowEditor: true,
      editorType: "combobox",
      optionsRenderer: (opt) => (
        <span style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              width: "10px",
              height: "10px",
              borderRadius: "50%",
              marginLeft: 0,
              marginRight: "0.4rem",
              backgroundColor: coresStatus[opt.value],
            }}
          />
          {opt.label}
        </span>
      ),
      options: statusOptions,
      onChange: onChangeInternal,
      fixedColWidth: true,
      colWidth: "180px",
      sortable: true,
      style: cellStyle,
    },
    {
      dataField: "nome_colab_atrib",
      text: "Atribuído",
      align: "left",
      sortable: true,
      style: cellStyle,
    },
    {
      dataField: "nro_tela",
      text: "Tela",
      align: "center",
      formatter: (c) => (c === "SEMT" ? "Sem Tela" : c),
      sortable: true,
      style: cellStyle,
    },
    {
      dataField: "nome_cliente",
      text: "Cliente",
      align: "left",
      formatter: (c: any, row: any) =>
        formatValueFromAPI(c, row.id_cliente, 30),
      sortable: true,
      style: cellStyle,
    },
    {
      dataField: "icone_plano",
      text: "Plano",
      align: "center",
      formatter: (c) => <img src={c} />,
      sortable: true,
      style: cellStyle,
    },
    {
      dataField: "prioridade",
      text: "P",
      align: "center",
      sortable: true,
      sortFunc: (a, b, order) => {
        const tratarZero = (v: any) => (v === 0 ? Number.POSITIVE_INFINITY : v);
        return naturalSort(tratarZero(a), tratarZero(b), order);
      },
      style: cellStyle,
    },
    {
      dataField: "det",
      text: "",
      align: "center",
      dummy: true,
      selectOnClick: false,
      formatter: (c, row) => {
        const notifNaoLida = row.atu_nao_lidas;
        return (
          <div style={{ position: "relative" }} onClick={() => alterar(row.id)}>
            <IconButton icon={BsFileText} onClick={() => {}} />
            {notifNaoLida > 0 ? (
              <span className="notif-indicator">{notifNaoLida}</span>
            ) : null}
          </div>
        );
      },
    },
    {
      dataField: "act",
      text: "",
      align: "center",
      selectOnClick: false,
      formatter: (c, row) => (
        <MoreDropdown>
          <DropdownItem onClick={() => excluir(row.id)}>Excluir</DropdownItem>
        </MoreDropdown>
      ),
    },
  ];

  return (
    <Table
      data={dados}
      columns={columns}
      paginated={false}
      onRowDoubleClick={alterar}
      rowStyle={(row) => {
        const blocked = lastEditedIds.includes(row.id) && loading;

        return {
          fontSize: "0.9rem",
          opacity: blocked ? 0.5 : undefined,
          userSelect: blocked ? "none" : undefined,
          pointerEvents: blocked ? "none" : undefined,
          cursor: blocked ? "progress" : undefined,
        };
      }}
    />
  );
};
