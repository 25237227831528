import { FC } from "react";
import { PDFReport } from "../../../../../components/pdf/PDFReport";
import { ReportDataList } from "../../../../../components/pdf/ReportDataList";
import { TableCheck } from "../../../../../components/TableCheck";

interface Props {
  dataIni?: any;
  dataFim?: any;
  dados?: any;
}

const columns: any = [
  {
    col: 0.7,
    dataField: "id",
    text: "Licença",
    align: "left",
  },
  {
    col: 2.8,
    dataField: "razao_social",
    text: "Razão Social",
    align: "left",
    formatter: (c: any) => c?.substring(0, 27),
  },
  {
    col: 2.8,
    dataField: "nome_fant",
    text: "Nome Fantasia",
    align: "left",
    formatter: (c: any) => c?.substring(0, 27),
  },
  {
    col: 1.8,
    dataField: "cnpj",
    text: "CNPJ",
    align: "left",
  },
  {
    col: 2,
    dataField: "nome_plano_contratado",
    text: "Plano",
    align: "left",
    // formatter: (c: any, row: any) =>
    //   formatValueFromAPI(c, row.id_plano_contratado),
  },
  {
    col: 1,
    dataField: "data_lim",
    text: "Data Limite",
    align: "center",
  },
  {
    col: 0.9,
    dataField: "ativo",
    text: "Status",
    align: "center",
    formatter: (c: any) => (c ? "Ativo" : "Inativo"),
  },
];

export const CadastralEmpresasPDF: FC<Props> = ({
  dataIni,
  dataFim,
  dados,
}) => {
  return (
    <PDFReport
      dataIni={dataIni}
      dataFim={dataFim}
      title="Cadastral de Empresas"
      number="501_1"
    >
      <ReportDataList data={dados} columns={columns} />
    </PDFReport>
  );
};
