import { RouteType } from "..";
import { MenuGroup } from "../../components/MenuGroup";
import { InteracoesLead } from "../../pages/comercial/cadastros/lead/interacao/InteracoesLead";
import { Lead } from "../../pages/comercial/cadastros/lead/Lead";
import { OrigemCliente } from "../../pages/comercial/cadastros/origem_cliente/OrigemCliente";
import { RiUserReceivedLine, RiUserStarLine } from "react-icons/ri";

export const cadastroLeadRoute: RouteType = {
  id: "AcSk011",
  path: "/comercial/cadastros/lead/",
  name: "Lead",
  component: Lead,
  icon: RiUserStarLine,
};

export const interacoesLeadRoute: RouteType = {
  id: "AcSk011",
  path: "/comercial/cadastros/lead/interacoes/",
  name: "Interações de Lead",
  component: InteracoesLead,
};

export const comercialCadastrosRoute: RouteType = {
  name: "Cadastros",
  path: "/comercial/cadastros/",
  children: [
    cadastroLeadRoute,
    {
      id: "AcSk016",
      path: "/comercial/cadastros/origem_cliente/",
      name: "Origem de Cliente",
      component: OrigemCliente,
      icon: RiUserReceivedLine,
    },
  ],
};

export const comercialRoutes: RouteType = {
  name: "Comercial",
  path: "/comercial/",
  component: () =>
    MenuGroup({
      routes: [comercialCadastrosRoute],
      title: "Comercial",
    }),
};
