import React, { FC, ReactNode } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";

interface Props {
  children: ReactNode;
}

const Wrapper: FC<Props> = ({ children }) => {
  const layout = useSelector((state: RootState) => state.layout);
  return (
    <div className={"wrapper " + (layout.isBoxed ? "wrapper-boxed" : "")}>
      {children}
    </div>
  );
};

export default Wrapper;
