import { FC, useEffect, useState } from "react";
import ImplantacaoService from "../../../../services/ImplantacaoService";
import { useSelector } from "react-redux";
import { AtualizacoesForm } from "../../../../components/AtualizacoesForm";

interface Props {
  setor: string;
  abaAtiva: boolean;
  dados: Array<any>;
  setDados: (d: any) => void;
}

export const AtualizacoesSetorForm: FC<Props> = ({
  setor,
  abaAtiva,
  dados,
  setDados,
}) => {
  const store = useSelector((state: any) => state.detalhesImplant);
  const selected = store.selected;
  const [loading, setLoading] = useState(true);

  const carregarDados = async () => {
    const params = { setor: setor };
    const [ok, ret] = await ImplantacaoService.atualizacao.listar(
      selected,
      params
    );
    setDados(ok ? ret : []);
    return ok;
  };

  const enviarAtu = async (conteudo: any, mencoes: any) => {
    const payload = {
      id_cab: selected,
      conteudo: conteudo,
      setor: setor,
      id_colab_mencionados: mencoes,
    };
    const [ok] = await ImplantacaoService.atualizacao.incluir(payload);
    if (ok) {
      carregarDados();
    }
    return ok;
  };

  const excluirAtu = async (idAtu: any) => {
    const [ok] = await ImplantacaoService.atualizacao.excluir(idAtu);
    if (ok) {
      carregarDados();
    }
  };

  const carregarAoAtivar = async () => {
    await carregarDados();
    if (store.id_atualiza_mostrar) {
      const atu = document.getElementById(`atu-${store.id_atualiza_mostrar}`);
      if (atu) {
        atu.scrollIntoView();
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (abaAtiva) {
      carregarAoAtivar();
    }
  }, [abaAtiva]);

  return (
    <AtualizacoesForm
      data={dados}
      handleSubmit={enviarAtu}
      loading={loading}
      handleDelete={excluirAtu}
      idHighlight={store.id_atualiza_mostrar}
    />
  );
};
