import React, {
  FC,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import CurrencyInput from "react-currency-input-field";
import { handleFocus } from "../../coreUtils";

interface Props {
  onChange?: any;
  style?: any;
  defaultValue?: any;
  value?: any;
  isPercentage?: any;
  onBlur?: any;
  decimalPlaces?: any;
  size?: any;
  allowNegativeValue?: any;
  id?: any;
  disableGroupSeparators?: any;
  autoFocus?: any;
  name?: any;
}

const TableNumberInput: FC<Props> = forwardRef(
  (
    {
      onChange,
      style,
      defaultValue,
      value,
      isPercentage = false,
      onBlur,
      decimalPlaces = 2,
      size,
      allowNegativeValue = false,
      id,
      disableGroupSeparators = true,
      autoFocus,
      name,
    },
    ref
  ) => {
    const [inputValue, setInputValue] = useState(defaultValue ?? null);
    const [callOnBlur, setCallOnBlur] = useState(false);
    const [invalid, setInvalid] = useState(false);
    const [onFocus, setOnFocus] = useState(false);
    const [internalId] = useState(
      id ?? "ni-" + Math.floor(Math.random() * Date.now())
    );
    const inputRef = useRef<any>();

    const preparaValor = (v: any) =>
      typeof v === "string"
        ? parseFloat((v || "0.0").replace(".", "").replace(",", "."))
        : v;

    useEffect(() => {
      if (defaultValue) setInputValue(defaultValue);
    }, []);

    useEffect(() => {
      if (value !== preparaValor(inputValue) && !onFocus) {
        setInputValue(value);
      }
    }, [value, onFocus]);

    const handleOnChange = (v: any) => {
      setInputValue(v);
      setInvalid(false);
      if (onChange && onFocus) {
        setCallOnBlur(true);
        onChange(preparaValor(v ?? 0));
      }
    };

    const onFocusInternal = (e: React.FocusEvent<HTMLInputElement>) => {
      setOnFocus(true);
      e.target.select();
    };

    const onBlurInternal = (e: React.FocusEvent<HTMLInputElement>) => {
      setOnFocus(false);
      if (onBlur && callOnBlur) {
        onBlur(e, preparaValor(e.target.value ?? 0));
      }
      setCallOnBlur(false);
    };

    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      handleFocus(e);
    };

    useImperativeHandle(ref, () => ({
      floatValue: () => preparaValor(inputValue),
      clear: () => {
        setInputValue(null);
      },
      focus: () => inputRef.current.focus(),
    }));

    return (
      <CurrencyInput
        autoFocus={autoFocus}
        className="table-input"
        intlConfig={{ locale: "pt-BR" }}
        style={style}
        size={size}
        onValueChange={handleOnChange}
        onBlur={onBlurInternal}
        onFocus={onFocusInternal}
        value={inputValue}
        // invalid={invalid}
        defaultValue={defaultValue}
        decimalsLimit={decimalPlaces}
        suffix={isPercentage ? " %" : ""}
        decimalScale={decimalPlaces}
        disableAbbreviations
        disableGroupSeparators={disableGroupSeparators}
        allowNegativeValue={allowNegativeValue}
        onKeyDown={onKeyDown}
        ref={inputRef}
        id={internalId}
        name={name}
      />
    );
  }
);

export default TableNumberInput;
