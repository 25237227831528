import { Dispatch, FC, useEffect } from "react";
import settings from "../settings";
import { isDevEnv } from "../coreUtils";
import useWebSocket from "react-use-websocket";
import logo from "../assets/img/logo/skillsoft_logo.png";
import { useDispatch, useSelector } from "react-redux";
import {
  addNotificacao,
  lerNotificacao,
  setNotificacoes,
} from "../redux/reducers/notificacoesReducer";
import NotificacoesService from "../services/NotificacoesService";
import { History, Location } from "history";
import { useHistory, useLocation } from "react-router-dom";
import { RouteType } from "../routes";
import {
  cadastroLeadRoute,
  interacoesLeadRoute,
} from "../routes/modules/comercial";
import { incluirAlterarDemandaDevRoute } from "../routes/modules/desenvolvimento";
import { implantacaoRoute } from "../routes/modules/implantacao";

const wsNotifAddress = isDevEnv()
  ? settings.WS_NOTIF_ADDRESS_DEV
  : settings.WS_NOTIF_ADDRESS;

const goToScreen = (
  history: History,
  location: Location,
  route: RouteType,
  params: Record<string, string> | null = null,
  state: any = null
) => {
  let paramsUrl = "";
  if (params) {
    paramsUrl = "?" + new URLSearchParams(params).toString();
  }

  let histFunc = history.push;
  if (location.pathname === route.path) {
    histFunc = history.replace;
  }

  histFunc(route.path + paramsUrl, state);
  window.focus();
};

export const onClickNotification = (
  dispatch: Dispatch<any>,
  history: History,
  location: Location,
  notif: any
) => {
  const origem = notif.origem;
  const idOrigem = notif.id_origem;

  if (origem === "IMPME") {
    const resumoJson = JSON.parse(notif.resumo);

    const params = {
      action: "MEN_ATU",
      id: resumoJson.id_implant,
      id_atualiza: idOrigem,
      setor: resumoJson.setor,
    };
    goToScreen(history, location, implantacaoRoute, params);
  } else if (origem === "DEVME") {
    const resumoJson = JSON.parse(notif.resumo);

    const params = {
      id: resumoJson.id_demanda,
      id_atualiza: idOrigem,
    };

    goToScreen(history, location, incluirAlterarDemandaDevRoute, params);
  } else if (["LEAVI", "LEAPR"].includes(origem)) {
    const resumoJson = JSON.parse(notif.resumo);
    const params = {
      action: "MEN_INT",
      id_lead: resumoJson.id_lead,
    };

    goToScreen(history, location, cadastroLeadRoute, params);
  } else if (origem === "LEAME") {
    const resumoJson = JSON.parse(notif.resumo);
    const params = {
      id_lead: resumoJson.id_lead,
      id_interacao: idOrigem,
    };
    goToScreen(history, location, interacoesLeadRoute, params);
  }
  dispatch(lerNotificacao(notif.origem, notif.id_origem));
};

export const formatarResumoNotif = (notif: any) => {
  let resumo = notif.resumo;

  const origem = notif.origem;
  if (origem === "IMPME") {
    let resumoJson = JSON.parse(notif.resumo);
    resumo = `${resumoJson.nome_autor} mencionou você: ${resumoJson.resumo}`;
  } else if (origem === "DEVME") {
    let resumoJson = JSON.parse(notif.resumo);
    resumo = `${resumoJson.nome_autor} mencionou você: ${resumoJson.resumo}`;
  } else if (["LEAME", "LEAVI", "LEAPR"].includes(origem)) {
    let resumoJson = JSON.parse(notif.resumo);
    resumo = `${resumoJson.nome_autor} mencionou você: ${resumoJson.resumo}`;
  }

  return resumo;
};

export const NotificationManager: FC = () => {
  const idUsuario = parseInt(localStorage.getItem("id_colaborador") ?? "0");
  const socketUrl = `${wsNotifAddress}/notificacao/nova/${idUsuario}/`;
  const { lastJsonMessage } = useWebSocket(socketUrl);
  const dispatch = useDispatch();
  const store = useSelector((state: any) => state.notificacoes);
  const history = useHistory();
  const location = useLocation();

  const mostrarNotificacao = (notif: any) => {
    if (Notification.permission === "granted") {
      const notification = new Notification(notif.titulo, {
        body: formatarResumoNotif(notif),
        icon: logo,
      });

      notification.addEventListener("click", () =>
        onClickNotification(dispatch, history, location, notif)
      );
    }
    dispatch(addNotificacao({ ...notif, lida: false }));
  };

  const buscarNotificacoes = async () => {
    const params = {
      mostrar_nao_lidas: store.mostrarNaoLidas,
    };

    const [ok, ret] = await NotificacoesService.buscarNotifUsuario(params);
    dispatch(setNotificacoes(ok ? ret : []));
  };

  useEffect(() => {
    if (lastJsonMessage) {
      mostrarNotificacao(lastJsonMessage);
    }
  }, [lastJsonMessage]);

  useEffect(() => {
    if (Notification.permission === "default") {
      Notification.requestPermission();
    }

    buscarNotificacoes();
  }, []);

  useEffect(() => {
    if (store.mostrarNaoLidas === true) {
      buscarNotificacoes();
    } else {
      dispatch(
        setNotificacoes(store.notificacoes.filter((e: any) => e.lida === false))
      );
    }
  }, [store.mostrarNaoLidas]);

  return <></>;
};
