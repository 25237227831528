import { FC, useState } from "react";
import { Row } from "reactstrap";
import { authGet, authPut } from "../../../../../api";
import {
  AsyncComboBox,
  FormButton,
  FormCheckbox,
  IntegerInput,
  TextInput,
} from "../../../../../components";
import { FixedField } from "../../../../../components/FixedField";
import {
  ModalActions,
  ModalCadastroProps,
  ModalCadastroV2,
} from "../../../../../components/cadastro/ModalCadastroV2";
import { modalTitleUsuario, routesBaseUsuario } from "../Usuarios";
import { routesBaseEmpresa } from "../../empresa/Empresa";
import { APIs } from "../../../../../apiV2";

interface Props {
  isOpen: ModalCadastroProps["isOpen"];
  toggle: ModalCadastroProps["toggle"];
  action: ModalCadastroProps["action"];
  selected: any;
  notifyEvent: ModalCadastroProps["notifyEvent"];
}

export const CadastroUsuariosModal: FC<Props> = ({
  isOpen,
  toggle,
  action,
  selected,
  notifyEvent,
}) => {
  const [ativo, setAtivo] = useState(false);
  const [licenca, setLicenca] = useState(null);
  const [colaborador, setColaborador] = useState(null);
  const [nickname, setNickname] = useState("");
  const [siglaEmpresa, setSiglaEmpresa] = useState("@empresa");
  const [appVendaDirContratado, setAppVendaDirContratado] = useState(false);
  const [appPedVdaContratado, setAppPedVdaContratado] = useState(false);
  const [appGerencialContratado, setAppGerencialContratado] = useState(false);
  const [sistemaOpenContratado, setSistemaOpenContratado] = useState(false);
  const [password, setPassword] = useState("");
  const [adminSkill, setAdminSkill] = useState(false);
  const [resetarSenhaAoLogar, setResetarSenhaAoLogar] = useState(false);
  const [dataLimite, setDataLimite] = useState(null);
  const [master, setMaster] = useState(false);
  const [temAcessoAppVendaDireta, setTemAcessoAppVendaDireta] = useState(false);
  const [temAcessoAppPedidoVda, setTemAcessoAppPedidoVda] = useState(false);
  const [temAcessoWeb, setTemAcessoWeb] = useState(false);
  const [temAcessoAppGerencial, setTemAcessoAppGerencial] = useState(false);
  const [temAcessoWebOpen, setTemAcessoWebOpen] = useState(false);
  const [loadingResetPassword, setLoadingResetPassword] = useState(false);

  const limparDados = () => {
    setAtivo(true);
    setLicenca(null);
    setColaborador(null);
    setNickname("");
    setSiglaEmpresa("");
    setAppVendaDirContratado(false);
    setAppPedVdaContratado(false);
    setAppGerencialContratado(false);
    setSistemaOpenContratado(false);
    setPassword("");
    setAdminSkill(false);
    setResetarSenhaAoLogar(false);
    setDataLimite(null);
    setMaster(false);
    setTemAcessoAppVendaDireta(false);
    setTemAcessoAppPedidoVda(false);
    setTemAcessoWeb(false);
    setTemAcessoAppGerencial(false);
    setTemAcessoWebOpen(false);
  };

  const fetchData = async (ret: any) => {
    setLicenca(ret.empresa);
    setColaborador(ret.colaborador);
    setNickname(ret.nickname);
    setAtivo(ret.ativo);
    setAdminSkill(ret.admin_skill);
    setResetarSenhaAoLogar(ret.resetar_senha_ao_logar);
    setDataLimite(ret.data_limite);
    setSiglaEmpresa(ret.empresa__sigla_user);
    setAppVendaDirContratado(ret.empresa__app_venda_dir_contratado);
    setAppPedVdaContratado(ret.empresa__app_ped_vda_contratado);
    setAppGerencialContratado(ret.empresa__app_gerencial_contratado);
    setSistemaOpenContratado(ret.empresa__sistema_open_contratado);
    setMaster(ret.master);
    setTemAcessoAppVendaDireta(ret.tem_acesso_app_venda_direta);
    setTemAcessoAppPedidoVda(ret.tem_acesso_app_pedido_vda);
    setTemAcessoWeb(ret.tem_acesso_web);
    setTemAcessoAppGerencial(ret.tem_acesso_app_gerencial);
    setTemAcessoWebOpen(ret.tem_acesso_web_open);
  };

  const buscarSiglaEmpresa = async (v: any) => {
    const ret = await authGet(
      `${routesBaseEmpresa}/buscar_info_cad_usuario/${v}/`
    );
    setSiglaEmpresa(ret.sigla_user);
    setAppVendaDirContratado(ret.app_venda_dir_contratado);
    setAppPedVdaContratado(ret.app_ped_vda_contratado);
    setAppGerencialContratado(ret.app_gerencial_contratado);
    setSistemaOpenContratado(ret.sistema_open_contratado);
  };

  const resetarSenha = async () => {
    setLoadingResetPassword(true);
    await authPut(`${routesBaseUsuario}/resetar_senha/`, {
      id_usuario: selected,
    });
    setLoadingResetPassword(false);
  };

  const submitPayload = (action: any) => {
    const payload = {
      licenca: licenca,
      nickname: nickname,
      master: master,
      tem_acesso_app_venda_direta: temAcessoAppVendaDireta,
      tem_acesso_app_pedido_vda: temAcessoAppPedidoVda,
      tem_acesso_web: temAcessoWeb,
      tem_acesso_app_gerencial: temAcessoAppGerencial,
      tem_acesso_web_open: temAcessoWebOpen,
    };

    return action === ModalActions.add
      ? {
          ...payload,
          password: password,
          id_colaborador: colaborador, // Colocar o mesmo nome na inclusão e alteração
        }
      : {
          ...payload,
          id_usuario: selected,
          id_colaborador: colaborador, // Colocar o mesmo nome na inclusão e alteração
          ativo: ativo,
        };
  };

  const handleSetEmpresa = (v: any) => {
    if (v === licenca) return false;
    setLicenca(v);
    setAppVendaDirContratado(false);
    setAppPedVdaContratado(false);
    setAppGerencialContratado(false);
    setSistemaOpenContratado(false);
    setTemAcessoAppVendaDireta(false);
    setTemAcessoAppPedidoVda(false);
    setTemAcessoAppGerencial(false);
    setTemAcessoWebOpen(false);
    buscarSiglaEmpresa(v);
  };

  return (
    <ModalCadastroV2
      isOpen={isOpen}
      toggle={toggle}
      action={action}
      title={modalTitleUsuario}
      size="md"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      headerCheck={{
        value: ativo,
        toggle: () => setAtivo(!ativo),
      }}
      routesBase={routesBaseUsuario}
      number="002_1"
      selected={selected}
      notifyEvent={notifyEvent}
      useApi={APIs.auth}
    >
      <Row>
        <AsyncComboBox
          md={8}
          isConcatField
          concatModelName="empresa"
          defaultOptions
          label="Empresa"
          isSearchable
          onChange={handleSetEmpresa}
          value={licenca}
          autoFocus
        />
        <IntegerInput
          md={2}
          label="Colaborador"
          value={colaborador}
          onChange={setColaborador}
        />
      </Row>
      <Row>
        <TextInput
          label="Nickname"
          md={8}
          onChange={(v: any) => setNickname(v?.replace(/\W/gi, ""))}
          value={nickname}
          upperCaseValue={false}
          updateEveryRender
          addonText={siglaEmpresa}
          addonType="append"
        />
      </Row>
      {action === ModalActions.add ? (
        <></>
      ) : (
        <>
          <Row>
            <FixedField
              label="Admin Skill"
              value={adminSkill ? "Sim" : "Não"}
            />
            <FixedField
              label="Resetar senha ao logar"
              value={resetarSenhaAoLogar ? "Sim" : "Não"}
            />
            <FixedField label="Data Limite" value={dataLimite} />
          </Row>
          <Row>
            <FormButton
              color="warning"
              md="auto"
              onClick={resetarSenha}
              padded={false}
              loading={loadingResetPassword}
            >
              Resetar Senha
            </FormButton>
          </Row>
        </>
      )}
      <Row>
        <FormCheckbox
          label="Usuário Master"
          checked={master}
          onChange={setMaster}
        />
      </Row>
      <Row>
        <FormCheckbox
          label="Acesso ao Sistema Web"
          checked={temAcessoWeb}
          onChange={setTemAcessoWeb}
        />
        <FormCheckbox
          label="Acesso ao APP Venda Direta"
          checked={temAcessoAppVendaDireta}
          onChange={setTemAcessoAppVendaDireta}
          disabled={!appVendaDirContratado}
        />
        <FormCheckbox
          label="Acesso ao APP de Pedidos"
          checked={temAcessoAppPedidoVda}
          onChange={setTemAcessoAppPedidoVda}
          disabled={!appPedVdaContratado}
        />
      </Row>
      <Row>
        <FormCheckbox
          label="Acesso ao APP Gerencial"
          checked={temAcessoAppGerencial}
          onChange={setTemAcessoAppGerencial}
          disabled={!appGerencialContratado}
        />
        <FormCheckbox
          label="Acesso ao Web OPEN"
          checked={temAcessoWebOpen}
          onChange={setTemAcessoWebOpen}
          disabled={!sistemaOpenContratado}
        />
      </Row>
    </ModalCadastroV2>
  );
};
