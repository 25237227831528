import { FC } from "react";
import { Table } from "../../../../../../components/Table";
import { TableColumn } from "../../../../../../components/table/TableRow";
import { formatValueFromAPI } from "../../../../../../coreUtils";
import { TableDelete } from "../../../../../../components/TableDelete";

interface Props {
  dados: any[];
  excluir: (id: any) => void;
}

export const ExcecaoGrid: FC<Props> = ({ dados, excluir }) => {
  const columns: TableColumn[] = [
    {
      dataField: "nome_empresa",
      text: "Empresa",
      align: "left",
      formatter: (c: any, row: any) => formatValueFromAPI(c, row.id_licenca),
      sortable: true,
    },
    { dataField: "cnpj_empresa", text: "CNPJ", align: "left" },
    {
      dataField: "desc_menu",
      text: "Tela",
      align: "left",
      formatter: (c, row) => `${c} [${row.nro_tela_menu}]`,
      sortable: true,
    },
    { dataField: "modulo_menu", text: "Módulo", align: "left", sortable: true },
    {
      dataField: "plano_menu",
      text: "Plano do Menu",
      align: "left",
      sortable: true,
      formatter: (c, row) => (
        <>
          <img
            src={row.icone_plano_menu}
            style={{ width: "16px", height: "16px", marginRight: "2px" }}
          />
          {c}
        </>
      ),
    },
    { dataField: "observ", text: "Obs.", align: "left" },
    {
      dataField: "del",
      text: "",
      align: "center",
      dummy: true,
      formatter: (c, row) => <TableDelete onClick={() => excluir(row.id)} />,
    },
  ];

  return <Table data={dados} columns={columns} />;
};
