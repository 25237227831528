import {
  FC,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Search } from "react-feather";
import {
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Spinner,
} from "reactstrap";

interface Props {
  md?: any;
  type?: any;
  name?: any;
  onChange?: any;
  value?: any;
  className?: any;
  loading?: any;
  placeholder?: any;
  divClassName?: any;
  hideLabel?: any;
  autoFocus?: any;
  onKeyDown?: any;
  ref?: any;
}

const SearchInput: FC<Props> = forwardRef(
  (
    {
      md,
      type = "text",
      name,
      onChange,
      value,
      className,
      loading,
      placeholder = "Pesquisar",
      divClassName,
      hideLabel,
      autoFocus,
      onKeyDown,
    },
    ref
  ) => {
    const [internalValue, setInternalValue] = useState("");
    const inputRef = useRef<any>("");

    useEffect(() => setInternalValue(value), [value]);

    const handleOnChange = (e: any) => {
      e.preventDefault();
      const inputValue = e.target.value || "";
      setInternalValue(inputValue);
      if (onChange) {
        onChange(inputValue);
      }
    };

    useImperativeHandle(ref, () => ({
      focus: () =>
        setTimeout(() => inputRef.current && inputRef.current.focus(), 35),
      value: internalValue?.trim(),
      setValue: (val: any) => setInternalValue(val),
    }));

    return (
      <Col md={md} className={divClassName}>
        {!hideLabel && <Label>Pesquisa</Label>}
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              {loading ? <Spinner size="sm" /> : <Search size={15} />}
            </InputGroupText>
          </InputGroupAddon>
          <Input
            type={type}
            name={name}
            onChange={handleOnChange}
            defaultValue={value}
            innerRef={inputRef}
            className={className}
            value={internalValue}
            placeholder={placeholder}
            autoFocus={autoFocus}
            onKeyDown={onKeyDown}
          />
        </InputGroup>
      </Col>
    );
  }
);

export default SearchInput;
