import { FC, useState } from "react";
import { ModalBase } from "../../../../../components/ModalBase";
import ComboBox, { ComboBoxOption } from "../../../../../components/ComboBox";
import { Row } from "reactstrap";
import { TextInput } from "../../../../../components";
import { DatePicker } from "../../../../../components/DatePicker";
import { toastr } from "react-redux-toastr";
import LeadService from "../../../../../services/components/comercial/LeadService";
import { formatDateISO } from "../../../../../coreUtils";

interface Props {
  isOpen: boolean;
  toggle: () => void;
  selected: any;
  notifyEvent: () => void;
}

const motivosNaoConquista: ComboBoxOption[] = [
  { label: "Está sendo bem atendido pelo sistema atual", value: "RBA" },
  { label: "Sem interesse e não possui sistema", value: "RSS" },
  { label: "Sem interesse, não informou motivo", value: "RSN" },
  { label: "Pouco interesse no momento, porém, deu margem", value: "RDM" },
  { label: "Lead encerrado, empresa não existe/fechou", value: "ENC" },
  {
    label: "Sistema Skill não tem funcionalidade importante para atender",
    value: "FUN",
  },
  { label: "Achou o valor caro", value: "VLC" },
];

export const NaoConquistaLeadModal: FC<Props> = ({
  isOpen,
  toggle,
  selected,
  notifyEvent,
}) => {
  const [motivoNaoConq, setMotivoNaoConq] = useState(null);
  const [sistAtual, setSistAtual] = useState("");
  const [observ, setObserv] = useState("");
  const [dataRetomada, setDataRetomada] = useState<any>(
    new Date(new Date().setFullYear(new Date().getFullYear() + 1))
  );
  const [loading, setLoading] = useState(false);

  const limparDados = () => {
    setMotivoNaoConq(null);
    setSistAtual("");
    setObserv("");
    setDataRetomada(
      new Date(new Date().setFullYear(new Date().getFullYear() + 1))
    );
  };

  const handleSetMotivoNaoConq = (v: any) => {
    setMotivoNaoConq(v);
    if (v === "ENC") {
      setSistAtual("");
      setDataRetomada(null);
    }
  };

  const handleSubmit = async () => {
    if (!motivoNaoConq) {
      toastr.warning("Atenção", "Por favor, informe o Motivo da Não Conquista");
      return false;
    }

    if (!["RSS", "ENC"].includes(motivoNaoConq) && sistAtual === "") {
      toastr.warning("Atenção", "Por favor, informe o Sistema Atual do Lead");
      return false;
    }

    if (motivoNaoConq !== "ENC" && !(dataRetomada instanceof Date)) {
      toastr.warning("Atenção", "Por favor, informe a Data de Retomada");
      return false;
    }

    const payload = {
      id: selected,
      motivo_nao_conq: motivoNaoConq,
      sist_atual: sistAtual,
      observ: observ,
      data_retomada: formatDateISO(dataRetomada),
    };

    setLoading(true);
    const [ok] = await LeadService.interacao.registrarNaoConquista(payload);
    if (ok) {
      toggle();
      notifyEvent();
    }
    setLoading(false);
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      title="Não Conquista de Lead"
      onConfirm={handleSubmit}
      loadingConfirm={loading}
      onClosed={limparDados}
      number="011_5"
    >
      <Row>
        <ComboBox
          md={10}
          label="Motivo"
          options={motivosNaoConquista}
          value={motivoNaoConq}
          onChange={handleSetMotivoNaoConq}
        />
      </Row>
      <Row>
        <TextInput
          md={6}
          label="Sistema Atual"
          value={sistAtual}
          onChange={setSistAtual}
          disabled={motivoNaoConq === "ENC"}
        />
        <DatePicker
          md={4}
          label="Data da Retomada"
          value={dataRetomada}
          onChange={setDataRetomada}
          disabled={motivoNaoConq === "ENC"}
        />
      </Row>
      <Row>
        <TextInput
          md={12}
          type="textarea"
          label="Observação"
          value={observ}
          onChange={setObserv}
          rows={3}
          upperCaseValue={false}
        />
      </Row>
    </ModalBase>
  );
};
