import { FC } from "react";
import ReactQuill from "react-quill";

const editorModules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
const editorFormats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "align",
  "link",
  "image",
  "code",
];

interface Props {
  value?: any;
  onChange?: any;
  className?: string;
}

export const MarkdownInput: FC<Props> = ({ value, onChange, className }) => {
  return (
    <ReactQuill
      modules={editorModules}
      formats={editorFormats}
      value={value}
      onChange={onChange}
      className={className}
    />
  );
};
