import { FC } from "react";
import { ArrowLeft } from "react-feather";

interface Props {
  children: any;
  onGoingBack?: () => void;
}

const ScreenTitle: FC<Props> = ({ children, onGoingBack }) => {
  return (
    <h5
      className="h5 mb-0 mr-2"
      style={{ fontWeight: "bold", fontSize: "0.95rem" }}
    >
      {onGoingBack && (
        <>
          <ArrowLeft
            size={15}
            style={{ marginBottom: "3.55px", cursor: "pointer" }}
            onClick={onGoingBack}
          />{" "}
        </>
      )}
      {children}
    </h5>
  );
};

export default ScreenTitle;
