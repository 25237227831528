import React from "react";
import { Col } from "reactstrap";

const TableRecordCount = ({ dataLength, theme }) => {
  return (
    <Col sm={3} md={3} xs={3} lg={3} className={`${theme}-table-record-count`}>
      <span>
        {dataLength ?? 0} Registro{(dataLength ?? 0) === 1 ? "" : "s"}
      </span>
    </Col>
  );
};

export default TableRecordCount;
