import { FC } from "react";
import { useState } from "react";
import {
  modalTitleContabilidade,
  routesBaseContabilidade,
} from "../Contabilidade";
import {
  ModalActions,
  ModalCadastroProps,
  ModalCadastroV2,
} from "../../../../../components/cadastro/ModalCadastroV2";
import { Row } from "reactstrap";
import {
  AsyncComboBox,
  PhoneInput,
  TextInput,
} from "../../../../../components";
import MaskedInput from "../../../../../components/MaskedInput";
import { DatePicker } from "../../../../../components/DatePicker";
import { dateFromLocaleString, formatDateISO } from "../../../../../coreUtils";
import { toastr } from "react-redux-toastr";

interface Props {
  isOpen: ModalCadastroProps["isOpen"];
  toggle: ModalCadastroProps["toggle"];
  action: ModalCadastroProps["action"];
  selected: any;
  notifyEvent: ModalCadastroProps["notifyEvent"];
}

export const CadastroContabilidadeModal: FC<Props> = ({
  isOpen,
  toggle,
  action,
  selected,
  notifyEvent,
}) => {
  const [ativo, setAtivo] = useState(true);
  const [nome, setNome] = useState("");
  const [idCidade, setIdCidade] = useState(0);
  const [endereco, setEndereco] = useState("");
  const [nroEndereco, setNroEndereco] = useState("");
  const [bairro, setBairro] = useState("");
  const [complemento, setComplemento] = useState("");
  const [cep, setCep] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [fone1, setFone1] = useState("");
  const [dataFundacao, setDataFundacao] = useState<any>(null);
  const [observ, setObserv] = useState("");
  const [email, setEmail] = useState("");

  const limparDados = () => {
    setAtivo(true);
    setNome("");
    setIdCidade(0);
    setEndereco("");
    setNroEndereco("");
    setBairro("");
    setComplemento("");
    setCep("");
    setWhatsapp("");
    setFone1("");
    setDataFundacao(null);
    setObserv("");
    setEmail("");
  };

  const fetchData = (data: any) => {
    setAtivo(data.ativo);
    setNome(data.nome ?? "");
    setIdCidade(data.id_cidade ?? 0);
    setEndereco(data.endereco ?? "");
    setNroEndereco(data.nro_endereco ?? "");
    setBairro(data.bairro ?? "");
    setComplemento(data.complemento ?? "");
    setCep(data.cep ?? "");
    setWhatsapp(data.whatsapp ?? "");
    setFone1(data.fone1 ?? "");
    setDataFundacao(dateFromLocaleString(data.data_fund ?? null));
    setObserv(data.observ ?? "");
    setEmail(data.email ?? "");
  };

  const submitPayload = (action: any) => {
    if (["", null, undefined].includes(nome)) {
      toastr.warning("Atenção", "Por favor, informe o Nome da Contabilidade");
      return false;
    }

    const payload = {
      ativo: ativo,
      nome: nome,
      id_cidade: idCidade ?? 0,
      endereco: endereco,
      nro_endereco: nroEndereco,
      bairro: bairro,
      complemento: complemento,
      cep: cep,
      whatsapp: whatsapp,
      fone1: fone1,
      data_fundacao:
        dataFundacao instanceof Date
          ? formatDateISO(dataFundacao)
          : dataFundacao,
      observ: observ,
      email: email,
    };

    return action === ModalActions.add ? payload : { id: selected, ...payload };
  };

  return (
    <ModalCadastroV2
      isOpen={isOpen}
      toggle={toggle}
      action={action}
      title={modalTitleContabilidade}
      size="md"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      headerCheck={{
        value: ativo,
        toggle: () => setAtivo(!ativo),
      }}
      routesBase={routesBaseContabilidade}
      number="013_1"
      selected={selected}
      notifyEvent={notifyEvent}
    >
      <Row>
        <TextInput
          label="Nome"
          md={12}
          onChange={setNome}
          value={nome}
          maxLength={120}
          autoFocus
        />
      </Row>
      <Row>
        <TextInput
          label="Endereço"
          md={10}
          onChange={setEndereco}
          value={endereco}
          maxLength={80}
        />
        <TextInput
          label="Número"
          md={2}
          onChange={setNroEndereco}
          value={nroEndereco}
          maxLength={10}
        />
      </Row>
      <Row>
        <TextInput
          label="Complemento"
          md={4}
          onChange={setComplemento}
          value={complemento}
          maxLength={40}
        />
        <TextInput
          label="Bairro"
          md={5}
          onChange={setBairro}
          value={bairro}
          maxLength={40}
        />
      </Row>
      <Row>
        <AsyncComboBox
          md={8}
          isConcatField
          concatModelName="cidade"
          defaultOptions
          label="Cidade"
          isSearchable
          isClearable
          onChange={setIdCidade}
          value={idCidade}
        />
        <MaskedInput
          mask="99999-999"
          name="cep"
          label="CEP"
          md={4}
          onChange={setCep}
          value={cep}
        />
      </Row>
      <Row>
        <PhoneInput
          md={4}
          label="WhatsApp"
          value={whatsapp}
          onChange={setWhatsapp}
        />
        <PhoneInput md={4} label="Fone" value={fone1} onChange={setFone1} />
        <DatePicker
          md={4}
          label="Data de Fundação"
          value={dataFundacao}
          onChange={setDataFundacao}
        />
      </Row>
      <Row>
        <TextInput
          label="E-Mail"
          md={10}
          onChange={setEmail}
          value={email}
          maxLength={80}
        />
      </Row>
      <Row>
        <TextInput
          label="Observação"
          md={12}
          onChange={setObserv}
          value={observ}
          type="textarea"
          rows={2}
        />
      </Row>
    </ModalCadastroV2>
  );
};
