import { apiDeleteV2, apiGetV2, apiPostV2, apiPutV2 } from "../apiV2";

const URL_BASE = "/demanda_dev";
const URL_BASE_ATUALIZACAO = `${URL_BASE}/atualizacao`;
const URL_BASE_CLI_COBR = `${URL_BASE}/cli_cobr`;

const DesenvolvimentoService = {
  routesBase: URL_BASE,
  alterar: (payload) => apiPutV2(`${URL_BASE}/alterar/`, payload),
  alterarItem: (payload) =>
    apiPutV2(`${URL_BASE}/alterar_item/`, payload, { successMessage: false }),
  buscar: (idDemanda) => apiGetV2(`${URL_BASE}/buscar/${idDemanda}/`),
  buscarImpressao: (idDemanda) =>
    apiGetV2(`${URL_BASE}/buscar_impressao/${idDemanda}/`),
  incluir: (payload) => apiPostV2(`${URL_BASE}/incluir/`, payload),
  listar: (params) => apiGetV2(`${URL_BASE}/listar/`, params),
  atualizacao: {
    excluir: (idAtu) =>
      apiDeleteV2(`${URL_BASE_ATUALIZACAO}/excluir/${idAtu}/`),
    incluir: (payload) =>
      apiPostV2(`${URL_BASE_ATUALIZACAO}/incluir/`, payload),
    listar: (idDemanda) =>
      apiGetV2(`${URL_BASE_ATUALIZACAO}/listar/${idDemanda}/`),
  },
  cliCobr: {
    incluir: (payload) => apiPostV2(`${URL_BASE_CLI_COBR}/incluir/`, payload),
    listar: (idDemanda, params) =>
      apiGetV2(`${URL_BASE_CLI_COBR}/listar/${idDemanda}/`, params),
  },
};

export default DesenvolvimentoService;
