import { FC } from "react";
import { Trash2 } from "react-feather";
import { IconButton } from "./IconButton";

interface Props {
  onClick?: any;
  disabled?: any;
}

export const TableDelete: FC<Props> = ({ onClick, disabled }) => (
  <IconButton
    icon={Trash2}
    color="#db3d3d"
    onClick={onClick}
    disabled={disabled}
  />
);
