import moment from "moment";
import { FC, useEffect, useRef, useState } from "react";
import DateTime from "react-datetime";
import InputMask from "react-input-mask";
import { Col, FormGroup, Label } from "reactstrap";
import { handleFocus } from "../coreUtils";

// Funções onBlur e onFocustransferidas do DataTime para InputMask interno

interface Props {
  value?: any;
  onChange?: (v: Date | string) => void;
  label?: any;
  md?: string | number;
  name?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  inputId?: any;
  divClassName?: string;
  onBlur?: (v: string | Date) => void;
}

export const DatePicker: FC<Props> = ({
  value,
  onChange,
  label,
  md = "auto",
  name,
  autoFocus,
  disabled,
  inputId,
  divClassName,
  onBlur,
}) => {
  const [canShowCalendar, setCanShowCalendar] = useState(false);
  const [internalValue, setInternalValue] = useState(value);
  const [valid, setValid] = useState(true);
  const ref = useRef<any>(null);

  const __renderInput = (props: any, _: any, __: any) => {
    return (
      <InputMask mask="99/99/9999" maskChar={null} {...props} name={name} />
    );
  };

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  const onBlurInternal = (v: any) => {
    if (typeof v === "string") {
      let fullDate = moment(v, "DD/MM/YYYY");
      if (fullDate.isValid()) {
        if (
          fullDate.format("YYYY-MM-DD") !==
          moment(internalValue).format("YYYY-MM-DD")
        ) {
          setInternalValue(fullDate);
          setValid(true);
          if (onChange) onChange(fullDate.toDate());
          if (onBlur) onBlur(fullDate.toDate());
        }
      } else if (v !== "") {
        setValid(false);
        if (onBlur) onBlur(v);
      }
    } else {
      if (onBlur) onBlur(v);
    }
  };

  const onChangeInternal = (v: any) => {
    setInternalValue(v);
    if (onChange) onChange(moment.isMoment(v) ? v.toDate() : v);
  };

  return (
    <Col md={md} className={divClassName}>
      <FormGroup>
        <Label>{label}</Label>
        <DateTime
          dateFormat="DD/MM/YYYY"
          timeFormat={false}
          value={internalValue}
          onChange={onChangeInternal}
          renderInput={__renderInput}
          inputProps={{
            id: inputId,
            className: `form-control${valid ? "" : " is-invalid"}`,
            autoFocus: autoFocus,
            disabled: disabled,
            onBlur: (e) =>
              ref.current?.state.open === false &&
              onBlurInternal(e.target.value),
            onKeyDown: (e) => {
              handleFocus(e);
              if (["Enter", "ArrowUp"].includes(e.key))
                ref.current.closeCalendar();
            },
            onMouseDown: () =>
              !ref.current?.state.open && setCanShowCalendar(true),
            onMouseUp: () =>
              ref.current?.state.open && setCanShowCalendar(false),
            onFocus: () =>
              ref.current?.state.open &&
              !canShowCalendar &&
              ref.current.closeCalendar(),
          }}
          closeOnSelect
          ref={ref}
        />
      </FormGroup>
    </Col>
  );
};
