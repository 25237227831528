import { FC, useRef, useState } from "react";
import { ModalBase } from "../../../../../components/ModalBase";
import { ComboBoxOption } from "../../../../../components/ComboBox";
import { Label, Row } from "reactstrap";
import { TextInput } from "../../../../../components";
import { RadioGroup } from "../../../../../components/RadioGroup";
import { AtualizacoesEditor } from "../../../../../components/AtualizacoesEditor";
import { interacaoLeadEditorFormats } from "../interacao/InteracoesLead";
import { extrairIdColabMencionados } from "../../../../../components/AtualizacoesForm";
import LeadService from "../../../../../services/components/comercial/LeadService";
import { formatValueFromAPI } from "../../../../../coreUtils";
import { FixedField } from "../../../../../components/FixedField";
import { toastr } from "react-redux-toastr";

interface Props {
  isOpen: boolean;
  toggle: () => void;
  selected: any;
  notifyEvent: () => void;
}

const localOptions: ComboBoxOption[] = [
  { label: "No Lead", value: "LEA" },
  { label: "Na Skill", value: "SKI" },
];

export const IncluirVisitaLeadModal: FC<Props> = ({
  isOpen,
  toggle,
  selected,
  notifyEvent,
}) => {
  const [nomeEmpresa, setNomeEmpresa] = useState("");
  const [dataHoraVis, setDataHoraVis] = useState<any>(null);
  const [local, setLocal] = useState(null);
  const [observ, setObserv] = useState("");
  const [loading, setLoading] = useState(false);

  const editorRef = useRef<any>();

  const limparDados = () => {
    setNomeEmpresa("");
    setDataHoraVis(null);
    setLocal(null);
    setObserv("");
  };

  const carregarDadosLead = async () => {
    const [ok, ret] = await LeadService.buscar(selected);
    if (ok) {
      setNomeEmpresa(formatValueFromAPI(ret.nome_empresa, selected));
    }
  };

  const handleSubmit = async () => {
    if (["", null, undefined].includes(dataHoraVis)) {
      toastr.warning("Atenção", "Por favor, informe a Data e Hora da Visita");
      return;
    }

    if (["", null, undefined].includes(local)) {
      toastr.warning("Atenção", "Por favor, informe o Local da Visita");
      return;
    }

    const payload = {
      id_lead: selected,
      data_hora_vis: dataHoraVis,
      local: local,
      observ: observ,
      id_colab_mencionados: extrairIdColabMencionados(editorRef),
    };

    setLoading(true);
    const [ok] = await LeadService.visita.incluir(payload);
    if (ok) {
      toggle();
      notifyEvent();
    }
    setLoading(false);
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
      title="Incluir Visita ao Lead"
      number="011_6"
      onClosed={limparDados}
      onConfirm={handleSubmit}
      loadingConfirm={loading}
      onBeforeOpen={carregarDadosLead}
    >
      <Row className="mb-3">
        <FixedField label="Empresa" value={nomeEmpresa} horizontal />
      </Row>
      <Row className="mb-3">
        <TextInput
          md={3}
          label="Data/Hora da Visita"
          value={dataHoraVis}
          type="datetime-local"
          onBlur={setDataHoraVis}
        />
        <RadioGroup
          label="Local da Visita"
          value={local}
          options={localOptions}
          onChange={setLocal}
        />
      </Row>
      <Label>Observação</Label>
      <AtualizacoesEditor
        value={observ}
        onChange={setObserv}
        formats={interacaoLeadEditorFormats}
        editorRef={editorRef}
      />
    </ModalBase>
  );
};
