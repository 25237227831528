// Sidebar
export const SIDEBAR_VISIBILITY_TOGGLE = "SIDEBAR_VISIBILITY_TOGGLE";
export const SIDEBAR_VISIBILITY_SHOW = "SIDEBAR_VISIBILITY_SHOW";
export const SIDEBAR_VISIBILITY_HIDE = "SIDEBAR_VISIBILITY_HIDE";
export const SIDEBAR_STICKY_TOGGLE = "SIDEBAR_STICKY_TOGGLE";
export const SIDEBAR_STICKY_ENABLE = "SIDEBAR_STICKY_ENABLE";
export const SIDEBAR_STICKY_DISABLE = "SIDEBAR_STICKY_DISABLE";

// Layout
export const LAYOUT_BOXED_TOGGLE = "LAYOUT_BOXED_TOGGLE";
export const LAYOUT_BOXED_ENABLE = "LAYOUT_BOXED_ENABLE";
export const LAYOUT_BOXED_DISABLE = "LAYOUT_BOXED_DISABLE";

// Theme
export const THEME_TOGGLE = "THEME_TOGGLE";

// Página
export const SET_PAGE_TITLE = "SET_PAGE_TITLE";
export const SET_PAGE_NUMBER = "SET_PAGE_NUMBER";
export const SET_PAGE_MODAL_NUMBER = "SET_PAGE_MODAL_NUMBER";
export const SET_PAGE_ON_BEFORE_GOING_BACK = "SET_PAGE_ON_BEFORE_GOING_BACK";
export const SET_PAGE_TOP_OPTIONS = "SET_PAGE_TOP_OPTIONS";
export const SET_GO_BACK_ROUTE = "SET_GO_BACK_ROUTE";
