import React, { FC, useEffect, useState } from "react";
import { Card, Col, Row } from "reactstrap";
import { CadastroLeadModal } from "./components/CadastroLeadModal";
import { LeadGrid } from "./components/LeadGrid";
import { debounce, defaultDebounceTime } from "../../../../coreUtils";
import { PageContainer } from "../../../../components/PageContainer";
import {
  AsyncComboBox,
  BotaoPesquisar,
  ComboBox,
  SearchInput,
} from "../../../../components";
import LeadService from "../../../../services/components/comercial/LeadService";
import { NaoConquistaLeadModal } from "./components/NaoConquistaLeadModal";
import { ConquistaLeadModal } from "./components/ConquistaLeadModal";
import { ComboBoxOption } from "../../../../components/ComboBox";
import { FiltroPeriodoDatas } from "../../../../components/FiltroPeriodoDatas";
import { ModalActions } from "../../../../components/cadastro/ModalCadastroV2";
import { BotaoIncluir, ModalExcluirV2 } from "../../../../components/cadastro";
import { FixedField } from "../../../../components/FixedField";
import { useQueryParams } from "../../../../utils/hooks";
import { IncluirVisitaLeadModal } from "./components/IncluirVisitaLeadModal";
import { IncluirPropostaLeadModal } from "./components/IncluirPropostaLeadModal";
import { InteracoesLeadsSemanaModal } from "./components/InteracoesLeadsSemanaModal";
import { useHistory } from "react-router-dom";
import { interacoesLeadRoute } from "../../../../routes/modules/comercial";

const tipoDataOptions: ComboBoxOption[] = [
  { label: "Próxima Interação", value: "PROXINT" },
  { label: "Última Interação", value: "ULTINT" },
];

const estagiosLead: ComboBoxOption[] = [
  { label: "Aberta", value: "ABER" },
  { label: "Não Conquistado", value: "PER" },
  { label: "Conquistado", value: "QUI" },
];

export const modalTitle = "Lead";

const labelTotalSemanaStyle: React.CSSProperties = { textAlign: "center" };

const valueTotalSemanaStyle: React.CSSProperties = {
  fontSize: "0.9rem",
  fontWeight: "bold",
  textAlign: "center",
};

export const Lead: FC = () => {
  const query = useQueryParams();
  const queryIdLead = query.get("id_lead")
    ? parseInt(query.get("id_lead") ?? "0")
    : null;
  const queryAction = query.get("action");

  const [pista, setPista] = useState("");
  const [idCidade, setIdCidade] = useState(null);
  const [estagio, setEstagio] = useState<string>(estagiosLead[0].value);
  const [tipoData, setTipoData] = useState(tipoDataOptions[0].value);
  const [dataIni, setDataIni] = useState(null);
  const [dataFim, setDataFim] = useState(null);
  const [dados, setDados] = useState([]);
  const [totaisSemana, setTotaisSemana] = useState<any>({});
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingPista, setLoadingPista] = useState(false);
  const [action, setAction] = useState(ModalActions.add);
  const [cadastroOpen, setCadastroOpen] = useState(false);
  const [excluirOpen, setExcluirOpen] = useState(false);
  const [interacoesOpen, setInteracoesOpen] = useState(false);
  const [naoConquistaOpen, setNaoConquistaOpen] = useState(false);
  const [conquistaOpen, setConquistaOpen] = useState(false);
  const [visitaOpen, setVisitaOpen] = useState(false);
  const [propostaOpen, setPropostaOpen] = useState(false);
  const [interacoesSemanaOpen, setInteracoesSemanaOpen] = useState(false);

  const history = useHistory();

  const handleDate = (di: any, df: any) => {
    setDataIni(di);
    setDataFim(df);
  };

  const buscarDados = async (p: any) => {
    const [ok, ret] = await LeadService.listar({
      pista: p,
      id_cidade: idCidade,
      estagio: estagio,
      tipo_data: tipoData,
      data_ini: dataIni,
      data_fim: dataFim,
    });
    setDados(ok ? ret.leads : []);
    setTotaisSemana(ok ? ret.totais_semana : {});
    return [ok, ret.leads];
  };

  const carregarDados = async () => {
    setLoading(true);
    const [ok, ret] = await buscarDados(pista);
    setLoading(false);
    return [ok, ret];
  };

  const handlePista = debounce(async (v: any) => {
    setPista(v);
    setLoadingPista(true);
    await buscarDados(v);
    setLoadingPista(false);
  }, defaultDebounceTime);

  const notifyEvent = (action: any) => {
    carregarDados();
    if (action === ModalActions.delete) setSelected(null);
  };

  const toggleCadastro = (action: ModalActions) => {
    setAction(action);
    setCadastroOpen(!cadastroOpen);
  };

  const toggleExcluir = () => setExcluirOpen(!excluirOpen);

  const alterar = (id: any) => {
    setSelected(id);
    setTimeout(() => {
      toggleCadastro(ModalActions.edit);
    }, 1);
  };

  const excluir = (id: any) => {
    setSelected(id);
    setTimeout(() => {
      toggleExcluir();
    }, 1);
  };

  const toggleNaoConquista = () => setNaoConquistaOpen(!naoConquistaOpen);

  const toggleConquista = () => setConquistaOpen(!conquistaOpen);

  const toggleVisita = () => setVisitaOpen(!visitaOpen);

  const toggleProposta = () => setPropostaOpen(!propostaOpen);

  const toggleInteracoesSemana = () =>
    setInteracoesSemanaOpen(!interacoesSemanaOpen);

  const incluirInteracao = (idLead: any) => {
    history.push(interacoesLeadRoute.path + `?id_lead=${idLead}`);
  };

  const incluirVisita = (idLead: any) => {
    setSelected(idLead);
    toggleVisita();
  };

  const incluirProposta = (idLead: any) => {
    setSelected(idLead);
    toggleProposta();
  };

  const naoConquistaLead = (id: any) => {
    setSelected(id);
    toggleNaoConquista();
  };

  const conquistaLead = (id: any) => {
    setSelected(id);
    toggleConquista();
  };

  const mostrarMencaoInteracao = async () => {
    let ok: boolean;
    if (dados.map((e: any) => e.id).includes(queryIdLead)) {
      ok = true;
    } else {
      [ok] = await carregarDados();
    }
    if (ok) {
      const elementoInteracao = document.getElementById(
        `id-lead-${queryIdLead}`
      );
      if (elementoInteracao) {
        elementoInteracao.scrollIntoView({ block: "center" });
        elementoInteracao.click();
      }
    }
  };

  const reativarLead = async (idLead: any, estagio: any) => {
    const payload = {
      id: idLead,
      estagio: estagio,
    };
    const [ok] = await LeadService.interacao.reativarLead(payload);
    if (ok) {
      carregarDados();
    }
  };

  useEffect(() => {
    if (queryAction === "MEN_INT") {
      mostrarMencaoInteracao();
    } else if (!queryAction) {
      if (queryIdLead) {
        alterar(queryIdLead);
      }
    }
  }, [queryAction, queryIdLead]);

  return (
    <PageContainer title="Cadastro de Leads" number="011" canGoBack>
      <Card body>
        <Row>
          <Col md={8}>
            <Row>
              <SearchInput
                md={8}
                onChange={handlePista}
                loading={loadingPista}
              />
              <ComboBox
                md={4}
                label="Estágio da Negociação"
                options={estagiosLead}
                value={estagio}
                onChange={setEstagio}
              />
            </Row>
            <Row>
              <AsyncComboBox
                md={4}
                isConcatField
                concatModelName="cidade"
                label="Cidade"
                isSearchable
                isClearable
                onChange={(v: any) => setIdCidade(v)}
                value={idCidade}
              />
            </Row>
          </Col>
          <Col>
            <Card body>
              <h5>Esta Semana</h5>
              <Row>
                <FixedField
                  divClassName="pl-1 pr-0"
                  label="Meta de Leads"
                  value={20}
                  labelStyle={labelTotalSemanaStyle}
                  textStyle={{
                    fontSize: "0.9rem",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                />
                <FixedField
                  divClassName="pr-0"
                  label="Novos Leads"
                  value={totaisSemana?.novos_leads ?? null}
                  labelStyle={labelTotalSemanaStyle}
                  textStyle={{
                    ...valueTotalSemanaStyle,
                    color:
                      (totaisSemana.novos_leads ?? 0) < 20 ? "red" : "inherit",
                  }}
                />
                <FixedField
                  divClassName="pr-0"
                  label="Conquistados"
                  value={totaisSemana?.leads_conquistados ?? null}
                  labelStyle={labelTotalSemanaStyle}
                  textStyle={valueTotalSemanaStyle}
                />
                <FixedField
                  divClassName="pr-0"
                  label="Interações"
                  value={totaisSemana?.interacoes ?? null}
                  labelStyle={{ ...labelTotalSemanaStyle, cursor: "pointer" }}
                  textStyle={{ ...valueTotalSemanaStyle, cursor: "pointer" }}
                  onClick={() => toggleInteracoesSemana()}
                />
              </Row>
            </Card>
          </Col>
        </Row>
        <Row>
          <ComboBox
            md={2}
            label="Filtrar Por"
            options={tipoDataOptions}
            value={tipoData}
            onChange={setTipoData}
          />
          <FiltroPeriodoDatas
            defaultOption={null}
            defaultStart={null}
            defaultEnd={null}
            onChange={handleDate}
          />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <BotaoIncluir toggle={() => toggleCadastro(ModalActions.add)} />
          <CadastroLeadModal
            isOpen={cadastroOpen}
            toggle={toggleCadastro}
            action={action}
            selected={selected}
            notifyEvent={notifyEvent}
          />
          <ModalExcluirV2
            title={modalTitle}
            isOpen={excluirOpen}
            toggle={toggleExcluir}
            number="011_2"
            selected={selected}
            routeBase={LeadService.routesBase}
            notifyEvent={notifyEvent}
          />
          <IncluirVisitaLeadModal
            isOpen={visitaOpen}
            toggle={toggleVisita}
            selected={selected}
            notifyEvent={carregarDados}
          />
          <IncluirPropostaLeadModal
            isOpen={propostaOpen}
            toggle={toggleProposta}
            selected={selected}
            notifyEvent={carregarDados}
          />
          <NaoConquistaLeadModal
            isOpen={naoConquistaOpen}
            toggle={toggleNaoConquista}
            selected={selected}
            notifyEvent={carregarDados}
          />
          <ConquistaLeadModal
            isOpen={conquistaOpen}
            toggle={toggleConquista}
            selected={selected}
            notifyEvent={carregarDados}
          />
          <InteracoesLeadsSemanaModal
            isOpen={interacoesSemanaOpen}
            toggle={toggleInteracoesSemana}
          />
        </Row>
      </Card>
      <Card body>
        <LeadGrid
          data={dados}
          handleSelect={setSelected}
          incluirInteracao={incluirInteracao}
          incluirVisita={incluirVisita}
          incluirProposta={incluirProposta}
          naoConquistaLead={naoConquistaLead}
          conquistaLead={conquistaLead}
          reativarLead={reativarLead}
          alterar={alterar}
          excluir={excluir}
        />
      </Card>
    </PageContainer>
  );
};
