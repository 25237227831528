import classnames from "classnames";
import { Children, FC, cloneElement, useRef, useState } from "react";
import { useEffect } from "react";
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabContentProps,
  TabPane,
} from "reactstrap";
import { filterFormFocusableElements } from "../coreUtils";

export interface TabBodyProps {
  title?: any;
  children?: any;
  active?: boolean;
  autoFocusFirst?: boolean;
  onActivate?: () => void;
  name?: string;
  disabled?: boolean;
}

export const TabBody: FC<TabBodyProps> = ({
  title,
  children,
  active,
  autoFocusFirst,
  onActivate,
  name,
  disabled,
}) => {
  const ref = useRef<HTMLFormElement>(null);

  useEffect(() => {
    if (active) {
      if (autoFocusFirst && !disabled) {
        const focusableElements = filterFormFocusableElements(
          ref.current?.elements
        );

        if (focusableElements.length > 0 && focusableElements[0].focus) {
          focusableElements[0].focus();
        }
      }

      if (onActivate) onActivate();
    }
  }, [active]);
  return (
    <form name={title} onSubmit={(e) => e.preventDefault()} ref={ref}>
      {children}
    </form>
  );
};

export interface TabControllerProps {
  children?: any;
  onTabChange?: (index?: number, name?: string) => void;
  autoFocusFirst?: boolean;
  defaultActiveTab?: TabContentProps["activeTab"];
  numbered?: boolean;
  contentStyle?: React.CSSProperties;
  tabClassName?: string;
}

export const TabController: FC<TabControllerProps> = ({
  children,
  onTabChange = () => {},
  autoFocusFirst = true,
  defaultActiveTab,
  numbered,
  contentStyle,
  tabClassName = "p-2",
}) => {
  const [activeTab, changeTab] = useState<TabContentProps["activeTab"]>("1");

  useEffect(() => {
    if (defaultActiveTab) {
      changeTab(defaultActiveTab);
    }
  }, []);

  return (
    <>
      <Nav tabs>
        {Children.map(children, (e, index) =>
          e ? (
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === `${index + 1}` })}
                onClick={() => {
                  changeTab(`${index + 1}`);
                  onTabChange(index + 1, e.props.name);
                }}
                disabled={e.props.disabled}
              >
                {numbered && `${index + 1}.`}
                {e.props.title}
              </NavLink>
            </NavItem>
          ) : (
            <></>
          )
        )}
      </Nav>
      <TabContent activeTab={activeTab} style={contentStyle}>
        {Children.map(children, (e, index) =>
          e ? (
            <TabPane tabId={`${index + 1}`} className={tabClassName}>
              {cloneElement(e, {
                active: activeTab === `${index + 1}`,
                autoFocusFirst: autoFocusFirst,
              })}
            </TabPane>
          ) : (
            <></>
          )
        )}
      </TabContent>
    </>
  );
};
